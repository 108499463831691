import db from '@/db'
import {
  ECategoryType,
  Filter,
  Subtypes,
} from '@/entities/filter/types'
import { debounce } from '@/shared/utils/debounce'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

export const useSubtypesFilterMobile = ({
  categoryType,
  searchParam,
}: Subtypes) => {
  const [subtypes, setSubtypes] = useState<Filter[]>([])
  const [totalSubtypesCount, setTotalSubtypesCount] =
    useState<number>(0)
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedIndices, setSelectedIndices] = useState<number[]>([])
  const [isFiltered, setIsFiltered] = useState<boolean>(false)

  const table = db.getTableByName(categoryType)

  useEffect(() => {
    const fetchDataAndLoadIndex = async () => {
      try {
        // Load total count from Dexie
        const totalCount = await table.count()

        setTotalSubtypesCount(totalCount)

        // Load all subtypes from Dexie
        const allSubtypes = await table.toArray()

        setSubtypes(allSubtypes)
      } catch (error) {
        console.error(error)
      }
    }

    fetchDataAndLoadIndex()
  }, [])

  useEffect(() => {
    const type = searchParams.get(searchParam)?.split(',') || []
    if (type.length) {
      setSelectedIndices(type.map(Number))
    }
  }, [searchParams])

  const handleCheckboxChange = useCallback(
    async (id: number) => {
      const isSelected = selectedIndices.includes(id)
      let newSelectedIndices: number[] = []

      if (isSelected) {
        // Deselect the node and its descendants
        const descendantIds = await getAllDescendantIds(id)
        newSelectedIndices = selectedIndices.filter(
          (index) => index !== id && !descendantIds.includes(index)
        )
      } else {
        // Select the node and its descendants
        const descendantIds = await getAllDescendantIds(id)

        newSelectedIndices = Array.from(
          new Set([...selectedIndices, id, ...descendantIds])
        )
      }

      // Update the state with the new indices
      setSelectedIndices(newSelectedIndices)
    },
    [selectedIndices]
  )

  const getAllDescendantIds = useCallback(
    async (parentId: number): Promise<number[]> => {
      const descendants: number[] = []
      const queue: number[] = [parentId]

      while (queue.length > 0) {
        const currentId = queue.shift()!
        const children = await table
          .where('parentId')
          .equals(currentId)
          .toArray()
        for (const child of children) {
          descendants.push(child.id)
          queue.push(child.id)
        }
      }

      return descendants
    },
    []
  )

  const handleApply = useCallback(() => {
    if (selectedIndices.length > 0) {
      searchParams.set(searchParam, selectedIndices.join(','))
    } else {
      searchParams.delete(searchParam)
    }
    setSearchParams(searchParams)
  }, [selectedIndices, searchParam, searchParams, setSearchParams])

  const handleReset = () => {
    setSelectedIndices([])
    searchParams.delete(searchParam)
    setSearchParams(searchParams)
  }

  const handleSelectAll = async (checked: boolean) => {
    if (checked) {
      const allIds = await table.toCollection().primaryKeys()
      setSelectedIndices(allIds)
    } else {
      setSelectedIndices([])
    }
  }

  const allSelected = useMemo(
    () => selectedIndices.length === totalSubtypesCount,
    [selectedIndices, totalSubtypesCount]
  )

  return {
    isFiltered,
    allSelected,
    handleApply,
    setSubtypes,
    setIsFiltered,
    handleReset,
    handleSelectAll,
    selectedIndices,
    totalSubtypesCount,
    handleCheckboxChange,
    setTotalSubtypesCount,
    subtypes,
  }
}
