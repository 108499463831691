import React, { HTMLAttributes, RefObject } from 'react'

interface ButtonScrollToAnchorProps
  extends HTMLAttributes<HTMLDivElement> {
  targetRef?: RefObject<HTMLElement>
  children: React.ReactNode
}

export const ButtonScrollToAnchor: React.FC<
  ButtonScrollToAnchorProps
> = ({ targetRef, children, className }) => {
  const handleScroll = () => {
    if (targetRef && targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: 'smooth' })
    } else window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <button
      type="button"
      onClick={handleScroll}
      className={className}
    >
      {children}
    </button>
  )
}
