import { Typography } from '@/shared/ui'

import { documentStatusBadgeVariants } from '../../consts'
import { EDocumentStatus } from '../../types'

//TODO: Добавить className и сделать текст больше на фильтре статуса документа

export const DocumentItemStatus = ({
  status,
  label,
}: {
  status: EDocumentStatus
  label: string
}) => {
  const { color, textColor } = documentStatusBadgeVariants[status]

  return (
    <Typography
      variant={'subtitle'}
      className="whitespace-nowrap rounded-[4px] px-2 py-[2px] font-semibold"
      style={{
        backgroundColor: color,
        color: textColor,
      }}
    >
      {label}
    </Typography>
  )
}
