import { RadioGroup, RadioGroupItem } from '@/shared/ui/RadioButton'
import { Typography } from '@/shared/ui/Typography'

import { mockSortModeData } from '../../consts'
import { ISortMode } from '../../types'

interface SortModeRadioGroupProps {
  selectedSortMode: ISortMode
  handleSortModeChange: (sortMode: ISortMode) => void
}

export const SortModeRadioGroup: React.FC<
  SortModeRadioGroupProps
> = ({ selectedSortMode, handleSortModeChange }) => {
  return (
    <RadioGroup
      value={selectedSortMode}
      onValueChange={handleSortModeChange}
      className="gap-6"
    >
      {Object.entries(mockSortModeData).map(([value, label]) => (
        <label
          key={value}
          className="flex items-center justify-between gap-3 border-b
            border-b-stroke-light-gray-2 py-3"
        >
          <Typography variant="body" className="font-normal">
            {label}
          </Typography>
          <RadioGroupItem value={value as ISortMode} />
        </label>
      ))}
    </RadioGroup>
  )
}
