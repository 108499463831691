import { CourtBaseItem } from '@/entities/courtBase'
import IconNotFound from '@/shared/assets/icons/logo_not_found.svg?react'
import { Typography } from '@/shared/ui'

import { courtBaseData } from '../consts'

export const CourtBaseBodyContainer = ({
  isMobile,
}: {
  isMobile: boolean
}) => {
  return (
    <div
      className="flex flex-col items-stretch justify-start rounded-b-3xl border-x-[1px]
        border-b-[1px] border-t-0 bg-transparent"
      style={{
        boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.04)',
        overflow: 'visible',
      }}
    >
      {courtBaseData && courtBaseData.length > 0 ? (
        <>
          {courtBaseData.map((item, index) => {
            const isLastItem = index === courtBaseData.length - 1
            return (
              <CourtBaseItem
                index={index}
                key={item.id}
                courtBaseItem={item}
                isLastItem={isLastItem}
              />
            )
          })}
        </>
      ) : (
        <div
          className="flex shrink-0 grow flex-col items-center justify-center self-stretch
            py-20"
        >
          <div className="flex flex-col items-stretch justify-center gap-8">
            <IconNotFound className="self-center drop-shadow-lg" />
            <div className="flex flex-col items-center justify-center">
              <Typography>
                По запросу не найдено ни одного судебного дела.
              </Typography>
              <Typography>
                Попробуйте написать запрос иначе и повторить поиск.
              </Typography>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
