import { Skeleton } from '@/shared/ui/Skeleton'
import { cn } from '@/shared/utils/common'

const ChatDocumentSkeleton = () => (
  <div
    className={cn(
      `flex h-full items-stretch justify-start gap-4 rounded-none
      border-stroke-light-gray-1 bg-bg-light-white-1`
    )}
  >
    <div
      className="flex grow flex-col items-stretch justify-start gap-4 self-stretch
        bg-white px-0 py-4"
    >
      {/* Title Skeleton */}
      <Skeleton className="mx-auto mb-3 h-[80px] w-[60%] bg-base-200" />

      {/* Section Header */}
      <Skeleton className="mb-1 h-[90px] w-[40%] bg-base-200" />

      {/* Paragraphs */}
      <Skeleton className="h-[60px] w-full bg-base-200" />
      <Skeleton className="h-[60px] w-[90%] bg-base-200" />
      <Skeleton className="mb-1 h-[60px] w-[85%] bg-base-200" />

      {/* Section Header */}
      <Skeleton className="mx-auto mb-4 h-[80px] w-[40%] bg-base-200" />

      {/* More Paragraphs */}
      <Skeleton className="h-[60px] w-full bg-base-200" />
      <Skeleton className="h-[60px] w-[92%] bg-base-200" />
      <Skeleton className="mb-4 h-[60px] w-[88%] bg-base-200" />

      <Skeleton className="mb-4 h-[80px] w-[45%] bg-base-200" />
      <Skeleton className="h-[60px] w-[70%] bg-base-200" />
      <Skeleton className="h-[60px] w-[88%] bg-base-200" />
      <Skeleton className="mb-4 h-[60px] w-[92%] bg-base-200" />

      <Skeleton className="mb-4 h-[80px] w-[45%] bg-base-200" />
      <Skeleton className="h-[60px] w-[100%] bg-base-200" />
      <Skeleton className="h-[60px] w-[83%] bg-base-200" />
      <Skeleton className="mb-4 h-[60px] w-[92%] bg-base-200" />
      {/* Footer */}
      <Skeleton className="ml-auto mt-8 h-[80px] w-[30%] bg-base-200" />
    </div>
  </div>
)

export { ChatDocumentSkeleton }
