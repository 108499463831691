import { ICourtItem } from '@/entities/courtBase'
import { Checkbox } from '@/shared/ui/Checkbox'
import { Typography } from '@/shared/ui/Typography'

interface CourtBaseFilterItemProps {
  items: ICourtItem[]
  selectedIndices: number[]
  handleCheckboxChange: (id: string) => void
}

export const CourtBaseFilterItems: React.FC<
  CourtBaseFilterItemProps
> = ({ items, selectedIndices, handleCheckboxChange }) => {
  return (
    <>
      {items.map((item) => {
        return (
          <label
            key={item.id}
            htmlFor={`checkbox-${item.id}`}
            className="flex cursor-pointer flex-nowrap items-center justify-between gap-2
              self-stretch border-b border-b-[#E3E5E8] px-0 py-3"
          >
            <span
              className="flex shrink grow-0 basis-full items-center justify-start gap-2
                overflow-hidden"
            >
              <Typography variant={'body'} className="inline min-w-0">
                {item.id}
              </Typography>
            </span>
            <Checkbox
              id={`checkbox-${item.id}`}
              checked={selectedIndices.includes(
                Number(item.id.replace(/-/g, ''))
              )}
              onCheckedChange={() => handleCheckboxChange(item.id)}
            />
          </label>
        )
      })}
    </>
  )
}
