import { DocumentInfoDialog } from '@/entities/panel/ui/DocumentInfoDialog'
import IconLettersDark from '@/shared/assets/icons/icon_letters_dark.svg?react'
import { Typography } from '@/shared/ui'
import React, { useState } from 'react'

type DocumentActionShowInfoProps = {
  isMobile: boolean
  documentId: number
}

export const DocumentActionShowInfo = ({
  isMobile,
  documentId,
}: DocumentActionShowInfoProps) => {
  const [isInfoDialogOpen, setIsInfoDialogOpen] = useState(false)

  const handleInfoClick = () => {
    setIsInfoDialogOpen(true)
  }

  const handleInfoClose = () => {
    setIsInfoDialogOpen(false)
  }

  return (
    <>
      <div
        onClick={handleInfoClick}
        className="flex cursor-pointer items-center justify-start gap-3 border-b
          border-b-stroke-light-gray-2 py-3"
      >
        <IconLettersDark className="min-h-6 min-w-6" />

        <Typography
          variant={'label'}
          className="text-text-light-black-8"
        >
          Информация о документе
        </Typography>
      </div>

      {/* Dialog will open when the user clicks on "Информация о документе" */}
      <DocumentInfoDialog
        open={isInfoDialogOpen}
        documentId={documentId}
        onClose={handleInfoClose}
      />
    </>
  )
}
