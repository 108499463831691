import { useTypedSelector } from '@/shared/store'
import { cn } from '@/shared/utils/common'
import { useSidebar } from '@/widgets/Sidebar'
import { useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'

import { IChatMessage } from '../../types'
import { ChatInput } from '../ChatInput'
import { ChatMessages } from '../ChatMessages'

interface ChatContainerProps {
  messages: IChatMessage[]
}

export const ChatContainer = ({ messages }: ChatContainerProps) => {
  const { isSidebarOpen } = useSidebar()
  const { documents } = useTypedSelector(
    (state) => state.documentPreview
  )
  const scrollContainerRef = useRef<HTMLDivElement | null>(null)

  const { chat_id } = useParams<{ chat_id: string }>()

  const isAwaitingResponse = useTypedSelector(
    (state) =>
      state.chats.chatsData[chat_id!]?.isAwaitingResponse ?? false
  )

  useEffect(() => {
    if (scrollContainerRef.current) {
      requestAnimationFrame(() => {
        scrollContainerRef.current!.scrollTop =
          scrollContainerRef.current!.scrollHeight
      })
    }
  }, [messages, isAwaitingResponse])

  if (!messages) return null

  return (
    <div
      className={cn(
        `relative flex min-h-full w-full flex-col pb-8 transition-[transform]
        duration-500 ease-in-out`,
        documents.length
          ? 'fixed translate-x-full'
          : 'relative translate-x-0'
      )}
    >
      <div
        ref={scrollContainerRef}
        className="chat_scrollable flex-1 overflow-y-auto"
      >
        <div
          className={cn(
            isSidebarOpen ? 'lg:min-w-[672px]' : 'lg:min-w-[768px]',
            `mx-auto h-full w-full min-w-[672px] bg-[#FCFCFC] pt-24
            lg:max-w-[850px]`
          )}
        >
          <ChatMessages messages={messages} />
        </div>
      </div>
      <ChatInput />
    </div>
  )
}
