import { usePostNewChatMessageMutation } from '@/shared/api/chat'
import { useAppDispatch, useTypedSelector } from '@/shared/store'
import { setChatAwaitingResponse } from '@/shared/store/slices/chat/chatSlice'
import { useToast } from '@/shared/ui/Toast/useToast'
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react'

export const useChatInput = ({
  chatId,
}: {
  chatId: string | undefined
}) => {
  const dispatch = useAppDispatch()
  const [isSending, setIsSending] = useState(false)
  const [inputValue, setInputValue] = useState<string>('')
  const selectRef = useRef<HTMLInputElement>(null)
  const { isChatOpen } = useTypedSelector(
    (state) => state.documentPreview
  )
  const { toast } = useToast()
  const [postNewChatMessage] = usePostNewChatMessageMutation()

  useEffect(() => {
    if (selectRef.current) {
      selectRef.current.focus()
    }
  }, [])

  useEffect(() => {
    if (selectRef.current) {
      selectRef.current.style.height = '44px'
      selectRef.current.style.borderRadius = '100px'
    }
  }, [selectRef, isChatOpen])

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setInputValue(e.target.value)
  }

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault()
    if (!inputValue.trim() || !chatId) return

    setIsSending(true)
    dispatch(setChatAwaitingResponse({ chatId }))
    try {
      // Send the message using RTK Query mutation
      await postNewChatMessage({
        chatId,
        data: { message: inputValue },
      }).unwrap()
      setInputValue('')
    } catch (error: any) {
      toast({
        variant: 'error',
        title: 'Что-то пошло не так',
        description: error.data.message,
      })
    } finally {
      setIsSending(false)
    }
  }

  const isSubmitButtonDisabled = isSending || inputValue.trim() === ''

  return {
    isSending,
    inputValue,
    isSubmitButtonDisabled,
    selectRef,
    handleInputChange,
    handleSubmit,
  }
}
