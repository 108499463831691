import { Skeleton } from '@/shared/ui/Skeleton'

export const DocumentItemMobileSkeleton: React.FC = () => {
  return (
    <Skeleton
      className="flex shrink grow-0 flex-col items-stretch justify-start rounded-[16px]
        px-3 pb-[16px] pt-[10px] shadow-shadow3"
    >
      <div className="flex w-full shrink grow basis-[40px] items-center justify-start gap-2">
        <Skeleton className="h-6 w-6 bg-base-50" />
        <Skeleton className="h-6 w-24 bg-base-50" />
        <div className="flex flex-1 items-center justify-end">
          <Skeleton className="h-6 w-6 bg-base-50" />
        </div>
      </div>
      <div className="flex flex-col items-stretch justify-start gap-2 pt-4">
        <Skeleton className="h-6 w-full bg-base-50" />
        <Skeleton className="h-20 w-full bg-base-50" />
      </div>
    </Skeleton>
  )
}
