import { usePostNewChatMutation } from '@/shared/api/chat'
import { setChatAwaitingResponse } from '@/shared/store/slices/chat/chatSlice'
import { useToast } from '@/shared/ui/Toast/useToast'
import { FormEvent, KeyboardEvent, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export const useHomePageChatInput = () => {
  const [message, setMessage] = useState<string>('')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { toast } = useToast()

  const [postNewChat, { isLoading, isError, error }] =
    usePostNewChatMutation()

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMessage(e.target.value)
  }

  const handleKeyDown = (
    event: KeyboardEvent<HTMLTextAreaElement>
  ) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      handleSubmit(event as unknown as FormEvent<HTMLFormElement>) // Submits the form
    }
  }

  const isSubmitButtonDisabled = isLoading || message.trim() === ''

  const handleSubmit = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault()
    if (!message.trim()) return

    try {
      const response = await postNewChat({ message }).unwrap()

      if (response.success) {
        // After successful creation, we have a chat id:
        dispatch(
          setChatAwaitingResponse({ chatId: response.chat.id })
        )
        navigate(`/chats/${response.chat.id}`)
      }
    } catch (error) {
      console.error('Failed to create new chat:', error)
      // Show an error toast
      toast({
        variant: 'error',
        title: 'Error',
        description:
          'Failed to create a new chat. Please try again later.',
      })
    }
  }

  return {
    error,
    isError,
    message,
    isLoading,
    handleSubmit,
    handleKeyDown,
    handleInputChange,
    isSubmitButtonDisabled,
  }
}
