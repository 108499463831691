import db from '@/db'
import { lunr } from '@/shared/config/lunrConfig'
import { debounce } from '@/shared/utils/debounce'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { ECategoryType, Filter } from '../types'

type UseCategoriesFiltersProps = {
  categoryType: ECategoryType
  setTotalCount: Dispatch<SetStateAction<number>>
  setFilterItems: Dispatch<SetStateAction<Filter[]>>
  setIsFiltered: Dispatch<React.SetStateAction<boolean>>
}

export const useCategoriesFiltersSearch = ({
  categoryType,
  setIsFiltered,
  setTotalCount,
  setFilterItems,
}: UseCategoriesFiltersProps) => {
  const [searchText, setSearchText] = useState<string>('')
  const [lunrIndex, setLunrIndex] = useState<any>(null) // Use 'any' because 'lunr' is dynamically imported
  const [isSearching, setIsSearching] = useState<boolean>(false)
  // const [isFiltered, setIsFiltered] = useState<boolean>(false)
  const table = db.getTableByName(categoryType)

  useEffect(() => {
    const fetchAndLoadIndex = async () => {
      try {
        // Load the total count from the appropriate table
        const totalCount = await table.count()
        setTotalCount(totalCount)

        // Load all items from the appropriate table
        const allItems = await table.toArray()
        setFilterItems(allItems)

        // Load the prebuilt Lunr index
        const indexUrl = `/${categoryType}Index.json` // e.g., /groupsIndex.json
        const response = await fetch(indexUrl)
        const serializedIndex = await response.json()
        const index = lunr.Index.load(serializedIndex)

        // Store lunr and index in state
        setLunrIndex({ lunr, index })
      } catch (error) {
        console.error('Error loading data or Lunr index:', error)
      }
    }

    fetchAndLoadIndex()
  }, [categoryType, setFilterItems, setTotalCount])

  // Debounced search effect
  useEffect(() => {
    const debouncedSearch = debounce(async () => {
      setIsSearching(true)

      if (lunrIndex && searchText.trim().length > 0) {
        try {
          const { lunr, index } = lunrIndex // Extract 'lunr' and 'index' from state

          // Tokenize and process the search query
          const tokens = lunr.tokenizer(searchText)
          const processedTokens = index.pipeline.run(tokens)
          const query = processedTokens.join(' ')

          // Perform the search
          const results = index.search(`${query}*`)

          const matchedIds = results.map((result: any) =>
            parseInt(result.ref)
          )
          const matches = await table
            .where('id')
            .anyOf(matchedIds)
            .toArray()

          setIsFiltered(true)
          setFilterItems(matches)
        } catch (error) {
          console.error(
            'Error during search or fetching from Dexie:',
            error
          )
        } finally {
          setIsSearching(false)
        }
      } else {
        // If no search text, load all items
        try {
          const allItems = await table.toArray()
          setIsFiltered(false)
          setFilterItems(allItems)
        } catch (error) {
          console.error('Error fetching all items from Dexie:', error)
        } finally {
          setIsSearching(false)
        }
      }
    }, 1500)

    if (lunrIndex) {
      debouncedSearch()
    }

    return () => {
      debouncedSearch.cancel?.()
    }
  }, [searchText, lunrIndex, categoryType, setFilterItems])

  return { searchText, setSearchText, isSearching }
}
