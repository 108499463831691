import { useGetDocumentsQuery } from '@/shared/api/documents'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { DocumentQueryParams, ISearchMode } from '../types'

const SCROLL_POSITION_KEY = 'mobile-documents-scroll-position'
const DOCUMENTS_PAGE_KEY = 'DOCUMENTS_PAGE'
const DOCUMENTS_LIMIT = 'DOCUMENTS_LIMIT'

// Scroll threshold for showing the scroll-up button
const SCROLL_UP_THRESHOLD = 250

// Animation durations
const RESTORE_ANIMATION_DURATION = 1000
const RESTORE_ANIMATION_DELAY = 500
const SCROLL_UP_ANIMATION_DURATION = 500

export const useDocumentsMobile = () => {
  const [searchParams] = useSearchParams()
  const queryParams = Object.fromEntries(searchParams.entries())
  const { search_mode, search_query } = queryParams

  const queryOptions: Partial<DocumentQueryParams> = {
    ...queryParams,
  }

  // Adjust search_mode and search_query logic
  if (search_mode && search_query) {
    queryOptions.search_mode = search_mode as ISearchMode
    queryOptions.search_query = search_query
  } else if (search_mode && !search_query) {
    delete queryOptions.search_mode
    delete queryOptions.search_query
  } else if (!search_mode && search_query) {
    queryOptions.search_mode = 'IN_TITLE' as ISearchMode
    queryOptions.search_query = search_query
  }

  // We rely on internal state for pagination, remove any 'page' from the query options
  delete queryOptions.page

  // Restore initial page from session or default to 1
  const initialPage = parseInt(
    sessionStorage.getItem(DOCUMENTS_PAGE_KEY) || '1',
    10
  )
  const [page, setPage] = useState(initialPage)

  const didRestoreScroll = useRef(false)
  const containerRef = useRef<HTMLElement | null>(null)
  const lastDocumentRef = useRef<HTMLDivElement | null>(null)
  const observer = useRef<IntersectionObserver | null>(null)

  const [isScrollUpVisible, setIsScrollUpVisible] = useState(false)

  // Keep track of previous queryOptions to detect changes
  const prevQueryOptionsRef = useRef<string>(
    JSON.stringify(queryOptions)
  )

  // If queryOptions change (excluding the initial mount), reset the page and remove DOCUMENTS_PAGE_KEY
  useEffect(() => {
    const currentQueryOptions = JSON.stringify(queryOptions)
    if (prevQueryOptionsRef.current !== currentQueryOptions) {
      // Parameters changed
      sessionStorage.removeItem(DOCUMENTS_PAGE_KEY)
      setPage(1)
      prevQueryOptionsRef.current = currentQueryOptions
    }
  }, [queryOptions])

  // Integrate query options along with pagination
  const { data, error, isLoading, isFetching } = useGetDocumentsQuery(
    { ...queryOptions, isMobile: true, page },
    { skip: false }
  )

  const handleIntersection = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const target = entries[0]
      if (
        target.isIntersecting &&
        !isFetching &&
        data &&
        data.meta.next_page
      ) {
        const nextPage = data.meta.next_page
        setPage(nextPage)
      }
    },
    [data, isFetching]
  )

  // Set up the IntersectionObserver for infinite scrolling
  useEffect(() => {
    if (!lastDocumentRef.current) return
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    })
    const currentObserver = observer.current
    currentObserver.observe(lastDocumentRef.current)
    return () => {
      currentObserver.disconnect()
    }
  }, [handleIntersection])

  const saveScrollPosition = useCallback((temp: HTMLElement) => {
    sessionStorage.setItem(
      SCROLL_POSITION_KEY,
      temp.scrollTop.toString()
    )
    setIsScrollUpVisible(temp.scrollTop > SCROLL_UP_THRESHOLD)
  }, [])

  // Attach scroll event to #temp container
  useEffect(() => {
    const temp = document.getElementById('temp') as HTMLElement | null
    containerRef.current = temp
    if (!temp) return

    const handler = () => saveScrollPosition(temp)
    temp.addEventListener('scroll', handler)
    return () => {
      temp.removeEventListener('scroll', handler)
    }
  }, [saveScrollPosition])

  // Update sessionStorage when data loads
  useEffect(() => {
    if (data && !isLoading && data.meta) {
      sessionStorage.setItem(
        DOCUMENTS_PAGE_KEY,
        data.meta.current_page.toString()
      )
      sessionStorage.setItem(
        DOCUMENTS_LIMIT,
        String(data.meta.per_page)
      )
    }
  }, [data, isLoading])

  // Restore scroll position once after initial data load
  useEffect(() => {
    if (
      didRestoreScroll.current ||
      isLoading ||
      !data ||
      data.documents.length === 0
    )
      return

    const savedPosition = sessionStorage.getItem(SCROLL_POSITION_KEY)
    if (savedPosition) {
      const targetPosition = parseInt(savedPosition, 10)
      const temp = containerRef.current
      if (temp) {
        let startTimestamp: number | null = null

        const animateScroll = (timestamp: number) => {
          if (startTimestamp === null) startTimestamp = timestamp
          const elapsed = timestamp - startTimestamp
          const progress = Math.min(
            elapsed / RESTORE_ANIMATION_DURATION,
            1
          )
          temp.scrollTop = targetPosition * progress

          if (progress < 1) {
            requestAnimationFrame(animateScroll)
          }
        }

        setTimeout(() => {
          requestAnimationFrame(animateScroll)
        }, RESTORE_ANIMATION_DELAY)
      }
    }
    didRestoreScroll.current = true
  }, [data, isLoading])

  // Smooth scroll up to the top
  const handleScrollUp = useCallback(() => {
    const temp = containerRef.current
    if (!temp) return

    const start = temp.scrollTop
    let startTimestamp: number | null = null

    const animateUp = (timestamp: number) => {
      if (startTimestamp === null) startTimestamp = timestamp
      const elapsed = timestamp - startTimestamp
      const progress = Math.min(
        elapsed / SCROLL_UP_ANIMATION_DURATION,
        1
      )
      temp.scrollTop = start - start * progress

      if (progress < 1) {
        requestAnimationFrame(animateUp)
      }
    }

    requestAnimationFrame(animateUp)
  }, [])

  return {
    data,
    error,
    isFetching,
    handleScrollUp,
    lastDocumentRef,
    isScrollUpVisible,
  }
}
