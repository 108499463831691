import { ISearchMode } from '@/entities/document'
import IconAiEmpty from '@/shared/assets/icons/icon_ai_empty.svg?react'
import IconClose from '@/shared/assets/icons/icon_close.svg?react'
import IconLettersDark from '@/shared/assets/icons/icon_letters_dark.svg?react'
import { Button } from '@/shared/ui/Button'
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from '@/shared/ui/Drawer'
import { IconButton } from '@/shared/ui/IconButton'
import { Typography } from '@/shared/ui/Typography'

import { useSearchModeFilter } from '../../hooks/useSearchModeFilter'
import { SearchModeRadioGroup } from '../SearchModeFilterRadioGroup'

const defaultSearchMode: ISearchMode = 'IN_TITLE'

export const SearchModeFilterDrawer = () => {
  const {
    selectedSearchMode,
    handleSearchModeChange,
    handleApply,
    handleReset,
  } = useSearchModeFilter(defaultSearchMode)

  return (
    <Drawer direction="bottom">
      <DrawerTrigger asChild>
        <IconButton
          className="flex h-10 w-10 items-center justify-center border-[1px]
            border-stroke-light-gray-2"
        >
          {selectedSearchMode == 'IN_TITLE' ? (
            <IconLettersDark className="h-6 w-6" />
          ) : (
            <IconAiEmpty className="h-6 w-6 [&_path]:stroke-base-950" />
          )}
        </IconButton>
      </DrawerTrigger>
      <DrawerContent className="z-[70] h-[62svh] overflow-y-hidden">
        <DrawerClose className="absolute right-3 top-3">
          <IconClose className="h-6 w-6" />
        </DrawerClose>
        <DrawerHeader className="flex items-center justify-start gap-3 p-0">
          <DrawerTitle>
            <Typography variant={'heading4'}>Режим поиска</Typography>
          </DrawerTitle>
        </DrawerHeader>
        <DrawerDescription
          className="-mx-6 mt-8 flex flex-col items-stretch justify-start gap-6
            overflow-y-scroll px-6"
        >
          <SearchModeRadioGroup
            selectedSearchMode={selectedSearchMode}
            handleSearchModeChange={handleSearchModeChange}
          />
        </DrawerDescription>
        <DrawerFooter className="flex flex-row flex-nowrap justify-between gap-5 p-0 pb-5">
          <DrawerClose className="shrink-0 grow">
            <Button
              variant={'primary'}
              className="w-full"
              onClick={() => handleApply({})}
            >
              Применить
            </Button>
          </DrawerClose>
          <DrawerClose className="shrink-0 grow">
            <Button
              variant={'outlined'}
              className="w-full"
              onClick={handleReset}
            >
              Сбросить
            </Button>
          </DrawerClose>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
