// @/db.ts
import Dexie, { type Table } from 'dexie'

import { DbTable, ECategoryType } from './entities/filter/types'

class CodexDatabase extends Dexie {
  organizations!: DbTable
  types!: DbTable
  groups!: DbTable

  constructor() {
    super('codexDatabase')
    this.version(1).stores({
      organizations:
        'id, parentId, name, document_amount, children_count, *tokens',
      types:
        'id, parentId, name, document_amount, children_count, *tokens',
      groups:
        'id, parentId, name, document_amount, children_count, *tokens',
    })
  }

  getTableByName(categoryType: ECategoryType): DbTable {
    if (
      categoryType === ECategoryType.APPROVING_ORGANIZATIONS ||
      categoryType === ECategoryType.DEVELOPING_ORGANIZATIONS
    ) {
      return this['organizations']
    } else return this[categoryType] as DbTable
  }
}
const db = new CodexDatabase()

export default db
