import { cn } from '@/shared/utils/common'
import { cva, VariantProps } from 'class-variance-authority'

const style = cva('animate-pulse rounded-md', {
  variants: {
    variant: {
      card: 'bg-base-100',
      content: 'bg-base-100',
    },
  },
  defaultVariants: {
    variant: 'card',
  },
})

interface SkeletonProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof style> {}

export const Skeleton = ({
  className,
  variant,
  ...props
}: SkeletonProps) => {
  return (
    <div className={cn(style({ variant }), className)} {...props} />
  )
}
