import { IAiAssistPayload, IChatAiFile } from '@/entities/chat/types'
import { chatApi, useGetChatByIdQuery } from '@/shared/api/chat'
import { useAppDispatch } from '@/shared/store'
import {
  clearChatAwaitingResponse,
  setChatError,
} from '@/shared/store/slices/chat/chatSlice'
import { toast } from '@/shared/ui/Toast/useToast'
import { useSocket, useSocketFailure } from '@/socketHook'
import { useEffect, useRef } from 'react'

// Make sure you have a toast hook
import { IChat } from '../types'

interface UseChatMessagesReturnType {
  data: {
    chat: IChat['chat']
    messages: IChat['messages']
  } | null
  error: string | null
  isLoading: boolean
  isFetching: boolean
}

export const useChatMessages = (
  chatId: string,
  userId: number
): UseChatMessagesReturnType => {
  const dispatch = useAppDispatch()
  const { data, error, isLoading, isFetching } =
    useGetChatByIdQuery(chatId)

  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  // Handle normal "responded" events
  useSocket({
    type: 'CHAT',
    userId,
    callBack: (payload: IAiAssistPayload) => {
      const documents = parseDocuments(payload.documents)

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }

      dispatch(clearChatAwaitingResponse({ chatId: payload.chat_id }))

      dispatch(
        chatApi.util.updateQueryData(
          'getChatById',
          payload.chat_id,
          (draft) => {
            if (draft && draft.messages) {
              const exists = draft.messages.find(
                (msg) => msg.id === payload.message_id
              )

              if (!exists) {
                draft.messages.push({
                  id: payload.message_id,
                  from: 'ASSISTANT',
                  is_favorite: false,
                  message: payload.message,
                  documents,
                  created_at: new Date().toISOString(),
                })
              }
            }
          }
        )
      )
    },
  })

  // Handle failure events
  useSocketFailure({
    type: 'CHAT',
    userId,
    callBack: (payload: {
      chat_id: string
      error_message: string
    }) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }

      // Set error in Redux state
      dispatch(
        setChatError({
          chatId: payload.chat_id,
          error: payload.error_message,
        })
      )
      dispatch(clearChatAwaitingResponse({ chatId: payload.chat_id }))

      // Show a toast to notify the user about the error
      toast({
        variant: 'error',
        title: 'Error',
        description: payload.error_message,
      })
    },
  })

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  return {
    data: data
      ? {
          chat: data.chat,
          messages: data.messages,
        }
      : null,
    error: error ? 'Failed to fetch chat messages' : null,
    isLoading,
    isFetching,
  }
}

const parseDocuments = (documents?: IChatAiFile[]): IChatAiFile[] => {
  if (documents && typeof documents === 'string') {
    try {
      return JSON.parse(documents)
    } catch (error: any) {
      toast({
        variant: 'error',
        title: 'Error',
        description: error,
      })
      return []
    }
  }
  return documents || []
}
