import { MobileDocumentsSearchInput } from '@/features/filters'
import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { cn } from '@/shared/utils/common'
import { CourtBaseBodyContainer } from '@/widgets/CourtBase/CourtBaseBodyContainer'
import { CourtBaseBodyContainerMobile } from '@/widgets/CourtBase/CourtBaseBodyContainerMobile'
import { WidgetCourtBaseFiltersMobile } from '@/widgets/CourtBase/WidgetCourtBaseFiltersMobile'
import { WidgetCourtBaseSearchInput } from '@/widgets/CourtBase/WidgetCourtBaseSearchInput'
import { WidgetCategories } from '@/widgets/WidgetCategories'
import { WidgetCourtBaseFilters } from '@/widgets/WidgetCourtBaseFilters'

export const CourtBasePage = () => {
  const isMobile = useMediaQuery('(max-width: 960px)')

  if (isMobile) {
    return (
      <div
        className={cn(
          `flex h-full flex-col items-stretch justify-start overflow-y-auto px-5
          pt-[64px]`
          // 'menu_boundary'
        )}
      >
        <div className="mb-[10px] flex justify-center self-stretch">
          <MobileDocumentsSearchInput />
        </div>
        <WidgetCourtBaseFiltersMobile />
        {/* Divider */}
        <div className="-mx-5 mb-2 h-2 shrink grow-0 bg-[#F0F0F0]" />
        {/* Body container */}
        <CourtBaseBodyContainerMobile />
      </div>
    )
  }
  return (
    <div
      className="relative flex h-[calc(100svh-96px)] w-full overflow-visible
        bg-transparent"
    >
      <WidgetCategories pageTitle="court-base" />
      <div
        className={cn(
          `menu_boundary relative flex h-full flex-1 flex-col overflow-auto
          rounded-t-3xl bg-transparent px-4`
        )}
      >
        <div className="sticky top-0 z-50 -mx-2 min-h-4 min-w-full bg-[#FCFCFC]"></div>
        <div
          className="sticky top-0 z-50 -mt-4 rounded-t-3xl border-x-[1px] border-t-[1px]
            bg-white pt-1"
        >
          <WidgetCourtBaseSearchInput />
          <WidgetCourtBaseFilters totalDocuments={856000} />
        </div>

        <CourtBaseBodyContainer isMobile={isMobile} />
      </div>
    </div>
  )
}
