import { AssignedDoc } from '@/entities/courtBase'
import { debounce } from '@/shared/utils/debounce'
import { useCallback, useEffect, useState } from 'react'

interface useCourtBaseAssignedDocsProps {
  assignedDocs: AssignedDoc[]
}

export const useCourtBaseAssignedDocs = ({
  assignedDocs,
}: useCourtBaseAssignedDocsProps) => {
  const [openItems, setOpenItems] = useState<string[]>([])
  const [isOpenSearch, setIsOpenSearch] = useState<boolean>(false)
  const [filteredDocs, setFilteredDocs] =
    useState<AssignedDoc[]>(assignedDocs)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [debouncedQuery, setDebouncedQuery] = useState<string>('')

  const debouncedSearchQuery = useCallback(
    debounce((query: string) => setDebouncedQuery(query), 300),
    [searchQuery]
  )

  useEffect(() => {
    debouncedSearchQuery(searchQuery)
  }, [searchQuery, debouncedSearchQuery])

  useEffect(() => {
    if (debouncedQuery) {
      const lowercasedQuery = debouncedQuery.toLowerCase()
      setFilteredDocs(
        assignedDocs.filter((doc) =>
          Object.values(doc).some((field) =>
            field.toLowerCase().includes(lowercasedQuery)
          )
        )
      )
    } else {
      setFilteredDocs(assignedDocs)
    }
  }, [debouncedQuery, assignedDocs])

  const toggleOpenSearch = () => setIsOpenSearch((prev) => !prev)

  const toggleAllAccordions = () => {
    if (openItems.length === assignedDocs.length) {
      setOpenItems([])
    } else if (openItems.length > 0) {
      setOpenItems([])
    } else {
      setOpenItems(assignedDocs.map((doc) => doc.id.toString()))
    }
  }

  return {
    openItems,
    isOpenSearch,
    filteredDocs,
    setSearchQuery,
    toggleOpenSearch,
    toggleAllAccordions,
    setOpenItems,
  }
}
