import { BaseDocumentMenu, MenuItem } from '@/entities/modal'
import { DocumentInfoDialog } from '@/entities/panel/ui/DocumentInfoDialog'
import IconDownloadDark from '@/shared/assets/icons/icon_download_dark.svg?react'
import IconFolderPlus from '@/shared/assets/icons/icon_folder_plus.svg?react'
import IconInfoDark from '@/shared/assets/icons/icon_info_dark.svg?react'
import IconMarkBlue from '@/shared/assets/icons/icon_mark_blue.svg?react'
import IconMarkDark from '@/shared/assets/icons/icon_mark_dark.svg?react'
import { Typography } from '@/shared/ui/Typography'
import { cn } from '@/shared/utils/common'
import { forwardRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { EDocumentStatus, IDocument } from '../../types'
import { DocumentItemStatus } from '../DocumentItemStatus'

interface DocumentItemProps {
  documentItem: IDocument
  isIndexed?: boolean
  isLastDocumentItem: boolean
}

// Main DocumentItem component
export const DocumentItem = forwardRef<
  HTMLDivElement,
  DocumentItemProps
>(({ documentItem, isLastDocumentItem, isIndexed = true }, ref) => {
  const isFavorite = documentItem.is_favorite
    ? documentItem.is_favorite
    : false
  const navigate = useNavigate()

  const [isInfoDialogOpen, setIsInfoDialogOpen] =
    useState<boolean>(false)
  const handleInfoClick = () => {
    setIsInfoDialogOpen((prev) => !prev)
  }
  const handleInfoClose = () => {
    setIsInfoDialogOpen(false)
  }

  const documentMenuItems: MenuItem[] = [
    {
      title: 'О документе',
      icon: <IconInfoDark className="h-5 w-5" />,
      onClick: handleInfoClick,
    },
    {
      title: isFavorite ? 'В избранном' : 'В избранное',
      icon: isFavorite ? (
        <IconMarkBlue className={'h-5 w-5'} />
      ) : (
        <IconMarkDark className={'h-5 w-5'} />
      ),
      className: 'hover:bg-additional-light-blue',
    },
    {
      title: 'Скачать',
      icon: <IconDownloadDark className="h-5 w-5" />,
    },
    {
      title: 'В папку',
      icon: <IconFolderPlus className="h-5 w-5" />,
    },
  ]

  return (
    <>
      <DocumentInfoDialog
        open={isInfoDialogOpen}
        documentId={documentItem.id}
        onClose={handleInfoClose}
      />
      {/* Body Document item */}
      <div
        className={cn(
          isLastDocumentItem ? '' : 'border-b-[1px]',
          'relative bg-white p-4'
        )}
        style={{
          boxShadow:
            '0px 6px 2px 0px rgba(0, 0, 0, 0.00), 0px 4px 1px 0px rgba(0, 0, 0, 0.00), 0px 2px 1px 0px rgba(0, 0, 0, 0.02), 0px 1px 1px 0px rgba(0, 0, 0, 0.03)',
        }}
      >
        <div
          ref={ref}
          className="flex items-stretch justify-start gap-5"
        >
          {isIndexed && (
            <Typography variant={'body'} className="font-medium">
              {documentItem.id}.
            </Typography>
          )}
          <div
            className="mr-8 flex flex-1 cursor-pointer flex-col items-stretch justify-start
              gap-2"
            onClick={() => navigate(`/documents/${documentItem.id}`)}
          >
            {documentItem.status ? (
              documentItem.status.code ===
              EDocumentStatus.ACTIVE_WITH_CHANGES ? (
                <div className="flex items-center justify-start gap-2">
                  <DocumentItemStatus
                    label={'Действующий'}
                    status={EDocumentStatus.ACTIVE_WITH_CHANGES}
                  />
                  <DocumentItemStatus
                    label={documentItem.status.name}
                    status={documentItem.status.code}
                  />
                </div>
              ) : (
                <div className="flex items-center justify-start gap-2">
                  <DocumentItemStatus
                    label={documentItem.status.name}
                    status={documentItem.status.code}
                  />
                </div>
              )
            ) : null}
            <Typography variant={'body'} className="font-medium">
              {documentItem.name}
            </Typography>
            <div className="-mt-1 flex flex-col items-stretch justify-start">
              {documentItem.requisites && (
                <div className="flex items-center justify-start gap-3">
                  <Typography
                    variant={'label'}
                    className="font-medium text-[#5E626A]"
                  >
                    {documentItem.requisites}
                  </Typography>
                </div>
              )}
            </div>
          </div>
          <div className="flex shrink grow-0 justify-start self-start">
            <BaseDocumentMenu menuItems={documentMenuItems} />
          </div>
        </div>
      </div>
    </>
  )
})

DocumentItem.displayName = 'DocumentItem'
