import { SubtypeData } from '@/features/filters/FilterAccordion/types'
import IconChevronRight from '@/shared/assets/icons/icon_chevron_right.svg?react'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/shared/ui/Accordion'
import { Checkbox } from '@/shared/ui/Checkbox'
import { Typography } from '@/shared/ui/Typography'
import { cn } from '@/shared/utils/common'
import React from 'react'

interface FilterAccordionProps {
  subtypes: SubtypeData[]
  level?: number
  parentId: number
  isFiltered: boolean
  selectedIndices: number[]
  handleCheckboxChange: (id: number) => void
}

export const FilterAccordion: React.FC<FilterAccordionProps> = ({
  parentId,
  level = 0,
  subtypes,
  isFiltered,
  selectedIndices,
  handleCheckboxChange,
}) => {
  let filteredSubtypes: SubtypeData[]

  if (isFiltered) {
    filteredSubtypes = subtypes
  } else {
    filteredSubtypes = subtypes.filter(
      (subtype) => subtype.parentId === parentId
    )
  }

  return (
    <>
      {filteredSubtypes.map((subtype) => {
        const hasChildren = subtypes.some((org) => {
          return org.parentId === subtype.id
        })

        return (
          <Accordion
            type="single"
            collapsible
            key={subtype.id}
            className={cn(
              level === 0 && 'border-l border-l-[#E3E5E8]'
            )}
          >
            <AccordionItem value={subtype.id.toString()}>
              <div
                className="flex cursor-pointer items-center justify-start gap-3 self-stretch px-3
                  py-3 md:py-[10px]"
                style={{ marginLeft: `${level * 16}px` }}
              >
                <Checkbox
                  variant={'secondary'}
                  id={`checkbox-${subtype.id}`}
                  className="shrink"
                  size={'small'}
                  checked={selectedIndices.includes(subtype.id)}
                  onCheckedChange={() =>
                    handleCheckboxChange(subtype.id)
                  }
                />
                <AccordionTrigger
                  className="p-0"
                  iconSvg={
                    hasChildren ? (
                      <IconChevronRight
                        className="mr-1 min-h-6 min-w-6 rounded-md bg-bg-light-gray-2 p-1
                          transition-transform duration-100 ease-in-out"
                      />
                    ) : null
                  }
                >
                  <span className="flex h-full items-center justify-start text-start">
                    <Typography
                      variant={'body'}
                      className="inline text-sm"
                    >
                      {subtype.name}{' '}
                      <span className="text-sm font-normal leading-5 text-[#747474]">
                        ({subtype.document_amount})
                      </span>
                    </Typography>
                  </span>
                </AccordionTrigger>
              </div>
              {hasChildren && (
                <AccordionContent className="ml-3 flex flex-col gap-3 border-l border-l-[#E3E5E8]">
                  <FilterAccordion
                    subtypes={subtypes}
                    level={level + 1}
                    isFiltered={false}
                    parentId={subtype.id}
                    selectedIndices={selectedIndices}
                    handleCheckboxChange={handleCheckboxChange}
                  />
                </AccordionContent>
              )}
            </AccordionItem>
          </Accordion>
        )
      })}
    </>
  )
}
