import { useHomePageChatInput } from '@/entities/chat/models/useHomePageChatInput'
import IconArrowActionGray from '@/shared/assets/icons/icon_arrow_action_gray.svg?react'
import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { IconButton } from '@/shared/ui/IconButton'
import { Input } from '@/shared/ui/Input'
import { cn } from '@/shared/utils/common'
import { forwardRef } from 'react'

export const HomePageChatInput = forwardRef<HTMLInputElement, {}>(
  (props, ref) => {
    const {
      error,
      isError,
      message,
      isLoading,
      handleSubmit,
      handleKeyDown,
      handleInputChange,
      isSubmitButtonDisabled,
    } = useHomePageChatInput()
    const isMobile = useMediaQuery('(max-width:480px)')

    return (
      <form
        onSubmit={handleSubmit}
        className={cn(
          `absolute bottom-3 flex w-full max-w-[850px] shrink basis-12
          items-center justify-center self-center`,
          !isMobile && 'pb-4'
        )}
      >
        <div className="relative flex w-full items-center bg-[#FCFCFC]">
          <Input
            textarea
            ref={ref}
            value={message}
            canRemove={false}
            onChange={handleInputChange}
            disabled={isLoading}
            className="w-full border-[1px] border-base-200 bg-base-20 pr-14"
            placeholder="Введите запрос"
            onKeyDown={handleKeyDown}
          />
          <IconButton
            type="submit"
            disabled={isLoading}
            className={cn(
              'absolute bottom-1 right-2 transform opacity-100',

              isSubmitButtonDisabled ? '[&_path]:fill-base-300' : ''
            )}
          >
            <IconArrowActionGray width={44} height={44} />
          </IconButton>
        </div>
      </form>
    )
  }
)

HomePageChatInput.displayName = 'HomePageChatInput'
