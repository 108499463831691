import { DocumentActionShowInfo } from '@/features/document/ui/DocumentActionShowInfo'
import IconBookmark from '@/shared/assets/icons/icon_bookmark_dark.svg?react'
import IconClose from '@/shared/assets/icons/icon_close.svg?react'
import IconDownloadDark from '@/shared/assets/icons/icon_download_dark.svg?react'
import IconFolderPlus from '@/shared/assets/icons/icon_folder_plus.svg?react'
import IconMoreDotsDark from '@/shared/assets/icons/icon_more_dots_dark.svg?react'
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from '@/shared/ui/Drawer'
import { Typography } from '@/shared/ui/Typography'
import { MouseEvent } from 'react'

interface DocumentsBaseActionsDrawerProps {
  documentId: number
}

export const DocumentsBaseActionsDrawer = ({
  documentId,
}: DocumentsBaseActionsDrawerProps) => {
  return (
    <Drawer direction="bottom">
      <DrawerTrigger
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation()
        }}
      >
        <IconMoreDotsDark />
      </DrawerTrigger>

      <DrawerContent className="z-[70] h-[62svh] overflow-y-hidden">
        <DrawerClose className="absolute right-3 top-3">
          <IconClose className="h-6 w-6" />
        </DrawerClose>
        <DrawerHeader className="flex items-center justify-start gap-3 p-0">
          <DrawerTitle>
            <Typography variant={'heading4'}>Действия</Typography>
          </DrawerTitle>
        </DrawerHeader>
        <DrawerDescription
          className="-mx-6 mt-8 flex flex-col items-stretch justify-start gap-6
            overflow-y-scroll px-6"
        >
          {/* Pass the documentId here */}
          <DocumentActionShowInfo isMobile documentId={documentId} />

          <div
            className="flex items-center justify-start gap-3 border-b
              border-b-stroke-light-gray-2 py-3"
          >
            <IconBookmark className="min-h-6 min-w-6" />

            <Typography
              variant={'label'}
              className="text-text-light-black-8"
            >
              Добавить в избранное
            </Typography>
          </div>
          <div
            className="flex items-center justify-start gap-3 border-b
              border-b-stroke-light-gray-2 py-3"
          >
            <IconFolderPlus className="min-h-6 min-w-6" />
            <Typography
              variant={'label'}
              className="text-text-light-black-8"
            >
              Добавить в папку
            </Typography>
          </div>
          <div
            className="flex items-center justify-start gap-3 border-b
              border-b-stroke-light-gray-2 py-3"
          >
            <IconDownloadDark className="min-h-6 min-w-6" />
            <Typography
              variant={'label'}
              className="text-text-light-black-8"
            >
              Скачать документ
            </Typography>
          </div>
        </DrawerDescription>
      </DrawerContent>
    </Drawer>
  )
}
