import { IChatHistoryItem } from '@/entities/chat/types'
import { useGetChatsQuery } from '@/shared/api/chat'
import { useTypedSelector } from '@/shared/store'
import { useNavigate } from 'react-router-dom'

type Props = {
  isFavoritesPage: boolean
}

export const useChatHistory = ({ isFavoritesPage }: Props) => {
  const navigate = useNavigate()

  const { isLoading, data, error } = useGetChatsQuery({
    page: 1,
    onlyFavorite: isFavoritesPage,
  })

  const { documents } = useTypedSelector(
    (state) => state.documentPreview
  )

  const chats = data?.chats || []

  const handleFavoritesToggled = () => {
    if (isFavoritesPage) {
      navigate('/')
    } else {
      navigate('/chats/favorites')
    }
  }

  const today = new Date().toISOString().split('T')[0]
  const yesterday = new Date(Date.now() - 86400000)
    .toISOString()
    .split('T')[0]

  const todayChats = chats.filter((chat) =>
    chat.last_used_at.startsWith(today)
  )
  const yesterdayChats = chats.filter((chat) =>
    chat.last_used_at.startsWith(yesterday)
  )
  const otherChats = chats.filter(
    (chat) =>
      !chat.last_used_at.startsWith(today) &&
      !chat.last_used_at.startsWith(yesterday)
  )

  const otherChatsByDate = otherChats.reduce(
    (acc, chat) => {
      const date = chat.last_used_at.split('T')[0]
      if (!acc[date]) {
        acc[date] = []
      }
      acc[date].push(chat)
      return acc
    },
    {} as Record<string, IChatHistoryItem[]>
  )

  return {
    isLoading,
    todayChats,
    yesterdayChats,
    otherChatsByDate,
    isFavoritesPage,
    handleFavoritesToggled,
    documents,
  }
}
