import { ISearchMode } from '@/entities/document'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export const useSearchModeFilter = (
  defaultSearchMode: ISearchMode
) => {
  const [selectedSearchMode, setSelectedSearchMode] =
    useState<ISearchMode>(defaultSearchMode)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const SearchMode = searchParams.get('search_mode') as ISearchMode
    if (SearchMode) {
      setSelectedSearchMode(SearchMode)
    }
  }, [location.search])

  const handleSearchModeChange = (searchMode: ISearchMode) => {
    setSelectedSearchMode(searchMode)
  }

  const handleApply = ({
    searchMode,
  }: {
    searchMode?: ISearchMode
  }) => {
    const modeToSet = searchMode ?? selectedSearchMode

    if (searchMode) {
      setSelectedSearchMode(searchMode)
    }

    const searchParams = new URLSearchParams(location.search)
    searchParams.set('search_mode', modeToSet)
    navigate({ search: searchParams.toString() })
  }

  const handleReset = () => {
    setSelectedSearchMode(defaultSearchMode)
    const searchParams = new URLSearchParams(location.search)
    searchParams.delete('search_mode')
    navigate({ search: searchParams.toString() })
  }

  return {
    selectedSearchMode,
    handleSearchModeChange,
    handleApply,
    handleReset,
  }
}
