import { EDocumentStatus } from '@/entities/document'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export const useDocumentStatusFilter = () => {
  const [selectedIndices, setSelectedIndices] = useState<
    EDocumentStatus[]
  >([])
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    const status = searchParams.get('status')?.split(',') || []
    if (status.length) {
      setSelectedIndices(
        status
          .map((value) => value as EDocumentStatus)
          .filter((value) =>
            Object.values(EDocumentStatus).includes(value)
          )
      )
    }
  }, [location.search])

  const handleCheckboxChange = (code: EDocumentStatus) => {
    setSelectedIndices((prev) =>
      prev.includes(code)
        ? prev.filter((index) => index !== code)
        : [...prev, code]
    )
  }

  const handleApply = () => {
    const searchParams = new URLSearchParams(location.search)
    searchParams.delete('status')
    if (selectedIndices.length > 0) {
      searchParams.set('status', selectedIndices.join(','))
    }
    navigate({ search: searchParams.toString() })
  }

  const handleReset = () => {
    setSelectedIndices([])
    const searchParams = new URLSearchParams(location.search)
    searchParams.delete('status')
    navigate({ search: searchParams.toString() })
  }

  return {
    selectedIndices,
    handleCheckboxChange,
    handleApply,
    handleReset,
  }
}
