import {
  DesktopRangePicker,
  DocumentStatusFilterMenuDesktop,
  SortModeFilterMenuDesktop,
} from '@/features/filters'
import { documentsApi } from '@/shared/api/documents'
import IconBookmarkSmall from '@/shared/assets/icons/icon_bookmark_small.svg?react'
import { useTypedSelector } from '@/shared/store'
import { IconButton, Typography } from '@/shared/ui'

export const WidgetDocumentFilters = () => {
  const totalDocuments = useTypedSelector((state) => {
    return documentsApi.endpoints.getDocuments.select({
      isMobile: true,
    })(state).data?.meta.total
  })

  const formattedTotalDocuments = totalDocuments
    ? new Intl.NumberFormat('ru-RU').format(totalDocuments)
    : undefined
  return (
    <div className="shrink-0 grow self-stretch border-b border-[#F0F0F0]">
      <div className="flex flex-nowrap items-center justify-between gap-3 py-3 pl-5 pr-3">
        <Typography
          variant={'label'}
          className="font-semibold text-base-400"
        >
          {totalDocuments
            ? `Найдено документов: ${formattedTotalDocuments}`
            : ''}
        </Typography>
        <div className="inline-flex shrink-0 grow items-center justify-end gap-4">
          <DesktopRangePicker
            totalDocuments={formattedTotalDocuments}
          />
          <DocumentStatusFilterMenuDesktop
            totalDocuments={formattedTotalDocuments}
          />
          <SortModeFilterMenuDesktop
            totalDocuments={formattedTotalDocuments}
          />
          <IconButton className="h-8 w-8 border-[1px] border-[#E3E5E8] bg-white p-2">
            <IconBookmarkSmall />
          </IconButton>
        </div>
      </div>
    </div>
  )
}
