// hooks/useWidgetDocumentsSearch.ts
import { useToast } from '@/shared/ui/Toast/useToast'
import { ChangeEvent, KeyboardEvent, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { CourtBaseFilterType } from '../types'

export const useCourtBaseFilterSearchInput = (
  onValueChange?: (value: string) => void
) => {
  const [value, setValue] = useState<string>('')
  const [searchParams, setSearchParams] = useSearchParams()
  const { toast } = useToast()
  const searchMode = searchParams.get('search_mode')

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    setValue(newValue)
    if (onValueChange) onValueChange(newValue)
  }

  const handleSubmit = () => {
    if (!value.trim()) {
      toast({
        title: 'Ошибка',
        description: 'Заполните поле поиска',
        variant: 'error',
      })
    } else {
      setSearchParams({
        ...Object.fromEntries(searchParams.entries()),
        search_query: value,
        search_mode: searchMode ?? 'IN_TITLE',
        page: '1',
      })
    }
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  return {
    value,
    handleChange,
    handleKeyDown,
    handleSubmit,
  }
}
