import IconFinanceGreen from '@/shared/assets/icons/icon_finance_green.svg?react'
import IconPeopleBlue from '@/shared/assets/icons/icon_people_blue.svg?react'
import IconWeightsOrange from '@/shared/assets/icons/icon_weights_orange.svg?react'
import { Typography } from '@/shared/ui'

// TODO: In the future these pre-prompts will be coming from backend and will provide values for the input
const homePagePrompts = [
  {
    name: 'Уголовное право',
    icon: IconWeightsOrange,
  },
  {
    name: 'Финансы',
    icon: IconFinanceGreen,
  },
  {
    name: 'Административное правонарушение',
    icon: IconPeopleBlue,
  },
]

export const HomePageWelcomeContainerMobile = () => {
  return (
    <div className="mt-11 flex flex-col items-stretch justify-start gap-8">
      {/* Header */}
      <Typography className="text-center text-[24px] font-bold leading-[32px] text-base-950">
        Добро пожаловать в{' '}
        <span
          className="text-fill-transparent bg-text-fade-blue-gradient bg-clip-text
            text-[24px] font-bold leading-[32px] text-transparent"
        >
          AI-ассистент!
        </span>
      </Typography>
      <div className="flex flex-col items-stretch justify-start gap-4">
        <Typography
          variant="label"
          className="text-center text-base-600"
        >
          Нажмите, чтобы посмотреть пример запроса
        </Typography>
        <div className="flex flex-wrap items-center justify-start gap-4">
          {homePagePrompts.map((prompt, index) => {
            const Icon = prompt.icon
            return (
              <button
                key={index}
                className="flex gap-3 rounded-2xl border-[1px] border-base-100 bg-base-50 px-4
                  py-2"
                style={{
                  boxShadow:
                    '0px 4px 1px 0px rgba(0, 0, 0, 0.00), 0px 2px 1px 0px rgba(0, 0, 0, 0.00), 0px 1px 1px 0px rgba(0, 0, 0, 0.01), 0px 1px 1px 0px rgba(0, 0, 0, 0.02)',
                }}
              >
                <Icon />
                <Typography
                  variant="label"
                  className="whitespace-nowrap text-base-700"
                >
                  {prompt.name}
                </Typography>
              </button>
            )
          })}
        </div>
        <button
          className="flex gap-3 self-center rounded-2xl border-[1px] border-base-100
            bg-base-50 px-4 py-2"
          style={{
            boxShadow:
              '0px 4px 1px 0px rgba(0, 0, 0, 0.00), 0px 2px 1px 0px rgba(0, 0, 0, 0.00), 0px 1px 1px 0px rgba(0, 0, 0, 0.01), 0px 1px 1px 0px rgba(0, 0, 0, 0.02)',
          }}
        >
          <Typography
            variant="label"
            className="whitespace-nowrap text-base-700"
          >
            Больше
          </Typography>
        </button>
      </div>
    </div>
  )
}
