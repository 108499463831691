import { ISearchMode } from '@/entities/document'
import { RadioGroup, RadioGroupItem } from '@/shared/ui/RadioButton'
import { Typography } from '@/shared/ui/Typography'

import { searchModeConfig } from '../../consts'

interface SearchModeRadioGroupProps {
  selectedSearchMode: ISearchMode
  handleSearchModeChange: (SearchMode: ISearchMode) => void
}

export const SearchModeRadioGroup: React.FC<
  SearchModeRadioGroupProps
> = ({ selectedSearchMode, handleSearchModeChange }) => {
  return (
    <RadioGroup
      value={selectedSearchMode}
      onValueChange={handleSearchModeChange}
      className="gap-6"
    >
      {Object.entries(searchModeConfig).map(([value, searchMode]) => (
        <label
          key={value}
          className="flex items-center justify-start gap-3 border-b
            border-b-stroke-light-gray-2 py-3"
        >
          {searchMode.icon && searchMode.icon}
          <Typography
            variant="body"
            className="flex flex-1 items-center justify-start gap-2 font-normal"
          >
            {searchMode.name}
            <span>{searchMode.badge && searchMode.badge}</span>
          </Typography>
          <RadioGroupItem value={value as ISearchMode} />
        </label>
      ))}
    </RadioGroup>
  )
}
