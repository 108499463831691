import { EDocumentStatus } from '@/entities/document'
import { documentStatusBadgeVariants } from '@/entities/document/consts'
import { DocumentItemStatus } from '@/entities/document/ui/DocumentItemStatus'
import { Checkbox } from '@/shared/ui/Checkbox'

import { DocumentStatus } from '../../types'

interface DocumentStatusItemProps {
  items: DocumentStatus[]
  selectedIndices: EDocumentStatus[]
  handleCheckboxChange: (code: EDocumentStatus) => void
}

export const DocumentStatusFilterItems: React.FC<
  DocumentStatusItemProps
> = ({ items, selectedIndices, handleCheckboxChange }) => {
  return (
    <>
      {Object.values(EDocumentStatus).map((status, index) => (
        <label
          key={index}
          htmlFor={`checkbox-${index}`}
          className="flex cursor-pointer flex-nowrap items-center justify-between gap-2
            self-stretch border-b border-b-[#E3E5E8] px-0 py-3"
        >
          <DocumentItemStatus
            status={status}
            label={documentStatusBadgeVariants[status].label}
          />
          <Checkbox
            value={status}
            id={`checkbox-${index}`}
            checked={selectedIndices.includes(status)}
            onCheckedChange={() => handleCheckboxChange(status)}
          />
        </label>
      ))}
    </>
  )
}
