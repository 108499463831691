import { useEffect, useState } from 'react'

import { categoriesConfig } from '../consts/categoriesConfig'
import { CategoriesPage, Category } from '../types'

interface UseFiltersProps {
  categoriesPage: CategoriesPage
}

export const useFilters = ({ categoriesPage }: UseFiltersProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const [categoriesData, setCategoriesData] = useState<Category[]>([])

  // Toggle menu state
  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  // Load preprocessed data into Dexie
  useEffect(() => {
    const loadDataToDexie = async () => {
      try {
        const config = categoriesConfig[categoriesPage]
        for (const { table, file } of Object.values(config)) {
          const count = await table.count()

          if (count === 0) {
            const response = await fetch(file)
            const data = await response.json()
            await table.bulkPut(data)
          }
        }
      } catch (error) {
        console.error('Error loading data into Dexie:', error)
      }
    }

    loadDataToDexie()
  }, [])

  useEffect(() => {
    const config = categoriesConfig[categoriesPage]
    const categoriesValues = Object.values(config).map(
      ({ category }) => category
    )

    setCategoriesData(categoriesValues)
  }, [])

  return {
    isMenuOpen,
    handleMenuToggle,
    categoriesData,
  }
}
