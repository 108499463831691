import {
  AssignedDoc,
  AssignedDocItem,
  AssignedDocItemMobile,
} from '@/entities/courtBase'
import {
  DownloadAssignedDocs,
  PrintAssignedDocs,
  SearchAssignedDocsInputMobile,
  SearchAssignedIconMobile,
  ToggleAssignedDocs,
} from '@/features/courtBase'
import { Accordion, Typography } from '@/shared/ui'
import { cn } from '@/shared/utils/common'
import { useEffect, useRef, useState } from 'react'

import { useCourtBaseAssignedDocs } from '../hooks/useCourtBaseAssignedDocs'

interface CourtBaseAssignedDocsMobileProps {
  assignedDocs: AssignedDoc[]
}

export const CourtBaseAssignedDocsMobile = ({
  assignedDocs,
}: CourtBaseAssignedDocsMobileProps) => {
  const accordionRefs = useRef<HTMLDivElement[]>([])
  const {
    openItems,
    isOpenSearch,
    filteredDocs,
    setSearchQuery,
    toggleOpenSearch,
    toggleAllAccordions,
    setOpenItems,
  } = useCourtBaseAssignedDocs({ assignedDocs })

  return (
    <div
      className="relative mt-[16px] flex w-full flex-col items-start justify-start
        rounded-3xl border border-base-100 px-2 py-4 shadow-shadow-modal-light"
    >
      <div
        className={cn(
          'flex w-full justify-between px-2 pb-3',
          isOpenSearch ? '' : 'border-b border-base-100'
        )}
      >
        <Typography variant={'body'} className="font-medium">
          Документы
        </Typography>
        <div className="flex justify-center gap-4">
          <SearchAssignedIconMobile
            toggleOpenSearch={toggleOpenSearch}
          />
          <DownloadAssignedDocs />
          <ToggleAssignedDocs
            toggleAllAccordions={toggleAllAccordions}
            isAnyOpen={Boolean(openItems.length)}
          />
        </div>
      </div>
      <SearchAssignedDocsInputMobile
        isOpen={isOpenSearch}
        onSearchChange={setSearchQuery}
      />

      <div className="pt-3">
        <Accordion
          type="multiple"
          className="flex flex-col gap-8"
          value={openItems}
          onValueChange={setOpenItems}
        >
          {filteredDocs.map((doc, index) => {
            return (
              <AssignedDocItemMobile
                key={doc.id}
                assignedDoc={doc}
                accordionRef={accordionRefs.current[index]}
              />
            )
          })}
        </Accordion>
      </div>
    </div>
  )
}
