import { ISearchMode } from '@/entities/document'
import IconAiFilledDark from '@/shared/assets/icons/icon_ai_filled_dark.svg?react'
import IconChevronRight from '@/shared/assets/icons/icon_chevron_right_dark.svg?react'
import IconSmallcapsDark from '@/shared/assets/icons/icon_smallcaps_dark.svg?react'
import { Checkbox } from '@/shared/ui/Checkbox'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/shared/ui/DropdownMenu'
import { RadioGroup, RadioGroupItem } from '@/shared/ui/RadioButton'
import { Typography } from '@/shared/ui/Typography'

import { mockSearchModeData } from '../../consts'
import { useSearchModeFilter } from '../../hooks/useSearchModeFilter'

export const SearchModeFilterMenuDesktop = () => {
  const {
    handleApply,
    handleReset,
    handleSearchModeChange,
    selectedSearchMode,
  } = useSearchModeFilter('CONTEXT')

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <div
          className="flex h-[40px] items-center justify-start gap-2 self-stretch
            rounded-[16px] border bg-[#FBFBFB] px-4 py-2"
        >
          {selectedSearchMode === 'IN_TITLE' ? (
            <>
              <IconSmallcapsDark className="h-4 w-4" />
              <Typography>{mockSearchModeData.IN_TITLE}</Typography>
              <IconChevronRight />
            </>
          ) : (
            <>
              <IconAiFilledDark className="h-4 w-4" />
              <Typography>{mockSearchModeData.CONTEXT}</Typography>
              <IconChevronRight />
            </>
          )}
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="rounded-2xl bg-white p-0">
        <DropdownMenuItem>
          <RadioGroup
            value={selectedSearchMode}
            onValueChange={handleSearchModeChange}
            className="gap-3"
          >
            {Object.entries(mockSearchModeData).map(
              ([value, label]) => (
                <label
                  key={value}
                  onClick={() =>
                    handleApply({ searchMode: value as ISearchMode })
                  }
                  className="flex items-center justify-start gap-3 rounded-2xl px-4 py-3
                    hover:cursor-pointer hover:bg-[#F0F0F0]"
                >
                  {value === 'IN_TITLE' ? (
                    <>
                      <IconSmallcapsDark />
                      <Typography
                        variant="body"
                        className="flex-1 text-start text-sm font-normal"
                      >
                        {label}
                      </Typography>
                      <RadioGroupItem value={value as ISearchMode} />
                    </>
                  ) : (
                    <>
                      <IconAiFilledDark className="h-5 w-5" />
                      <Typography
                        variant="body"
                        className="flex-1 text-sm font-normal"
                      >
                        {label}
                      </Typography>
                      <RadioGroupItem value={value as ISearchMode} />
                    </>
                  )}
                </label>
              )
            )}
          </RadioGroup>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
