import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { Outlet } from 'react-router-dom'

export const CourtBaseLayout = () => {
  const isMobile = useMediaQuery('(max-width: 960px)')

  if (isMobile)
    return (
      <div className="relative flex flex-1 flex-col">
        <Outlet context={{ isMobile: isMobile }} />
      </div>
    )

  return (
    <div className="relative flex flex-1 flex-col px-8 pt-24">
      <Outlet context={{ isMobile: isMobile }} />
    </div>
  )
}
