import { useGetDocumentInfoQuery } from '@/shared/api/documents'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/shared/ui/Dialog'
import { Skeleton } from '@/shared/ui/Skeleton'
import { Typography } from '@/shared/ui/Typography'

// Ensure correct path
import { IDocumentInfo } from '../../types'

interface DocumentInfoDialogProps {
  open: boolean
  documentId: number
  onClose: () => void
}
// TODO: Вынести в отдельный компонент
const DocumentInfoSkeleton = () => {
  return (
    <div className="flex flex-col gap-4 p-6">
      <Skeleton className="h-4 w-3/4" />
      <Skeleton className="h-4 w-1/2" />
      <Skeleton className="h-4 w-full" />
      <Skeleton className="h-4 w-full" />
      <Skeleton className="h-4 w-full" />
    </div>
  )
}

export const DocumentInfoDialog = ({
  documentId,
  open = false,
  onClose,
}: DocumentInfoDialogProps) => {
  const { data, isLoading, isError } = useGetDocumentInfoQuery(
    { id: documentId },
    { skip: !open }
  )

  const documentInfo: IDocumentInfo | undefined = data?.info

  return (
    <Dialog open={open}>
      <DialogContent
        onClose={onClose}
        className="z-[90] w-full max-w-[calc(100svw-24px)] gap-8 overflow-hidden
          rounded-3xl bg-white p-4 sm:p-6 lg:max-w-[934px]"
      >
        <DialogHeader>
          <DialogTitle className="text-start md:text-center">
            <Typography
              variant="heading5"
              className="md: text-[24px] text-lg"
            >
              {isLoading
                ? 'Загрузка информации о документе...'
                : isError
                  ? 'Ошибка при загрузке информации о документе'
                  : 'Информация о документе'}
            </Typography>
          </DialogTitle>
        </DialogHeader>

        {isLoading ? (
          <DialogDescription>
            <DocumentInfoSkeleton />
          </DialogDescription>
        ) : isError ? (
          <DialogDescription className="p-6">
            <Typography>
              Попробуйте обновить страницу или попробуйте позже.
            </Typography>
          </DialogDescription>
        ) : (
          <DialogDescription className="flex flex-col items-stretch justify-start gap-4 overflow-x-auto">
            {/* Publication Date */}
            <div
              className="flex flex-wrap items-center gap-2 border-b
                border-b-stroke-light-gray-1 py-2"
            >
              <Typography
                variant="body"
                className="basis-[152px] font-semibold"
              >
                Дата публикации:
              </Typography>
              <Typography
                variant="label"
                className="flex-1 whitespace-nowrap rounded-[4px] px-2 py-[2px]"
              >
                {documentInfo?.published_at ?? ''}
              </Typography>
            </div>

            {/* Document Types */}
            <div
              className="flex flex-wrap items-center gap-2 border-b
                border-b-stroke-light-gray-1 py-2"
            >
              <Typography
                variant="body"
                className="basis-[152px] font-semibold"
              >
                Тип документа:
              </Typography>
              <div className="flex flex-wrap items-center gap-2">
                {documentInfo?.types && documentInfo.types.length > 0
                  ? documentInfo.types.map((item, index) => (
                      <Typography
                        key={index}
                        variant="label"
                        className="whitespace-nowrap rounded-[4px] bg-stroke-light-gray-1 px-2 py-[2px]
                          font-semibold"
                      >
                        {item.name ?? ''}
                      </Typography>
                    ))
                  : null}
              </div>
            </div>

            {/* Legal Field */}
            <div
              className="flex flex-wrap items-center gap-2 border-b
                border-b-stroke-light-gray-1 py-2"
            >
              <Typography
                variant="body"
                className="basis-[152px] font-semibold"
              >
                Отрасль права:
              </Typography>
              <div className="flex flex-wrap items-center gap-2">
                {documentInfo?.groups &&
                documentInfo.groups.length > 0
                  ? documentInfo.groups.map((group, index) => (
                      <Typography
                        key={index}
                        variant="label"
                        className="whitespace-nowrap rounded-[4px] bg-stroke-light-gray-1 px-2 py-[2px]
                          font-semibold"
                      >
                        {group.name ?? ''}
                      </Typography>
                    ))
                  : null}
              </div>
            </div>

            {/* Developing Organizations */}
            <div
              className="flex flex-wrap items-center gap-2 border-b
                border-b-stroke-light-gray-1 py-2"
            >
              <Typography
                variant="body"
                className="basis-[152px] font-semibold"
              >
                Орган разработки:
              </Typography>
              <div className="flex flex-wrap items-center gap-2">
                {documentInfo?.developingOrganizations &&
                documentInfo.developingOrganizations.length > 0
                  ? documentInfo.developingOrganizations.map(
                      (org, index) => (
                        <Typography
                          key={index}
                          variant="label"
                          className="whitespace-nowrap rounded-[4px] bg-stroke-light-gray-1 px-2 py-[2px]
                            font-semibold"
                        >
                          {org.name ?? ''}
                        </Typography>
                      )
                    )
                  : null}
              </div>
            </div>

            {/* Approving Organizations */}
            <div className="flex flex-wrap items-center gap-2 py-2">
              <Typography
                variant="body"
                className="basis-[152px] font-semibold"
              >
                Орган принятия:
              </Typography>
              <div className="flex flex-wrap items-center gap-2">
                {documentInfo?.approvingOrganizations &&
                documentInfo.approvingOrganizations.length > 0
                  ? documentInfo.approvingOrganizations.map(
                      (org, index) => (
                        <Typography
                          key={index}
                          variant="label"
                          className="whitespace-nowrap rounded-[4px] bg-stroke-light-gray-1 px-2 py-[2px]
                            font-semibold"
                        >
                          {org.name ?? ''}
                        </Typography>
                      )
                    )
                  : null}
              </div>
            </div>
          </DialogDescription>
        )}
      </DialogContent>
    </Dialog>
  )
}
