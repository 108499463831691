import { ChatPanel } from '@/entities/chat/ui/ChatPanel'
import { DocumentPanel } from '@/entities/panel/ui/DocumentPanel'
import { cn } from '@/shared/utils/common'
import {
  Panel,
  PanelGroup,
  PanelResizeHandle,
} from 'react-resizable-panels'

import { useDocumentPrevew } from '../../hooks/useDocumentPreview'

export const DocumentPreviewContainer = () => {
  const {
    documents,
    isChatOpen,
    isAnyPanelOpen,
    isOpenDocumentsPanel,
    isVisibleSecondDocument,
    isVisibleThreePanel,
    panelRef,
  } = useDocumentPrevew()
  if (!documents.length) {
    return null
  }

  return (
    <>
      <PanelGroup
        ref={panelRef}
        className={cn(
          'bg-white',
          `w-[calc(100svw - 178px)] flex max-h-[calc(100%)] transform
          items-stretch justify-center gap-2 pr-[84px] pt-[88px] ease-in-out`,
          'origin-bottom pl-[100px] transition-[transform]',

          isOpenDocumentsPanel
            ? 'scale-y-100 duration-500'
            : 'duration-400 scale-y-0'
        )}
        autoSaveId="example"
        direction="horizontal"
      >
        <>
          <Panel
            className={cn(
              'rounded-t-[8px] bg-white shadow-shadow2',
              'transition-[max-width] duration-500 ease-in-out'
            )}
            defaultSize={50}
            minSize={30}
          >
            <DocumentPanel
              index={0}
              document={documents[0]}
              isVisibleThreePanel={isVisibleThreePanel}
            />
          </Panel>

          {isAnyPanelOpen && (
            <PanelResizeHandle
              className={cn(
                'h-[19px] w-[3px] self-center rounded-sm bg-[#182336] opacity-[0.38]',
                isAnyPanelOpen ? 'block' : 'hidden'
              )}
            />
          )}
          <Panel
            className={cn(
              'max-w-[auto]',
              'rounded-t-[8px] shadow-shadow2 transition-all duration-700 ease-in-out',
              isAnyPanelOpen
                ? 'max-w-[100svw] opacity-100'
                : 'max-w-[0px] opacity-0',
              isAnyPanelOpen ? 'duration-700' : 'duration-[800ms]',
              documents.length === 2
                ? 'duration-[400ms]'
                : 'duration-700',
              isVisibleSecondDocument ? 'opacity-100' : 'opacity-0',
              !isAnyPanelOpen && 'opacity-0'
            )}
            defaultSize={50}
            minSize={30}
          >
            {documents.length === 2 ? (
              <DocumentPanel
                index={1}
                document={documents[1]}
                isVisibleThreePanel={isVisibleThreePanel}
              />
            ) : (
              <ChatPanel isVisibleSecondPanel />
            )}
          </Panel>
        </>
      </PanelGroup>
      <PanelGroup
        direction="horizontal"
        className={cn(
          'fixed bottom-0 right-0 z-50 bg-transparent',
          `flex max-h-[calc(100svh-90px)] w-screen transform items-stretch
          justify-center gap-2 px-9 pt-0`,
          'origin-right transition-[all] duration-300 ease-in-out',
          isChatOpen && documents.length == 2
            ? 'scale-x-100 opacity-100'
            : 'scale-x-0 opacity-0'
        )}
        autoSaveId="example"
      >
        <Panel defaultSize={55} />
        <PanelResizeHandle className="h-[19px] w-[3px] self-center rounded-sm bg-[#182336] opacity-[0.38]" />
        <Panel
          className={cn(
            `relative flex w-[40svw] shrink-0 flex-col items-center rounded-t-[8px]
            bg-white px-2 pb-2 shadow-shadow2`
          )}
          defaultSize={45}
          minSize={30}
        >
          <ChatPanel />
        </Panel>
      </PanelGroup>
    </>
  )
}
