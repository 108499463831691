import {
  ECourtBaseStatus,
  ICourtItemById,
} from '@/entities/courtBase'

export const courtBaseIdData: ICourtItemById = {
  data: {
    id: '7599-24-00-2a',
    date: '17.09.2024',
    requirements:
      'Признании недействительным пункта договора об установлении комиссии за обслуживание займа, признании частично недействительным условия договора банковского займа и о взыскании удержанной суммы комиссии за обслуживание займа',

    plaintiffs: ['Перцев Д.Г.', 'Перцев Д.Г.'],
    defendants: ['Перцев Д.Г.', 'Перцев Д.Г.'],
    judges: ['Баратбеков Т.К.'],
    court: 'Алматинский городской суд (Апелляционная инстанция)',
    status: [ECourtBaseStatus.PENDING],
    additional_info: ['новый суд'],
    is_favorite: true,
    number: 1,
  },
  assignedDocs: [
    {
      date: '17.09.2024',
      id: '7599-34-00-2a',
      assignedDocInfo: `город Алматы
 
Судебная коллегия по гражданским делам Алматинского городского суда в составе председательствующего судьи Есжановой Г.К., судей Абсиметовой К.Д., Батырова Ж.А.,
с участием посредством мессенджера WhatsApp представителя истца Якуповой Н.М., Биржанова А.Г., представителя ответчика Абенова Б.Н.,
рассмотрев в открытом судебном заседании гражданское дело по иску товарищества с ограниченной ответственностью «SHEBERBUILD» к товариществу с ограниченной ответственностью ТОО «АРЫСТАН NZ» о взыскании суммы задолженности,
поступившее по апелляционной жалобе ответчика на решение специализированного межрайонного экономического суда города Алматы от 8 февраля 2024 года,
 
У С Т А Н О В И Л А:
Решением специализированного межрайонного экономического суда города Алматы от 8 февраля 2024 года иск товарищества с ограниченной ответственностью «SHEBERBUILD» к товариществу с ограниченной ответственностью ТОО «АРЫСТАН NZ» о взыскании суммы задолженности - частично удовлетворен.
Постановлено взыскать с ТОО «Арыстан NZ» в пользу ТОО «SHEBERBUILD» сумму в размере 43 593 728 (сорок три миллиона пятьсот девяносто три тысячи семьсот двадцать восемь) тенге, судебные расходы государственную пошлину в размере 1 307 811, 84 (один миллион триста семь тысяч восемьсот одиннадцать) тенге 84 тиын.
В удовлетворении остальной части иска отказать.
Не соглашаясь с вынесенным судебным актом, ответчик в своей апелляционной жалобе просит его отменить, с принятием нового решения об отказе в удовлетворении иска в полном объеме.
Заслушав пояснения представителей ответчика, поддержавших доводы жалобы, возражения представителя истца, исследовав доводы жалобы, изучив материалы дела, коллегия полагает, что обжалуемый судебный акт подлежит оставлению без изменения по следующим основаниям.
Материалами дела установлено, что 24 апреля 2023 года между ТОО «SHEBERBUILD» (далее- «Истец») и ТОО «Арыстан NZ» (далее - «Ответчик») заключен договор подряда №15/USH/2023 (далее - договор №1), согласно которому, ответчик принял на себя обязательство по выполнению работ на объекте: «Строительство краеведческого музея с актовым залом в г.Уштобе Каратальского района» Жетысуской области.
3 июня 2023 года между ТОО «SHEBERBUILD» и ТОО «Арыстан NZ» заключено дополнительное соглашение к договору подряда № 15/USH/2023 от 24 апреля 2023 года (далее - дополнительное соглашение).
Согласно пункту 3.1. дополнительного соглашения стоимость работ по договору составила 65 201 201, 98 (шестьдесят пять миллионов двести одна тысяча двести один) тенге 98 тиын.
Сторонами был определен срок начала выполнения работ 11 мая 2023 года и срок окончания работ 30 июля 2023 года.
3 мая 2023 года между ТОО «SHEBERBUILD» и ТОО «Арыстан NZ» был заключен договор подряда № 16/USH/2023 (далее - договор №2), согласно которому, ответчик принял на себя обязательство по выполнению работ на объекте: «Строительство краеведческого музея с актовым залом в г.Уштобе Каратальского района».
Стоимость работ по договору №2 составила 37 999 697 (тридцать семь миллионов девятьсот девяносто девять тысяч шестьсот девяносто семь) тенге.
Сторонами был определен срок начала выполнения работ по договору № 2 03 мая 2023 года и срок окончания работ 8 июня 2023 года.
Истец выплатил ответчику по договору № 1 сумму в размере 20 172 500 (двадцать миллионов сто семьдесят две тысячи пятьсот) тенге и по договору № 2 сумму в размере 23 593 728 (двадцать три миллиона пятьсот девяносто три тысячи семьсот двадцать восемь) тенге.
Ответчиком принятые обязательства по договорам исполнены не надлежащим образом, акты выполненных работ сторонами не подписаны, имеются задолженности по договорам.
В соответствии со статьями 272, 273 Гражданского кодекса Республики Казахстан (далее ГК), обязательство должно исполняться надлежащим образом в соответствии с условиями обязательства и требованиями законодательства. Односторонний отказ от исполнения обязательства и одностороннее изменение его условий не допускается.
В соответствии со статьей 683 ГК по договору возмездного оказания услуг исполнитель обязуется по заданию заказчика оказать услуги (совершить определенные действия или осуществить определенную деятельность), а заказчик обязуется оплатить эти услуги.
На основе представленных доказательств, суд первой инстанции правильно пришел к выводу, что ответчиком не были выполнены обязательства по договорам, в результате неисполнении условий договоров образовалась задолженность в размере 43 766 228 тенге.
Позиция суда основана на законе и доказательствах, исследованных в судебных заседаниях.
Основания и мотивы принятого решения подробно изложены в судебном акте с приведением конкретных норм закона, которые подлежали применению.
Несогласие стороны с выводами суда, базирующимся на беспристрастном, полном и объективном исследовании обстоятельств дела не является основанием для пересмотра оспариваемого судебного акта.
С учётом вышеизложенного, судебная коллегия полагает возможным также применить часть вторую статьи 426 ГПК, согласно которой, при оставлении апелляционной жалобы без удовлетворения в связи с отсутствием новых доводов в мотивировочной части апелляционного постановления указывается только на отсутствие предусмотренных настоящим Кодексом оснований для внесения изменений в решение суда первой инстанции либо его отмены.
Доводы апелляционной жалобы ответчика, были тщательно исследованы судом первой инстанции в ходе рассмотрения дела и им дана надлежащая правовая оценка.
При этом новые доводы, не являвшиеся предметом рассмотрения в суде первой инстанции, в жалобе не приведены.
Таким образом, коллегия находит, что фактические обстоятельства, имеющие значение для дела, судом первой инстанции установлены правильно, каких-либо нарушений при применении и толковании норм гражданского законодательства, а также норм гражданского процессуального закона судом не допущено.
Оснований для внесения изменений в судебный акт либо его отмены в апелляционном порядке не имеется.
Кроме того в пункте 4 статьи 663 ГК указано, что сдача результатов работ подрядчиком и приемка их заказчиком оформляются актом, подписанным обеими сторонами, а в случаях, предусмотренных законодательными актами, - также представителями государственных органов и органов местного самоуправления. При отказе одной из сторон от подписания акта в нем делается отметка об этом и акт подписывается другой стороной. Односторонний акт сдачи или приемки результатов работ может быть признан судом действительным лишь в случае, если мотивы отказа от подписания акта признаны судом необоснованными.
Имеется вступившую в законную силу решение СМЭС г.Алматы от 23 мая 2024 года об оставлении без удовлетворения исковые требования ТОО «АРЫСТАН NZ» о признании актов выполненных работ действительными и взыскании задолженности ТОО SHEBERBUILD» и встречные исковые требования ТОО «SHEBERBUILD» к ТОО «АРЫСТАН NZ» о расторжении договоров подряда № 16/USH/2023 от 3 мая 2023 года и № 15/USH/2023 от 24 апреля 2023 года.
С учетом изложенного, коллегия находит доводы апелляционной жалобы необоснованными и подлежащими оставлению без удовлетворения, а решение суда является законным и обоснованным, оснований для его отмены либо изменения, не имеется.
Руководствуясь п.1 ст.424, п.1 ст.425, 426, 431 ГПК РК, апелляционная судебная коллегия
 
П О С Т А Н О В И Л А:
Решение специализированного межрайонного экономического суда города Алматы от 8 февраля 2024 года оставить без изменения.
Апелляционную жалобу ответчика -без удовлетворения.
Постановление коллегии вступает в законную силу с момента его оглашения.
В силу п.п.3) ч.2 ст.434 Гражданского процессуального кодекса РК постановление не подлежит пересмотру в кассационном порядке.
 
 
Председательствующий
Есжанова Г.К.
 
Судьи
Батыров Ж.А.
Абсиметова К.Д.`,
      additional_info:
        'Решение Специализированного межрайонного экономического суда Акмолинской области от 5 апреля 2024 ',
    },
    {
      date: '17.09.2024',
      id: '7599-24-0-2a',
      assignedDocInfo:
        'Судебная коллегия по гражданским делам Алматинского городского суда в составе председательствующего судьи Есжановой Г.К., судей Абсиметовой К.Д., Батырова Ж.А., ',
      additional_info:
        ' Решение Специализированного межрайонного экономического суда Акмолинской области от 5 апреля 2024',
    },
    {
      date: '17.09.2024',
      id: '7599-24-80-2a',
      assignedDocInfo:
        'Решение специализированного межрайонного экономического суда города Алматы от 8 февраля 2024 года оставить без изменения.  Есжановой Г.К., судей Абсиме ',

      additional_info:
        'Решение Специализированного межрайонного экономического суда Акмолинской области от 5 апреля 2024 Решение Специализированного межрайонного экономического суда Акмолинской области от 5 апреля 2024',
    },
    {
      date: '17.09.2024',
      id: '7599-24-06-2a',
      assignedDocInfo:
        'Основания и мотивы принятого решения подробно изложены в судебном акте с приведением конкретных норм закона, которые подлежали применению.',

      additional_info:
        'Решение Специализированного межрайонного экономического суда Акмолинской области от 5 апреля 2024 ',
    },
  ],
}
