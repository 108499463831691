import { IChatAiFile } from '@/entities/chat/types'
import { ChatAiFileDrawerMenu } from '@/entities/chat/ui/ChatAiFileDrawerMenu'
import { useGetDocumentByIdQuery } from '@/shared/api/documents'
import IconClose from '@/shared/assets/icons/icon_close.svg?react'
import IconExport from '@/shared/assets/icons/icon_export.svg?react'
import IconMoreDots from '@/shared/assets/icons/icon_more_dots_dark.svg?react'
import { IconButton, Typography } from '@/shared/ui'
import { cn } from '@/shared/utils/common'
import { useEffect, useRef, useState } from 'react'

import { useDocumentPanel } from '../../modal/useDocumentPanel'
import { DocumentInfoDialog } from '../DocumentInfoDialog'
import { DocumentPanelSkeleton } from '../DocumentPanelSkeleton'
import { RawHtmlRenderer } from '../RawHtmlRenderer'

interface DocumentPanelsProps {
  document: IChatAiFile
  isVisibleThreePanel: boolean
  index: number
}

export const DocumentPanel = ({
  document,
  isVisibleThreePanel,
  index,
}: DocumentPanelsProps) => {
  const {
    isInfoDialogOpen,
    handleRemoveDocument,
    handleInfoClose,
    handleInfoClick,
  } = useDocumentPanel()
  const { data, error, isLoading, isFetching } =
    useGetDocumentByIdQuery({
      id: Number(document.id),
    })

  const secondPanelRef = useRef<HTMLDivElement>(null)
  const [panelWidth, setPanelWidth] = useState(0)

  useEffect(() => {
    if (secondPanelRef.current) {
      const { width } = secondPanelRef.current.getBoundingClientRect()
      setPanelWidth(Number(width.toFixed(0)))
    }
  }, [secondPanelRef])

  return (
    <div
      ref={secondPanelRef}
      className={cn(
        'h-full pb-2',
        'relative flex shrink-0 flex-col items-center',
        'transition-[all] duration-700 ease-in-out'
      )}
    >
      <div
        className="flex min-h-[60px] items-start justify-between gap-8 self-stretch
          border-b-[1px] border-base-100 p-4"
      >
        <div className="flex items-center justify-between gap-8">
          <ChatAiFileDrawerMenu onInfoClick={handleInfoClick}>
            <IconButton>
              <IconMoreDots />
            </IconButton>
          </ChatAiFileDrawerMenu>
          <IconButton>
            <IconExport width={'24px'} height={'24px'} />
          </IconButton>
        </div>
        <Typography
          variant={'title'}
          className={cn(
            'truncate font-bold',
            'transition-[all] duration-700 ease-in-out',
            isVisibleThreePanel ? 'opacity-100' : 'opacity-0'
          )}
          title={document.name}
        >
          {document.name}
        </Typography>
        <div className="flex items-center justify-between gap-8">
          <div className="h-6 w-6" />
          <IconButton
            onClick={() => handleRemoveDocument(document.id, index)}
          >
            <IconClose width="26px" height="26px" />
          </IconButton>
        </div>
      </div>
      <div
        className="custom-scrollbar flex flex-col items-center gap-4 self-stretch
          overflow-y-auto px-[40px] py-6"
      >
        {' '}
        {!isLoading && !isFetching && data && isVisibleThreePanel ? (
          <RawHtmlRenderer rawHtml={data.document.content} />
        ) : (
          <DocumentPanelSkeleton />
        )}
      </div>
    </div>
  )
}
