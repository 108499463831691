import { useChatInput } from '@/entities/chat/models/useChatInput'
import IconArrowActionGray from '@/shared/assets/icons/icon_arrow_action_gray.svg?react'
import { useTypedSelector } from '@/shared/store'
import { IconButton } from '@/shared/ui/IconButton'
import { Input } from '@/shared/ui/Input'
import { cn } from '@/shared/utils/common'
import { FormEvent, KeyboardEvent } from 'react'
import { useParams } from 'react-router-dom'

interface Props
  extends React.InputHTMLAttributes<
    HTMLTextAreaElement | HTMLInputElement
  > {
  placeholder?: string
  label?: string
  error?: string
  isPanel?: boolean
}

export const ChatInput = ({ isPanel = false, ...props }: Props) => {
  const { chat_id } = useParams<{ chat_id: string }>()
  const {
    isSending,
    inputValue,
    selectRef,
    handleInputChange,
    handleSubmit,
  } = useChatInput({ chatId: chat_id })

  const isAwaitingResponse = useTypedSelector(
    (state) =>
      state.chats.chatsData[chat_id!]?.isAwaitingResponse ?? false
  )

  const handleKeyDown = (
    event: KeyboardEvent<HTMLTextAreaElement>
  ) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      handleSubmit(event as unknown as FormEvent<HTMLFormElement>)
    }
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        if (!inputValue.trim()) return
        handleSubmit(e)
      }}
      className={cn(
        'z-20 mx-auto flex w-full items-center justify-center lg:max-w-[850px]',
        isPanel ? 'bottom-2 px-4 pb-4' : 'bottom-2'
      )}
    >
      <div className="relative flex w-full items-center rounded-[100px] bg-base-100">
        <Input
          textarea
          {...props}
          ref={selectRef}
          value={inputValue}
          canRemove={false}
          onKeyDown={handleKeyDown}
          onChange={handleInputChange}
          isPanel
          disabled={isSending || isAwaitingResponse}
          className="w-full bg-base-100 pr-14"
          placeholder="Введите запрос"
        />
        <IconButton
          type="submit"
          disabled={isSending || !inputValue || isAwaitingResponse}
          className="absolute bottom-1 right-2 transform opacity-100 [&_svg]:text-black
            [&_svg]:disabled:text-base-300"
        >
          <IconArrowActionGray width={44} height={44} />
        </IconButton>
      </div>
    </form>
  )
}
