import { Skeleton } from '@/shared/ui/Skeleton'
import { forwardRef } from 'react'

const CourtBaseItemSkeleton = forwardRef<HTMLDivElement>((_, ref) => (
  <Skeleton
    className="flex shrink grow-0 basis-[86px] items-stretch justify-start gap-1
      rounded-none py-3 pl-4 pr-14 shadow-shadow3"
  >
    <div className="flex shrink grow basis-[44px] items-start justify-start">
      <Skeleton className="h-6 w-6 bg-base-50" />
    </div>
    <div className="flex w-full grow basis-full flex-col items-stretch gap-5">
      <div className="flex w-full grow basis-full items-stretch justify-between gap-2">
        <Skeleton className="h-6 w-52 bg-base-50" />
        <Skeleton className="h-6 w-32 bg-base-50" />
      </div>
      <div className="flex grow basis-full flex-col items-stretch justify-start gap-2">
        {Array.from({ length: 5 }).map((_, index) => (
          <div className="flex grow basis-full items-stretch justify-start gap-2">
            <Skeleton className="h-6 w-20 bg-base-50" />
            <Skeleton className="h-6 w-52 bg-base-50" />
          </div>
        ))}
      </div>
    </div>
  </Skeleton>
))

CourtBaseItemSkeleton.displayName = 'CourtBaseItemSkeleton'

export { CourtBaseItemSkeleton }
