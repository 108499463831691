import { EDocumentStatus } from '../types'

export const isDocumentStatus = (
  status: any
): status is { code: EDocumentStatus; name: string } => {
  return (
    status &&
    typeof status.code === 'string' &&
    Object.values(EDocumentStatus).includes(status.code) &&
    typeof status.name === 'string'
  )
}
