import IconBookmark from '@/shared/assets/icons/icon_bookmark_mobile.svg?react'
import { Typography } from '@/shared/ui'
import { cn } from '@/shared/utils/common'
import React from 'react'

import { useToogleFavouriteDocument } from '../../hooks/useToogleFavouriteDocument'

type MobileButtonToggleFavoriteDocument = {
  docId: number
  isFavorite?: boolean
}

export const MobileButtonToggleFavoriteDocument = ({
  docId,
  isFavorite,
}: MobileButtonToggleFavoriteDocument) => {
  const { handleToggleFavorite, isError, isLoading, isSuccess } =
    useToogleFavouriteDocument(docId)

  return (
    <div
      onClick={handleToggleFavorite}
      className="flex items-center justify-start gap-3 border-b
        border-b-stroke-light-gray-2 py-3"
    >
      <IconBookmark
        className={cn(
          '[&_path]:stroke-[#25272C]',
          isFavorite
            ? '[&_path]:fill-additional-blue-2 [&_path]:stroke-additional-blue-2'
            : ''
        )}
      />
      <Typography variant={'body'}>
        {isFavorite ? 'В избранном' : 'В избранное'}
      </Typography>
    </div>
  )
}
