import {
  DocumentQueryParams,
  IDocument,
  Locale,
  Paragraph,
  Version,
} from '@/entities/document'
import { IDocumentInfo } from '@/entities/panel/types'
import {
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react'
import Cookies from 'js-cookie'

import { config } from '..'

interface DocumentsResponse {
  success: boolean
  meta: Meta
  documents: IDocument[]
}

interface DocumentByIdResponse {
  success: boolean
  document: DocumentData
  meta: Meta
}

//TODO: paragraphs changed to content.Also need to add requisites and status

interface DocumentInfoResponse {
  success: boolean
  info: IDocumentInfo
}
interface DocumentData {
  id: number
  name: string
  locale: Locale
  paragraphs: Paragraph[]
  versions: Version[]
  content: string
}

interface Meta {
  //TODO: Вынести Meta в shared/api
  total: number
  per_page: number
  current_page: number
  next_page: number | null
  last_page: number
}

export const documentsApi = createApi({
  reducerPath: 'documentsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: config.API_BASE,
    prepareHeaders: (headers) => {
      const token = Cookies.get('access_token')
      if (token) {
        headers.set('Authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  tagTypes: ['Documents', 'DocumentInfo'],
  endpoints: (builder) => ({
    getDocuments: builder.query<
      DocumentsResponse,
      DocumentQueryParams & { isMobile?: boolean }
    >({
      query: ({ isMobile, ...params }) => {
        return {
          url: '/documents',
          params: {
            page: params.page ?? 1,
            ...params,
          },
        }
      },
      providesTags: (result, error, { isMobile }) => {
        if (isMobile) {
          // Tags for infinite scrolling
          return result
            ? [
                ...result.documents.map(({ id }) => ({
                  type: 'Documents' as const,
                  id,
                })),
              ]
            : []
        } else {
          // Tags for regular pagination
          return result
            ? [
                ...result.documents.map(({ id }) => ({
                  type: 'Documents' as const,
                  id,
                })),
                { type: 'Documents', id: 'PARTIAL-LIST' },
              ]
            : [{ type: 'Documents', id: 'PARTIAL-LIST' }]
        }
      },
      keepUnusedDataFor: 86400,
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const { isMobile, page, ...params } = queryArgs

        if (isMobile) {
          // For mobile queries, we create a key that includes all params except page.
          // This ensures that when search parameters change, the key changes and a new request fires.
          return `${endpointName}?${JSON.stringify(params)}`
        } else {
          // For desktop queries, keep the existing behavior (you can tweak if needed)
          return `${endpointName}?${JSON.stringify(queryArgs)}`
        }
      },
      // serializeQueryArgs: ({ endpointName, queryArgs }) => {
      //   const { isMobile, ...params } = queryArgs

      //   // Serialize other query arguments to create a unique cache key for desktop
      //   const serializedParams = JSON.stringify(params)

      //   // Use endpointName and serialized params for desktop, or only endpointName for mobile
      //   return isMobile
      //     ? endpointName
      //     : `${endpointName}?${serializedParams}`
      // },

      forceRefetch: ({ currentArg, previousArg }) => {
        if (currentArg?.isMobile) {
          return currentArg.page !== previousArg?.page // Force refetch for mobile when page changes
        }
        return false // Default behavior for desktop
      },
      merge: (currentCache, newData, { arg }) => {
        if (!arg.isMobile) {
          return newData // For non-mobile just replace data
        }

        if (arg.page === 1) {
          // Replace when page is 1, to avoid duplicating after reload
          return newData
        } else {
          // For pages > 1, append, but ensure no duplicates
          const existingIds = new Set(
            currentCache.documents.map((d) => d.id)
          )
          const uniqueNewDocuments = newData.documents.filter(
            (d) => !existingIds.has(d.id)
          )
          currentCache.documents.push(...uniqueNewDocuments)
          currentCache.meta = newData.meta
          return currentCache
        }
      },
    }),
    getDocumentById: builder.query<
      DocumentByIdResponse,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/documents/${id}`,
      }),
      providesTags: (result, error, arg) => [
        { type: 'Documents', id: arg.id },
      ],
    }),
    getDocumentInfo: builder.query<
      DocumentInfoResponse,
      { id: number }
    >({
      query: ({ id }) => ({
        url: `/documents/${id}/info`,
      }),

      providesTags: (result, error, arg) => [
        { type: 'DocumentInfo', id: arg.id },
      ],
      keepUnusedDataFor: 86400,
    }),
    getFavorites: builder.query<DocumentsResponse, { page?: number }>(
      {
        query: ({ page = 1 }) => ({
          url: `/documents/favorite`,
          method: 'GET',
          params: { page },
          headers: {
            Authorization: `Bearer ${Cookies.get('access_token')}`,
          },
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.documents.map(({ id }) => ({
                  type: 'Documents' as const,
                  id,
                })),
                { type: 'Documents', id: 'Favorites' },
              ]
            : [{ type: 'Documents', id: 'Favorites' }],
      }
    ),
    postToggleFavoriteDocument: builder.mutation<
      { status: boolean },
      { document_id: number }
    >({
      query: ({ document_id }) => ({
        url: `/documents/${document_id}/favorite`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${Cookies.get('access_token')}`,
        },
      }),
      invalidatesTags: (result, error, { document_id }) => [
        { type: 'Documents', id: document_id },
        { type: 'Documents', id: 'Favorites' },
      ],
      async onQueryStarted(
        { document_id },
        { dispatch, queryFulfilled }
      ) {
        const patchResult = dispatch(
          documentsApi.util.updateQueryData(
            'getDocuments',
            { isMobile: false },
            (draft) => {
              const document = draft.documents.find(
                (doc) => doc.id === document_id
              )
              if (document) {
                document.is_favorite = !document.is_favorite
              }
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
    }),
  }),
})

export const {
  useGetDocumentsQuery,
  useGetDocumentInfoQuery,
  useGetDocumentByIdQuery,
  useGetFavoritesQuery,
  usePostToggleFavoriteDocumentMutation,
} = documentsApi
