import { Checkbox } from '@/shared/ui/Checkbox'
import { RadioGroup, RadioGroupItem } from '@/shared/ui/RadioButton'
import { Typography } from '@/shared/ui/Typography'
import React from 'react'

import { ISortMode } from '../../types'

type Props = {
  handleSortModeChange: (sortMode: ISortMode) => void
  items: Partial<Record<ISortMode, string>>
  selectedSortMode: string
}

export const SortModeFilterItemDesktop = ({
  handleSortModeChange,
  items,
  selectedSortMode,
}: Props) => {
  return (
    <>
      <RadioGroup
        value={selectedSortMode}
        onValueChange={handleSortModeChange}
        className="gap-6 px-3 py-4"
      >
        {Object.entries(items).map(([value, label]) => (
          <label
            key={value}
            className="flex items-center justify-start gap-3"
          >
            <RadioGroupItem value={value as ISortMode} />
            <Typography
              variant="body"
              className="text-sm font-normal"
            >
              {label}
            </Typography>
          </label>
        ))}
      </RadioGroup>
      {/* {Object.entries(items).map(([key, value], index) => (
        <label
          key={index}
          htmlFor={`checkbox-${key}`}
          className="flex cursor-pointer items-center justify-start gap-2 self-stretch px-3
            py-3"
        >
          <Checkbox
            variant={'secondary'}
            size={'small'}
            id={`checkbox-${key}`}
            checked={selectedIndices.includes(key)}
            onCheckedChange={() =>
              handleCheckboxChange(key as ISortMode)
            }
          />
          <span>
            <Typography variant={'body'} className="inline">
              {value}
            </Typography>
          </span>
        </label>
      ))} */}
    </>
  )
}
