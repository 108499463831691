import IconAiEmpty from '@/shared/assets/icons/icon_ai_empty.svg?react'
import IconAiFilledDark from '@/shared/assets/icons/icon_ai_filled_dark.svg?react'
import IconProfile from '@/shared/assets/icons/icon_avatar_gray.svg?react'
import IconDocumentsDark from '@/shared/assets/icons/icon_documents_dark.svg?react'
import IconDocumentsFilled from '@/shared/assets/icons/icon_documents_filled.svg?react'
import IconMoreDark from '@/shared/assets/icons/icon_more_dark.svg?react'
import IconWeightsDark from '@/shared/assets/icons/icon_weights_dark.svg?react'
import IconWeightsFilled from '@/shared/assets/icons/icon_weights_filled.svg?react'
import { Typography } from '@/shared/ui/Typography'
import { cn } from '@/shared/utils/common'
import { ReactNode, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

interface IBottomNavigationItem {
  icon: ReactNode
  iconActive: ReactNode
  title: string
  pages: string[]
  disabled?: boolean
}
const navItems: IBottomNavigationItem[] = [
  {
    icon: <IconDocumentsDark height={28} width={28} />,
    iconActive: <IconDocumentsFilled height={28} width={28} />,
    title: 'НПА',
    pages: ['documents'],
    disabled: false,
  },
  {
    icon: <IconWeightsDark height={28} width={28} />,
    iconActive: <IconWeightsFilled height={28} width={28} />,
    title: 'Суд.база',
    pages: ['court-base'],
    disabled: false,
  },
  {
    icon: <IconAiEmpty height={28} width={28} />,
    iconActive: <IconAiFilledDark height={28} width={28} />,
    title: 'AI',
    pages: ['', 'chats'],
    disabled: false,
  },
  {
    icon: <IconMoreDark stroke="#18233661" height={28} width={28} />,
    iconActive: (
      <IconMoreDark stroke="#18233661" height={28} width={28} />
    ),
    title: 'Еще',
    pages: ['more'],
    disabled: true,
  },
  {
    icon: <IconProfile stroke="#18233661" height={28} width={28} />,
    iconActive: (
      <IconProfile stroke="#18233661" height={28} width={28} />
    ),
    title: 'Аккаунт',
    pages: ['profile'],
    disabled: true,
  },
]

export const BottomNavigationBar = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const currentPage = location.pathname.split('/')[1] || ''

  const handleNavigation = (pages: string[]) => {
    const targetPage = pages[0] // Navigate to the first page in the array
    navigate(`/${targetPage}`)
  }

  return (
    <div
      className="fixed bottom-0 z-[60] flex w-full items-center self-stretch border-t
        bg-white pb-2"
    >
      {navItems.map((item, index) => {
        const isActive = item.pages.includes(currentPage)

        return (
          <button
            key={index}
            disabled={item.disabled}
            className="flex shrink-0 grow basis-0 cursor-pointer flex-col items-center gap-1
              px-0 pb-[2px] pt-2"
            onClick={() => handleNavigation(item.pages)}
          >
            {isActive ? item.iconActive : item.icon}
            <Typography
              variant={'label'}
              className={cn(
                isActive ? 'text-base-950' : 'text-base-400'
              )}
            >
              {item.title}
            </Typography>
          </button>
        )
      })}
    </div>
  )
}
