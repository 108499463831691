import { ICourtItem } from '@/entities/courtBase'
import { CourtBaseItemStatus } from '@/entities/courtBase/ui/CourtBaseItemStatus'
import { Typography } from '@/shared/ui'
import { cn } from '@/shared/utils/common'
import React from 'react'

interface CourtBaseByIdItemProps {
  mainInfo: ICourtItem
}

export const CourtBaseByIdCaseMobile = ({
  mainInfo,
}: CourtBaseByIdItemProps) => {
  const courtByIdData = [
    { name: 'Истцы', data: mainInfo.plaintiffs },
    { name: 'Ответчики', data: mainInfo.defendants },
    { name: 'Суд', data: [mainInfo.court] },
    { name: 'Судьи', data: mainInfo.judges },
    { name: 'Требования', data: [mainInfo.requirements] },
  ]

  return (
    <>
      <div
        className={cn(
          `relative mt-[15px] flex gap-5 rounded-[16px] border-[1px]
          border-base-100 bg-white py-2 pb-4`
        )}
        style={{
          boxShadow:
            '0px 6px 2px 0px rgba(0, 0, 0, 0.00), 0px 4px 1px 0px rgba(0, 0, 0, 0.00), 0px 2px 1px 0px rgba(0, 0, 0, 0.02), 0px 1px 1px 0px rgba(0, 0, 0, 0.03)',
        }}
      >
        {/* Header */}
        <div className="relative flex w-full flex-col items-start justify-start">
          <div className="flex w-full justify-start gap-1.5 px-3 pb-2">
            <Typography variant={'body'} className="font-medium">
              3.
            </Typography>
            <Typography variant={'body'} className="pr-4 font-medium">
              {mainInfo.date ? `От ${mainInfo.date}` : null}
            </Typography>
          </div>

          <div className="w-full cursor-pointer border-t-[1px] border-base-100 p-3 pt-4">
            <Typography variant={'body'} className="font-medium">
              {mainInfo.id
                ? `Гражданское дело №${mainInfo.id}`
                : null}
            </Typography>
          </div>
          {/* Body */}
          <div className="grid grid-cols-[1fr_auto] gap-x-2.5 gap-y-1.5 px-3">
            <Typography
              variant={'label'}
              className="font-medium text-base-900"
            >
              Решение
            </Typography>
            <div className="flex flex-wrap items-center justify-start gap-2">
              {mainInfo.status.map((stat, index) => {
                const isLast = index + 1 === mainInfo.status.length

                return (
                  <span key={index}>
                    <CourtBaseItemStatus status={stat} />
                    {!isLast && (
                      <Typography
                        variant={'label'}
                        className="pl-2 pt-px font-medium leading-[2px] text-base-300"
                      >
                        |
                      </Typography>
                    )}
                  </span>
                )
              })}
            </div>

            {courtByIdData.map((court, index) => {
              const isLast = index + 1 === courtByIdData.length
              return (
                <React.Fragment key={court.name}>
                  <Typography
                    variant={'label'}
                    className="align-center flex translate-y-0.5 font-medium text-base-900"
                  >
                    {court.name}:
                  </Typography>
                  <span
                    className={cn(
                      isLast ? '' : 'pb-2',
                      !isLast ? 'border-b-[1px]' : ''
                    )}
                  >
                    {court.data.map((el, index) => {
                      const isFirst = index === 0

                      return (
                        <Typography
                          variant={'label'}
                          className="font-medium text-base-900"
                          key={index}
                        >
                          {isFirst ? null : '• '}
                          {el}
                        </Typography>
                      )
                    })}
                  </span>
                </React.Fragment>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}
