import IconPrint from '@/shared/assets/icons/icon_print.svg?react'
import { IconButton } from '@/shared/ui'

const PrintAssignedDocsButton = () => {
  return (
    <IconButton className="rounded-[8px] border border-base-100 p-[5px]">
      <IconPrint />
    </IconButton>
  )
}

export { PrintAssignedDocsButton }
