import { useGetFavoritesQuery } from '@/shared/api/documents'
import { useCallback, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'

type Props = {
  isMobile: boolean
}

export const useFavoriteDocuments = ({ isMobile = false }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const queryParams = Object.fromEntries(searchParams.entries())
  const { page } = queryParams
  const currentPage = page ? Number(page) : 1

  // Fetch favorite documents for the current page
  const { data, error, isFetching, isLoading } = useGetFavoritesQuery(
    {
      page: currentPage,
    }
  )

  // Infinite scrolling: track visibility of the last document to fetch the next page
  // const observer = useRef<IntersectionObserver | null>(null)
  // const lastFavoriteRef = useCallback(
  //   (node: HTMLDivElement | null) => {
  //     if (!isMobile || isFetching) return
  //     if (observer.current) observer.current.disconnect()
  //     observer.current = new IntersectionObserver((entries) => {
  //       if (entries[0].isIntersecting && data?.meta?.next_page) {
  //         // Update the `page` search param to trigger the next fetch
  //         setSearchParams({
  //           ...queryParams,
  //           page: String(data.meta.next_page),
  //         })
  //       }
  //     })
  //     if (node) observer.current.observe(node)
  //   },
  //   [
  //     isMobile,
  //     isFetching,
  //     data?.meta?.next_page,
  //     queryParams,
  //     setSearchParams,
  //   ]
  // )

  return {
    data: data,
    error,
    currentPage,
    // lastFavoriteRef,
    isLoading,
    isFetching,
  }
}
