import { cn } from '@/shared/utils/common'
import { cva, VariantProps } from 'class-variance-authority'
import React from 'react'

const spinnerVariants = cva('flex-col items-center justify-center', {
  variants: {
    show: {
      true: 'flex',
      false: 'hidden',
    },
  },
  defaultVariants: {
    show: true,
  },
})

const loaderVariants = cva('animate-spin text-primary', {
  variants: {
    size: {
      small: 'size-6',
      medium: 'size-8',
      large: 'size-12',
    },
  },
  defaultVariants: {
    size: 'medium',
  },
})

interface SpinnerContentProps
  extends VariantProps<typeof spinnerVariants>,
    VariantProps<typeof loaderVariants> {
  className?: string
  children?: React.ReactNode
}

export function Spinner({
  size,
  show,
  children,
  className,
}: SpinnerContentProps) {
  return (
    <span className={spinnerVariants({ show })}>
      <svg
        className={cn(loaderVariants({ size }), className)}
        xmlns="http://www.w3.org/2000/svg"
        width="1em"
        height="1em"
        viewBox="0 0 24 24"
      >
        <g
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="0.9"
        >
          <path d="M2 12c0-2.8 2.2-5 5-5s5 2.2 5 5s2.2 5 5 5s5-2.2 5-5" />
          <path d="M7 20.7a1 1 0 1 1 5-8.7a1 1 0 1 0 5-8.6" />
          <path d="M7 3.3a1 1 0 1 1 5 8.6a1 1 0 1 0 5 8.6" />
          <circle cx="12" cy="12" r="10" />
        </g>
      </svg>

      {children}
    </span>
  )
}
