import {
  DocumentsSearchInput,
  MobileDocumentsSearchInput,
} from '@/features/filters/DocumentsSearchInput'
import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { cn } from '@/shared/utils/common'
import { DocumentFiltersMobile } from '@/widgets/DocumentsBase/DocumentFiltersMobile'
import { WidgetCategories } from '@/widgets/WidgetCategories'
import { WidgetDocumentFilters } from '@/widgets/WidgetDocumentFilters'
import { Outlet } from 'react-router-dom'

export const DocumentsLayout = () => {
  const isMobile = useMediaQuery('(max-width: 960px)')

  if (isMobile)
    return (
      <div className="relative flex flex-1 flex-col pt-[46px]">
        <div
          className={cn(
            'flex h-full flex-col items-stretch justify-start overflow-y-auto px-5'
          )}
        >
          <div className="mb-5 flex justify-center self-stretch">
            <MobileDocumentsSearchInput />
          </div>
          <DocumentFiltersMobile />
          {/* Divider */}
          <div className="-mx-5 mb-2 h-2 shrink grow-0 bg-[#F0F0F0]" />
          {/* Body container */}
          <Outlet context={{ isMobile: isMobile }} />
        </div>
      </div>
    )

  return (
    <div className="relative flex flex-1 flex-col px-8 pt-24">
      <div className="relative flex w-full overflow-visible bg-transparent">
        <WidgetCategories pageTitle="document" />

        <div
          className={cn(
            `menu_boundary relative flex h-full flex-1 flex-col overflow-auto
            rounded-t-3xl bg-transparent px-4`
          )}
        >
          <div className="sticky top-0 z-50 -mx-2 min-h-4 min-w-full bg-[#FCFCFC]"></div>
          <div
            className="sticky top-0 z-50 -mt-4 rounded-t-3xl border-x-[1px] border-t-[1px]
              bg-white pt-1"
          >
            <DocumentsSearchInput />
            <WidgetDocumentFilters />
          </div>

          <Outlet context={{ isMobile: isMobile }} />
        </div>
      </div>
    </div>
  )
}
