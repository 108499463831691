//src/entities/document/types/index.ts
export const enum ECourtBaseStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  EXPIRED = 'EXPIRED',
  ARTICLE = 'ARTICLE',
}

export const enum ECourtBaseParagraphType {
  HEADING = 'HEADING',
  COMMENT = 'COMMENT',
  TEXT = 'TEXT',
}

export interface ICourtItem {
  id: string
  number: number
  date: string
  plaintiffs: string[]
  defendants: string[]
  court: string
  judges: string[]
  additional_info: string[]
  requirements: string
  status: ECourtBaseStatus[]
  is_favorite: boolean
}

export interface AssignedDoc {
  date: string
  id: string
  additional_info: string
  assignedDocInfo: string
}
export interface ICourtItemById {
  data: ICourtItem
  assignedDocs: AssignedDoc[]
}
