import IconSearch from '@/shared/assets/icons/icon_search_dark.svg?react'

interface SearchAssignedIconMobileProps {
  toggleOpenSearch: () => void
}

export const SearchAssignedIconMobile = ({
  toggleOpenSearch,
}: SearchAssignedIconMobileProps) => {
  return (
    <div
      className="rounded-[8px] border border-base-100 p-[5px]"
      onClick={toggleOpenSearch}
    >
      <IconSearch />
    </div>
  )
}
