// ChatByIdPage.tsx
import { useFavoriteMessages } from '@/entities/chat/models/useFavoriteMessages'
import { ChatContainerSkeleton } from '@/entities/chat/ui/ChatContainerSkeleton'
import { ChatPanelButton } from '@/entities/chat/ui/ChatPanelButton'
import { FavoriteChatContainer } from '@/entities/chat/ui/FavoriteChatContainer'
import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { useTypedSelector } from '@/shared/store'
import { useSidebar } from '@/widgets/Sidebar'

export const ChatFavoritesPage = () => {
  const isMobile = useMediaQuery('(max-width: 959px)')

  const { messages, isLoading } = useFavoriteMessages()

  const { documents, isChatOpen } = useTypedSelector(
    (state) => state.documentPreview
  )
  const { isSidebarOpen } = useSidebar()

  if (isLoading) {
    return (
      <ChatContainerSkeleton
        isSidebarOpen={isSidebarOpen}
        isMobile={isMobile}
      />
    )
  }

  if (!messages) {
    return <div>У вас не избранных сообщений</div>
  }

  return (
    <>
      {isMobile ? (
        <FavoriteChatContainer messages={messages ?? []} />
      ) : (
        // <MobileChatContainer messages={messages ?? []} /> //TODO: отдельно рендерить мобилку для избранных
        <FavoriteChatContainer messages={messages ?? []} />
      )}
      {/* <DocumentPreviewContainer /> TODO: Обдумать обработку превью контейнера на странице избранных*/}
      {documents.length !== 0 && !isChatOpen && <ChatPanelButton />}
    </>
  )
}
