import { ISearchMode } from '@/entities/document'
import IconAiFilled from '@/shared/assets/icons/icon_ai_filled.svg?react'
import IconLettersDark from '@/shared/assets/icons/icon_letters_dark.svg?react'
import { Typography } from '@/shared/ui'
import { ReactNode } from 'react'

type SearhModeData = {
  name: string
  icon: ReactNode
  badge?: ReactNode
}

export const searchModeConfig: Record<ISearchMode, SearhModeData> = {
  IN_TITLE: {
    name: 'По названию документа',
    icon: <IconLettersDark />,
  },
  CONTEXT: { name: 'Ai-поиск', icon: <IconAiFilled /> },
  CONTEXT_PLUS: {
    name: 'Ai-поиск',
    icon: (
      <IconAiFilled className="[&_path]:fill-additional-blue-2" />
    ),
    badge: (
      <Typography
        className="rounded-[4px] bg-additional-blue-1 px-2 py-[2px]
          text-additional-blue-2"
      >
        Plus
      </Typography>
    ),
  },
}

export const mockSearchModeData: Record<ISearchMode, string> = {
  IN_TITLE: 'Поиск по названию',
  CONTEXT: 'Поиск по контексту',
  CONTEXT_PLUS: 'Ai-поиск',
}
