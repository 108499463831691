import { ToggleAssignedDocsButton } from './ui/ToggleAssignedDocsButton'

interface ToggleAssignedDocsProps {
  toggleAllAccordions: () => void
  isAnyOpen: boolean
}

const ToggleAssignedDocs = ({
  toggleAllAccordions,
  isAnyOpen,
}: ToggleAssignedDocsProps) => {
  return (
    <div onClick={toggleAllAccordions}>
      <ToggleAssignedDocsButton isAnyOpen={isAnyOpen} />
    </div>
  )
}

export { ToggleAssignedDocs }
