import IconChevronRight from '@/shared/assets/icons/icon_chevron_right.svg?react'
import { IconButton } from '@/shared/ui'
import { cn } from '@/shared/utils/common'

interface ToggleAssignedDocsButtonProps {
  isAnyOpen: boolean
}

const ToggleAssignedDocsButton = ({
  isAnyOpen,
}: ToggleAssignedDocsButtonProps) => {
  return (
    <IconButton
      className={cn(
        'rounded-[8px] border border-base-100 px-[7px] py-[5px]',
        isAnyOpen ? 'rotate-90' : ''
      )}
    >
      <IconChevronRight />
    </IconButton>
  )
}

export { ToggleAssignedDocsButton }
