import IconAiEmpty from '@/shared/assets/icons/icon_ai_empty.svg?react'
import IconAiOutlinedDark from '@/shared/assets/icons/icon_ai_filled_dark.svg?react'
import IconDocumentsDark from '@/shared/assets/icons/icon_documents_dark.svg?react'
import IconDocumentsFilled from '@/shared/assets/icons/icon_documents_filled.svg?react'
import IconWeightsDark from '@/shared/assets/icons/icon_weights_dark.svg?react'
import IconWeightsFilled from '@/shared/assets/icons/icon_weights_filled.svg?react'

import { EPlatformPage, SegmentedItem } from '../types'

export const initialSegmentedItems: SegmentedItem[] = [
  {
    value: EPlatformPage.Assistant,
    icon: (
      <IconAiEmpty
        width={20}
        height={20}
        className="max-h-[20px] max-w-[20px]"
      />
    ),
    iconActive: (
      <IconAiOutlinedDark
        width={20}
        height={20}
        className="max-h-[20px] max-w-[20px]"
      />
    ),
    label: 'Ai-ассистент',
    disabled: false,
  },
  {
    value: EPlatformPage.Documents,
    icon: (
      <IconDocumentsDark
        width={20}
        height={20}
        className="max-h-[20px] max-w-[20px]"
      />
    ),
    iconActive: (
      <IconDocumentsFilled
        width={20}
        height={20}
        className="max-h-[20px] max-w-[20px]"
      />
    ),
    label: 'База документов',
    disabled: false,
  },
  {
    value: EPlatformPage.CourtBase,
    icon: (
      <IconWeightsDark
        width={20}
        height={20}
        className="max-h-[20px] max-w-[20px]"
      />
    ),
    iconActive: (
      <IconWeightsFilled
        width={20}
        height={20}
        className="max-h-[20px] max-w-[20px]"
      />
    ),

    label: 'Судебная база',
    disabled: false,
  },
]
