import IconBookmarkDark from '@/shared/assets/icons/icon_bookmark_dark.svg?react'
import { IconButton } from '@/shared/ui'
import { cn } from '@/shared/utils/common'

import { useDocumentsToggleFavoritesPage } from '../../hooks/useDocumentsToggleFavoritesPage'

type DocumentsToggleFavoritesPageButtonProps = {
  isFavoriteRoute: boolean
}

export const DocumentsToggleFavoritesPageButton = ({
  isFavoriteRoute,
}: DocumentsToggleFavoritesPageButtonProps) => {
  const { handleBookmarkClick } = useDocumentsToggleFavoritesPage({
    isFavoriteRoute: isFavoriteRoute,
  })
  return (
    <IconButton
      className={cn(
        'flex h-10 w-10 items-center justify-center border-[1px]',
        isFavoriteRoute
          ? 'border-additional-blue-2'
          : 'border-stroke-light-gray-2'
      )}
      onClick={handleBookmarkClick}
    >
      <IconBookmarkDark
        className={cn(
          'min-h-6 min-w-6',
          isFavoriteRoute
            ? '[&_path]:fill-additional-blue-2 [&_path]:stroke-additional-blue-2'
            : ''
        )}
      />
    </IconButton>
  )
}
