import IconChevronRight from '@/shared/assets/icons/icon_dropdown_down.svg?react'
import { Typography } from '@/shared/ui/Typography'
import { forwardRef, ForwardRefRenderFunction } from 'react'

interface CustomInputProps {
  value?: string
  onClick?: () => void
  className?: string
}

const CustomInput: ForwardRefRenderFunction<
  HTMLButtonElement,
  CustomInputProps
> = ({ value, onClick, className, ...props }, ref) => (
  <div className="flex shrink grow-0 basis-[150px] items-center justify-start gap-2">
    <div
      className="flex h-[32px] items-center justify-start gap-1 self-stretch
        rounded-[26px] border bg-[#FBFBFB] px-3 py-2"
    >
      <Typography className="font-Inter whitespace-nowrap text-sm font-medium leading-[16px]">
        {value ?? 'Номер дела'}
      </Typography>
      <IconChevronRight />
    </div>
  </div>
)

export const CourtBaseFilterCustomButton = forwardRef(CustomInput)
