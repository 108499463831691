import IconArrowScroll from '@/shared/assets/icons/icon_arrow_scroll.svg?react'
import { useTypedSelector } from '@/shared/store'
import { ButtonScrollToAnchor } from '@/shared/ui/ButtonScrollToAnchor'
import { cn } from '@/shared/utils/common'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useChatMobile } from '../../models/useChatMobile'
import { IChatMessage } from '../../types'
import { ChatMessages } from '../ChatMessages'
import { MobileChatInput } from '../MobileChatInput'

type MobileChatContainerProps = {
  messages: IChatMessage[]
}

export const MobileChatContainer = ({
  messages,
}: MobileChatContainerProps) => {
  const { bottomRef, isBottomVisible } = useChatMobile({
    messages: messages,
  })
  const { chat_id } = useParams<{ chat_id: string }>()

  const isAwaitingResponse = useTypedSelector(
    (state) =>
      state.chats.chatsData[chat_id!]?.isAwaitingResponse ?? false
  )

  // Smooth scrolling function using requestAnimationFrame
  const smoothScrollTo = (
    element: HTMLElement,
    target: number,
    duration: number
  ) => {
    const start = element.scrollTop
    const change = target - start
    const startTime = performance.now()

    function animateScroll(currentTime: number) {
      const elapsed = currentTime - startTime
      const t = Math.min(elapsed / duration, 1) // progress: 0 to 1
      element.scrollTop = start + change * t
      if (t < 1) {
        requestAnimationFrame(animateScroll)
      }
    }

    requestAnimationFrame(animateScroll)
  }

  // After initial mount or after messages update, scroll to bottom of #temp container
  useEffect(() => {
    const temp = document.getElementById('temp') as HTMLElement | null
    if (temp) {
      requestAnimationFrame(() => {
        smoothScrollTo(temp, temp.scrollHeight, 300) // adjust duration as needed
      })
    }
  }, [messages, isAwaitingResponse])

  if (!messages) return null

  return (
    <>
      {/* Make sure this container has the id="temp" so we can target it */}
      <div
        id="temp"
        className={cn(
          'relative flex h-full flex-col items-stretch justify-start gap-8',
          'overflow-y-auto pb-[118px] pt-10 md:max-w-[728px]'
        )}
      >
        <ChatMessages messages={messages} />
        <div ref={bottomRef} />
        {!isBottomVisible && (
          <ButtonScrollToAnchor
            className="fixed bottom-[143px] left-1/2 -translate-x-1/2 rounded-full border
              border-base-200 bg-white p-1"
            style={{
              boxShadow:
                '0px 18px 5px 0px rgba(0, 0, 0, 0.00), 0px 12px 5px 0px rgba(0, 0, 0, 0.00), 0px 7px 4px 0px rgba(0, 0, 0, 0.02), 0px 3px 3px 0px rgba(0, 0, 0, 0.03), 0px 1px 2px 0px rgba(0, 0, 0, 0.03)',
            }}
            targetRef={bottomRef}
          >
            <IconArrowScroll />
          </ButtonScrollToAnchor>
        )}
      </div>
      <MobileChatInput />
    </>
  )
}
