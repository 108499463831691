import {
  documentsApi,
  useGetDocumentsQuery,
} from '@/shared/api/documents'
import { RootState } from '@/shared/store'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

import { DocumentQueryParams, ISearchMode } from '../types'

type Props = {
  isMobile: boolean
}

//TODO: This entire hook will be changed after requirements will be discussed

export const useDocuments = ({ isMobile = false }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const queryParams = Object.fromEntries(searchParams.entries())
  const { search_mode, search_query, page } = queryParams
  const [isLastItemVisible, setIsLastItemVisible] =
    useState<boolean>(false)

  // Adjust query parameters based on search mode and query
  const queryOptions: Partial<DocumentQueryParams> = {
    ...queryParams,
  }
  if (search_mode && search_query) {
    queryOptions.search_mode = search_mode as ISearchMode
    queryOptions.search_query = search_query
  } else if (search_mode && !search_query) {
    delete queryOptions.search_mode
    delete queryOptions.search_query
  } else if (!search_mode && search_query) {
    queryOptions.search_mode = 'IN_TITLE' as ISearchMode
    queryOptions.search_query = search_query
  }

  const cachedData = useSelector((state: RootState) => {
    return documentsApi.endpoints.getDocuments.select({
      isMobile: false,
    })(state).data
  })

  const currentPage = page ? Number(page) : 1
  const cachedCurrentPage = cachedData?.meta?.current_page ?? 1

  // Determine whether to skip the fetch
  const skipQuery = useCallback((): boolean => {
    if (!isMobile) return false // Always fetch for non-mobile
    if (!cachedData?.documents) return false // Fetch if no cached data
    if (!isLastItemVisible) return true // Skip if last item is not visible
    return !cachedData.meta.next_page // Skip if no next page
  }, [isMobile, cachedData, isLastItemVisible])

  // Fetch documents for the current page
  const { data, error, isFetching, isLoading } = useGetDocumentsQuery(
    {
      ...queryOptions,
      page: isMobile
        ? (cachedData?.meta.next_page ?? 1)
        : currentPage,
      isMobile,
    },
    { skip: skipQuery() }
  )
  // Infinite scrolling: track visibility of the last document to fetch the next page
  const observer = useRef<IntersectionObserver | null>(null)

  const lastDocumentRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (!isMobile || isFetching) return

      if (observer.current) observer.current.disconnect()

      observer.current = new IntersectionObserver(
        (entries) => {
          const isAnyIntersecting = entries.some(
            (entry) => entry.isIntersecting
          )
          if (isAnyIntersecting && !isFetching && !isLoading) {
            setIsLastItemVisible(true) // Element is visible
          } else {
            setIsLastItemVisible(false) // Element is not visible
          }
        },
        {
          threshold: 1.0, // Trigger only when fully visible
        }
      )

      if (node) observer.current.observe(node)
    },
    [isMobile, isFetching, isLoading] // Removed 'cachedData' and 'data' from dependencies
  )

  // Reset `isLastItemVisible` when new data is fetched
  useEffect(() => {
    if (isFetching) {
      // Reset visibility when a fetch starts to prevent immediate re-fetch
      setIsLastItemVisible(false)
    } else if (
      data?.documents &&
      data.documents.length > (cachedData?.documents.length || 0)
    ) {
      // Optionally, reset when new documents are added
      setIsLastItemVisible(false)
    }
  }, [isFetching, data, cachedData])

  // Clean up the observer on unmount
  useEffect(() => {
    return () => {
      if (observer.current) {
        observer.current.disconnect()
      }
    }
  }, [])

  return {
    data: cachedData || data,
    error,
    currentPage,
    lastDocumentRef,
    isLoading,
    isFetching,
  }
}
