// FiltersSearchInput Component
import { SearchModeFilterMenuDesktop } from '@/features/filters/SearchModeFilter'
import IconSearch from '@/shared/assets/icons/icon_search_dark.svg?react'
import { IconButton } from '@/shared/ui'
import { cn } from '@/shared/utils/common'
import React, { ForwardedRef, InputHTMLAttributes } from 'react'

import { useDocumentsSearchInput } from '../../models/useDocumentsSearchInput'

interface DocumentsSearchInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  inputType?: string
  onValueChange?: (value: string) => void
  innerRef?: ForwardedRef<HTMLInputElement>
}

export const DocumentsSearchInput: React.FC<
  DocumentsSearchInputProps
> = ({
  inputType = 'text',
  onValueChange,
  innerRef,
  className,
  ...props
}) => {
  const { value, handleChange, handleKeyDown, handleSubmit } =
    useDocumentsSearchInput(onValueChange)

  return (
    <div className="relative flex w-full items-start gap-2 rounded-t-3xl px-1">
      <div
        className="absolute left-2 top-1 flex min-w-[216px] flex-col items-start
          gap-[3px]"
      >
        <SearchModeFilterMenuDesktop />
      </div>
      <input
        type={inputType}
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        ref={innerRef}
        placeholder="Поиск по названию документа..."
        className={cn(
          `border-input placeholder:font-inter flex h-12 w-full rounded-[20px]
          border bg-[#F1F2F4] pl-[264px] pr-6 text-sm shadow-sm
          transition-colors file:border-0 file:bg-transparent file:text-sm
          file:font-medium placeholder:text-sm placeholder:font-medium
          placeholder:leading-5 placeholder:text-[#67707E]
          focus-visible:outline-none focus-visible:ring-1
          focus-visible:ring-base-900 disabled:cursor-not-allowed
          disabled:opacity-50`,
          {
            'ring-1 ring-base-900': value,
          },
          className
        )}
        {...props}
      />
      <IconButton
        onClick={handleSubmit}
        className="absolute right-2 h-10 w-10 translate-y-1 rounded-[16px] border-[1px]
          border-[#2E32380A] bg-base-20"
        style={{
          boxShadow:
            '0px 3px 8px 0px rgba(24, 35, 54, 0.08), 0px 3px 1px 0px rgba(24, 35, 54, 0.02)',
        }}
      >
        <IconSearch className="h-5 w-5" />
      </IconButton>
    </div>
  )
}
