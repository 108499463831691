import IconArrowThinkUp from '@/shared/assets/icons/icon_arrow_thick_up.svg?react'
import IconMoreDotsDark from '@/shared/assets/icons/icon_more_dots_dark.svg?react'
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarTrigger,
} from '@/shared/ui/Menubar'
import { Typography } from '@/shared/ui/Typography'
import { cn } from '@/shared/utils/common'
import { useBoundary } from '@/shared/utils/useBoundary'
import { ReactNode } from 'react'

import { MenuItem } from '../types'

interface BaseDocumentMenuProps {
  children?: ReactNode
  menuItems: MenuItem[]
}

export const BaseDocumentMenu = ({
  children,
  menuItems,
}: BaseDocumentMenuProps) => {
  const boundaryElements = useBoundary('menu_boundary')

  return (
    <>
      <Menubar className="flex items-start justify-stretch self-stretch border-none">
        <MenubarMenu>
          <MenubarTrigger
            onClick={(e) => e.stopPropagation()}
            className={cn(
              `absolute right-3 z-30 flex h-6 w-6 items-center justify-start
              rounded-lg bg-[#FCFDFD] hover:cursor-pointer hover:bg-[#F0F0F0]`
            )}
          >
            {children ? children : <IconMoreDotsDark />}
          </MenubarTrigger>

          <MenubarContent
            className="relative z-[5000] flex flex-col items-stretch rounded-[20px] bg-white
              p-2"
            collisionBoundary={boundaryElements}
            collisionPadding={{ top: 120 }}
            align="start"
            style={{
              boxShadow:
                '0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 9px 28px 8px rgba(0, 0, 0, 0.05)',
            }}
            alignOffset={-150}
            sideOffset={10} // Adjust this if necessary to position the content correctly
          >
            {/* Arrow Icon positioned at right-[20px] */}
            <IconArrowThinkUp className="absolute -top-[14px] right-[20px] h-5 w-5" />

            {/* Menu Items */}

            {menuItems.map((menu, index) => {
              const handleClickMenuItem = (
                e: React.MouseEvent<HTMLDivElement, MouseEvent>
              ) => {
                e.stopPropagation()
                if (menu.onClick) {
                  menu.onClick(e)
                }
              }

              return (
                <MenubarItem
                  inset={true}
                  className="p-0"
                  key={index}
                  onClick={handleClickMenuItem}
                >
                  <div
                    className={cn(
                      `flex w-full items-center gap-3 rounded-lg p-2 hover:cursor-pointer
                      hover:bg-slate-100`,
                      index + 1 === menuItems.length
                        ? 'rounded-t-none border-t-[1px] border-base-100'
                        : '',
                      menu?.className
                    )}
                  >
                    {menu.icon}
                    <Typography
                      className="text-nowrap font-normal"
                      variant="label"
                    >
                      {menu.title}
                    </Typography>
                  </div>
                </MenubarItem>
              )
            })}
          </MenubarContent>
        </MenubarMenu>
      </Menubar>
    </>
  )
}
