import { DocumentInfoDialog } from '@/entities/panel/ui/DocumentInfoDialog'
import { Typography } from '@/shared/ui/Typography'
import { DocumentsBaseActionsDrawer } from '@/widgets/DocumentsBase/DocumentsBaseActionsDrawer'
import { forwardRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { IDocument } from '../../types'
import { DocumentItemStatus } from '../DocumentItemStatus'

interface DocumentItemMobileProps {
  documentItem: IDocument
  isIndexed?: boolean
  isLastDocumentItem: boolean
}

// Define the main DocumentItemMobile component
const DocumentItemMobile = forwardRef<
  HTMLDivElement,
  DocumentItemMobileProps
>(({ documentItem, isLastDocumentItem, isIndexed = true }, ref) => {
  const navigate = useNavigate()
  const [isInfoDialogOpen, setIsInfoDialogOpen] = useState(false)
  const handleInfoClose = () => setIsInfoDialogOpen(false)

  return (
    <>
      <div
        ref={ref}
        className="flex flex-col items-stretch justify-start rounded-[16px] border-[1px]
          border-base-200 bg-white shadow-shadow3 sm:basis-[208px]
          lg:basis-[120px] xl:basis-[96px]"
      >
        <div
          className="relative flex items-center justify-between self-stretch border-b px-3
            py-2"
        >
          <div className="flex items-center gap-4 overflow-hidden">
            <Typography variant={'body'} className="font-medium">
              {documentItem.id}.
            </Typography>
            {documentItem.status && (
              <div className="flex flex-wrap items-center justify-start gap-[6px]">
                <DocumentItemStatus
                  status={documentItem.status.code}
                  label={documentItem.status.name}
                />
              </div>
            )}
          </div>
          <DocumentsBaseActionsDrawer documentId={documentItem.id} />
        </div>
        <div
          className="flex grow flex-col items-stretch justify-start gap-3 self-stretch
            rounded-2xl bg-white px-3 py-4"
          onClick={() => navigate(`/documents/${documentItem.id}`)}
        >
          <Typography className="line-clamp-[7] text-sm font-medium leading-5">
            {documentItem.name}
          </Typography>
          <div className="-mt-[2px] flex flex-col items-stretch justify-start">
            {documentItem.requisites && (
              <div className="flex items-center justify-start gap-3">
                <Typography
                  variant={'label'}
                  className="font-medium text-[#5E626A]"
                >
                  <span>
                    <Typography
                      className="text-base-600"
                      variant={'label'}
                    >
                      •{' '}
                    </Typography>
                  </span>
                  {documentItem.requisites}
                </Typography>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
})

DocumentItemMobile.displayName = 'DocumentItemMobile'

export { DocumentItemMobile }
