import IconSearch from '@/shared/assets/icons/icon_search_dark.svg?react'
import { Input } from '@/shared/ui'
import { motion } from 'framer-motion'

interface SearchAssignedDocsInputMobileProps {
  isOpen: boolean
  onSearchChange: (query: string) => void
}

export const SearchAssignedDocsInputMobile = ({
  isOpen,
  onSearchChange,
}: SearchAssignedDocsInputMobileProps) => {
  return (
    <motion.div
      initial={{ height: 0, opacity: 0 }}
      animate={
        isOpen
          ? { height: 'auto', opacity: 1 }
          : { height: 0, opacity: 0 }
      }
      transition={{ duration: 0.35, ease: 'easeInOut' }}
      className="w-full overflow-hidden"
    >
      <Input
        className="min-h-[48px] rounded-[20px] bg-base-100 pl-4 text-[14px]"
        placeholder="Поиск внутри документов..."
        onChange={(e) => onSearchChange(e.target.value)}
        rightIcon={
          <div
            className="absolute -right-1.5 top-1/2 -translate-y-1/2 transform rounded-[16px]
              bg-white p-2.5"
          >
            <IconSearch />
          </div>
        }
      />
    </motion.div>
  )
}
