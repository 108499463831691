// DocumentContentsContext.tsx
import { createContext, ReactNode, useContext, useState } from 'react'

interface DocumentContentsContextProps {
  isOpen: boolean
  toggleIsOpen: () => void
}

const initialValue: DocumentContentsContextProps = {
  isOpen: false,
  toggleIsOpen: () => {},
}

export const DocumentContentsContext =
  createContext<DocumentContentsContextProps>(initialValue)

export const DocumentContentsProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [isOpen, setIsOpen] = useState(initialValue.isOpen)

  const toggleIsOpen = () => {
    setIsOpen(!isOpen)
  }

  return (
    <DocumentContentsContext.Provider
      value={{ isOpen, toggleIsOpen }}
    >
      {children}
    </DocumentContentsContext.Provider>
  )
}
