import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { IconButton } from '@/shared/ui'
import { TooltipProvider } from '@/shared/ui/Tooltip'
import { cn } from '@/shared/utils/common'
import { BottomNavigationBar } from '@/widgets/BottomNavigationBar'
import {
  MobilePlatformHeader,
  PlatformHeader,
} from '@/widgets/Headers'
import {
  SidebarProvider,
  useSidebar,
} from '@/widgets/Sidebar/model/useSidebar'
import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

export const PlatformLayoutContent = () => {
  const isMobile = useMediaQuery('(max-width: 480px)')
  const { isSidebarOpen, closeSidebar, toggleSidebar } = useSidebar()

  useEffect(() => {
    closeSidebar()
  }, [closeSidebar])

  if (isMobile)
    return (
      <div
        id="temp"
        className="min-w-screen relative flex h-[100svh] flex-col items-stretch
          justify-start overflow-y-auto"
      >
        {/* <MobilePlatformHeader /> */}
        <div className="relative flex grow flex-col items-stretch justify-start pb-[71px]">
          <Outlet />
        </div>
        <BottomNavigationBar />
      </div>
    )

  return (
    <div className="m-0 flex min-h-[100svh] flex-col">
      <PlatformHeader />
      <div className="h-full flex-1">
        <Outlet />
      </div>
    </div>
  )
}

export const PlatformLayout = () => {
  return (
    <SidebarProvider>
      <TooltipProvider>
        <PlatformLayoutContent />
      </TooltipProvider>
    </SidebarProvider>
  )
}
