import { useGetUserQuery } from '@/shared/api/user'
import { Spinner } from '@/shared/ui'
import React, { useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

interface ProtectedRouteProps {
  children: React.ReactNode
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
}) => {
  const location = useLocation()

  const { data: userData, error, isLoading } = useGetUserQuery()

  if (isLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Spinner size="large" />
      </div>
    )
  }

  if (error || !userData || !userData.success) {
    return <Navigate to="/signin" state={{ from: location }} />
  }

  return <>{children}</>
}

export default ProtectedRoute
