import { useDocumentContents } from '@/features/document/hooks/useDocumentContent'
import IconBookmarkBlack from '@/shared/assets/icons/icon_bookmark_back.svg?react'
import IconBurger from '@/shared/assets/icons/icon_burger_gray.svg?react'
import IconCommentsDark from '@/shared/assets/icons/icon_comments_dark.svg?react'
import IconContentDark from '@/shared/assets/icons/icon_content_dark.svg?react'
import IconDownloadDark from '@/shared/assets/icons/icon_download_dark.svg?react'
import IconEyeDarkSmall from '@/shared/assets/icons/icon_eye_small_dark.svg?react'
import IconInfoItallicDark from '@/shared/assets/icons/icon_info_itallic_dark.svg?react'
import IconMagnifyDark from '@/shared/assets/icons/icon_magnify_dark.svg?react'
import IconGlobeMedium from '@/shared/assets/icons/icon_planet_dark_medium.svg?react'
import IconRowDark from '@/shared/assets/icons/icon_row_dark.svg?react'
import IconSmallcapsDarkMedium from '@/shared/assets/icons/icon_smallcaps_dark_medium.svg?react'
import { IconButton } from '@/shared/ui'
import { motion } from 'framer-motion'
import { useState } from 'react'

export const WidgetDocumentMenuActions = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleOpen = (e: any) => {
    e.stopPropagation()
    setIsOpen(!isOpen)
  }
  const {
    isOpen: isDocumentContentsOpen,
    toggleIsOpen: toggleIsDocumentContentsOpen,
  } = useDocumentContents()

  return (
    <div className="flex w-14 flex-col items-center justify-start gap-3">
      <IconButton
        className="h-10 w-10 rounded-full border-[1px] border-base-100 px-[10px]
          py-[10px]"
        style={{
          boxShadow:
            '0px 6px 2px 0px rgba(0, 0, 0, 0.00), 0px 4px 1px 0px rgba(0, 0, 0, 0.00), 0px 2px 1px 0px rgba(0, 0, 0, 0.02), 0px 1px 1px 0px rgba(0, 0, 0, 0.03)',
        }}
        onClick={(e) => toggleOpen(e)}
        aria-expanded={isOpen}
      >
        <IconBurger className="h-5 w-5" />
      </IconButton>

      <motion.div
        className="flex flex-col items-center justify-start gap-4 overflow-hidden
          rounded-[16px] border-[1px] border-base-100 p-2"
        style={{
          boxShadow: '0px 8px 32px 0px rgba(0, 0, 0, 0.04)',
        }}
        initial={{ opacity: 0, height: 0 }}
        animate={
          isOpen
            ? { opacity: 1, height: 'auto' }
            : { opacity: 0, height: 0 }
        }
        transition={{
          height: { duration: 0.3, ease: 'easeInOut' },
          opacity: { duration: 0.2 },
        }}
      >
        <IconButton className="h-10 w-10 px-[8px] py-[8px]">
          <IconMagnifyDark className="h-6 w-6" />
        </IconButton>
        <IconButton
          className="h-10 w-10 px-[8px] py-[8px]"
          onClick={toggleIsDocumentContentsOpen}
        >
          <IconContentDark className="h-6 w-6" />
        </IconButton>
        <IconButton className="relative h-10 w-10 px-[8px] py-[8px]">
          <IconCommentsDark className="h-6 w-6" />
          <IconEyeDarkSmall className="absolute left-[26px] top-[26px]" />
        </IconButton>
        <IconButton className="h-10 w-10 px-[8px] py-[8px]">
          <IconGlobeMedium className="h-6 w-6" />
        </IconButton>
        <IconButton className="h-10 w-10 px-[8px] py-[8px]">
          <IconSmallcapsDarkMedium className="h-6 w-6" />
        </IconButton>
        <IconButton className="h-10 w-10 px-[8px] py-[8px]">
          <IconRowDark className="h-6 w-6" />
        </IconButton>
        <IconButton className="h-10 w-10 px-[8px] py-[8px]">
          <IconInfoItallicDark className="h-6 w-6" />
        </IconButton>
        <IconButton className="h-10 w-10 px-[8px] py-[8px]">
          <IconBookmarkBlack className="h-6 w-6" />
        </IconButton>
        <IconButton className="h-10 w-10 px-[8px] py-[8px]">
          <IconDownloadDark className="h-6 w-6" />
        </IconButton>
      </motion.div>
    </div>
  )
}
