import { DocumentBaseBodyContainer } from '@/widgets/DocumentsBase/DocumentBaseBodyContainer'
import { DocumentBaseFavoritesBodyContainerMobile } from '@/widgets/DocumentsBase/DocumentBaseFavoritesBodyContainerMobile'
import { useOutletContext } from 'react-router-dom'

export const DocumentsFavoritePage = () => {
  const { isMobile } = useOutletContext<{
    isMobile: boolean
  }>()

  if (isMobile) {
    return <DocumentBaseFavoritesBodyContainerMobile />
  }

  return <DocumentBaseBodyContainer />
}
