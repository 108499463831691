import { ICourtItem } from '@/entities/courtBase'
import {
  CourtBaseByIdCaseMobile,
  CourtBaseHeaderMobile,
} from '@/features/courtBase'

interface CourtBaseByIdItemProps {
  mainInfo: ICourtItem
}

export const CourtBaseByIdItemMobile = ({
  mainInfo,
}: CourtBaseByIdItemProps) => {
  return (
    <div>
      <CourtBaseHeaderMobile id={mainInfo.id} />
      <CourtBaseByIdCaseMobile mainInfo={mainInfo} />
    </div>
  )
}
