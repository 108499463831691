import { Typography } from '@/shared/ui/Typography'
import { cn } from '@/shared/utils/common'
import React, { forwardRef } from 'react'
import { useNavigate } from 'react-router-dom'

import { ICourtItem } from '../../types'
import { CourtBaseItemStatus } from '../CourtBaseItemStatus'

interface CourtBaseItemMobileProps {
  courtBaseItem: ICourtItem
  isIndexed?: boolean
  isLastItem: boolean
  index: number
}

const CourtBaseItemMobile = forwardRef<
  HTMLDivElement,
  CourtBaseItemMobileProps
>(({ courtBaseItem, isLastItem, isIndexed = true, index }, ref) => {
  const navigate = useNavigate()

  const courtData = [
    { name: 'Истцы', data: courtBaseItem.plaintiffs },
    { name: 'Ответчики', data: courtBaseItem.defendants },
    { name: 'Суд', data: [courtBaseItem.court] },
    { name: 'Судьи', data: courtBaseItem.judges },
    { name: 'Требования', data: [courtBaseItem.requirements] },
  ]

  const handleNavigate = () => {
    navigate(`/court-base/${courtBaseItem.id}`)
  }

  return (
    <>
      <div
        className={cn(
          isLastItem ? '' : 'border-b-[1px]',
          `relative flex gap-5 rounded-[16px] border-[1px] border-base-100
          bg-white py-2`
        )}
        style={{
          boxShadow:
            '0px 6px 2px 0px rgba(0, 0, 0, 0.00), 0px 4px 1px 0px rgba(0, 0, 0, 0.00), 0px 2px 1px 0px rgba(0, 0, 0, 0.02), 0px 1px 1px 0px rgba(0, 0, 0, 0.03)',
        }}
      >
        {/* Header */}
        <div
          className="relative flex w-full flex-col items-start justify-start"
          ref={ref}
          onClick={handleNavigate}
        >
          <div className="flex w-full justify-start gap-1.5 px-3 pb-2">
            <Typography variant={'body'} className="font-medium">
              {index + 1}.
            </Typography>
            <Typography variant={'body'} className="pr-4 font-medium">
              {courtBaseItem.date ? `От ${courtBaseItem.date}` : null}
            </Typography>
          </div>

          <div className="w-full cursor-pointer border-t-[1px] border-base-100 px-3 pb-3 pt-4">
            {isIndexed && (
              <Typography variant={'body'} className="font-medium">
                {courtBaseItem.id
                  ? `Гражданское дело №${courtBaseItem.id}`
                  : null}
              </Typography>
            )}
          </div>
          {/* Body */}
          <div className="grid grid-cols-[1fr_auto] gap-x-2.5 gap-y-1.5 px-3">
            <Typography
              variant={'label'}
              className="font-medium text-base-900"
            >
              Решение
            </Typography>
            <div className="flex flex-wrap items-center justify-start gap-2">
              {courtBaseItem.status.map((stat, index) => {
                const isLast =
                  index + 1 === courtBaseItem.status.length

                return (
                  <span key={index}>
                    <CourtBaseItemStatus status={stat} />
                    {!isLast && (
                      <Typography
                        variant={'label'}
                        className="pl-2 pt-px font-medium leading-[2px] text-base-300"
                      >
                        |
                      </Typography>
                    )}
                  </span>
                )
              })}
            </div>

            {courtData.map((court, index) => {
              const isLast = index + 1 === courtData.length
              return (
                <React.Fragment key={court.name}>
                  <Typography
                    variant={'label'}
                    className="align-center flex translate-y-0.5 font-medium text-base-900"
                  >
                    {court.name}:
                  </Typography>
                  <span
                    className={cn(
                      isLast ? '' : 'pb-2',
                      'border-b-[1px]'
                    )}
                  >
                    {court.data.map((el, index) => {
                      const isFirst = index === 0

                      return (
                        <Typography
                          variant={'label'}
                          className="font-medium text-base-900"
                          key={index}
                        >
                          {isFirst ? null : '• '}
                          {el}
                        </Typography>
                      )
                    })}
                  </span>
                </React.Fragment>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
})

CourtBaseItemMobile.displayName = 'CourtBaseItemMobile'

export { CourtBaseItemMobile }
