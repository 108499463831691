import {
  isRejectedWithValue,
  Middleware,
  MiddlewareAPI,
  SerializedError,
} from '@reduxjs/toolkit'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query'

interface ErrorResponseData {
  success: boolean
  code: number
  message: string
}

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      const error = action.payload as
        | FetchBaseQueryError
        | SerializedError

      if ('status' in error) {
        const errorData = error.data as ErrorResponseData
        const errorMessage: string =
          errorData?.message ||
          'Перезагрузите страницу, чтобы все заработало. Если ошибка повторяется, обратитесь в поддержку.'

        console.error('API Error:', {
          status: error.status,
          message: errorMessage,
        })
      }
      // Handle SerializedError (usually unexpected errors)
      else if ('message' in error) {
        console.error('Unexpected Error:', error.message)
      } else {
        console.error('Unknown Error occurred')
      }
    }

    return next(action)
  }
