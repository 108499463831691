import { Typography } from '@/shared/ui'
import React from 'react'

import { ECourtBaseStatus } from '../../types'

type DocumentStatusBadge = {
  label: string
  color: string
  textColor: string
}

const documentStatusBadgeVariants: Record<
  ECourtBaseStatus,
  DocumentStatusBadge
> = {
  [ECourtBaseStatus.ACTIVE]: {
    label: 'Удовлетворить',
    color: '#EDF3EC',
    textColor: '#4A6F47',
  },
  [ECourtBaseStatus.ARTICLE]: {
    label: 'В процессе',
    color: '#EDEEF1',
    textColor: '#67707E',
  },
  [ECourtBaseStatus.PENDING]: {
    label: 'Удовлетворить частично',
    color: '#FCECD6',
    textColor: '#845F34',
  },
  [ECourtBaseStatus.EXPIRED]: {
    label: 'Отказать',
    color: '#FDEBEC',
    textColor: '#8F5752',
  },
}
export const CourtBaseItemStatus = ({
  status,
}: {
  status: ECourtBaseStatus
}) => {
  const { label, color, textColor } =
    documentStatusBadgeVariants[status]

  return (
    <Typography
      variant={'subtitle'}
      className="rounded-[4px] px-2 py-[2px] font-semibold"
      style={{
        backgroundColor: color,
        color: textColor,
      }}
    >
      {label}
    </Typography>
  )
}
