import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ChatState {
  isAwaitingResponse: boolean
  error: string
}

interface ChatAwaitingResponseState {
  // A record mapping chat GUIDs to a ChatState
  chatsData: Record<string, ChatState>
}

const initialState: ChatAwaitingResponseState = {
  chatsData: {},
}

const chatSlice = createSlice({
  name: 'chatAwaitingResponse',
  initialState,
  reducers: {
    setChatAwaitingResponse: (
      state,
      action: PayloadAction<{ chatId: string }>
    ) => {
      const { chatId } = action.payload
      if (!state.chatsData[chatId]) {
        state.chatsData[chatId] = {
          isAwaitingResponse: true,
          error: '',
        }
      } else {
        state.chatsData[chatId].isAwaitingResponse = true
      }
    },
    clearChatAwaitingResponse: (
      state,
      action: PayloadAction<{ chatId: string }>
    ) => {
      const { chatId } = action.payload
      if (!state.chatsData[chatId]) {
        state.chatsData[chatId] = {
          isAwaitingResponse: false,
          error: '',
        }
      } else {
        state.chatsData[chatId].isAwaitingResponse = false
      }
    },
    setChatError: (
      state,
      action: PayloadAction<{ chatId: string; error: string }>
    ) => {
      const { chatId, error } = action.payload
      if (!state.chatsData[chatId]) {
        state.chatsData[chatId] = {
          isAwaitingResponse: false,
          error,
        }
      } else {
        state.chatsData[chatId].error = error
      }
    },
    clearChatError: (
      state,
      action: PayloadAction<{ chatId: string }>
    ) => {
      const { chatId } = action.payload
      if (state.chatsData[chatId]) {
        state.chatsData[chatId].error = ''
      }
    },
  },
})

export const {
  setChatAwaitingResponse,
  clearChatAwaitingResponse,
  setChatError,
  clearChatError,
} = chatSlice.actions
export default chatSlice.reducer
