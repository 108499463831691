import { IChatAiFile } from '@/entities/chat/types'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface DocumentPreviewState {
  documents: IChatAiFile[]
  isChatOpen: boolean
  isOpenDocumentsPanel: boolean
  hash: string | null
  isChangedFirstPanel: boolean
  isChangedSecondPanel: boolean
  isChangedThreePanels: boolean
}

const initialState: DocumentPreviewState = {
  documents: [],
  isChatOpen: false,
  isOpenDocumentsPanel: false,
  hash: null,
  isChangedFirstPanel: false,
  isChangedSecondPanel: false,
  isChangedThreePanels: false,
}

export const documentPreviewSlice = createSlice({
  name: 'documentPreview',
  initialState,
  reducers: {
    addDocument: (state, action: PayloadAction<IChatAiFile>) => {
      if (state.documents.length >= 2) {
        state.documents.shift()
      }

      state.documents.push(action.payload)
    },
    removeDocument: (state, action: PayloadAction<number>) => {
      const index = state.documents
        .map((doc) => doc.id)
        .lastIndexOf(action.payload)

      if (index !== -1) {
        state.documents.splice(index, 1)
      }
    },
    openChatPanel: (state) => {
      state.isChatOpen = true
    },
    closeChatPanel: (state) => {
      state.isChatOpen = false
    },
    toggleDocumentPanel: (state, action: PayloadAction<boolean>) => {
      state.isOpenDocumentsPanel = action.payload
    },
    updateHash: (state, action: PayloadAction<string | null>) => {
      state.hash = action.payload
    },
    removeAllDocuments: (state) => {
      state.documents = []
    },
    toggleFirstDocumentPanel: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isChangedFirstPanel = action.payload
    },
    toggleSecondDocumentPanel: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isChangedSecondPanel = action.payload
    },
    toggleThrirdDocumentsPanel: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isChangedThreePanels = action.payload
    },
  },
})

export const {
  addDocument,
  removeDocument,
  openChatPanel,
  closeChatPanel,
  toggleDocumentPanel,
  updateHash,
  removeAllDocuments,
  toggleFirstDocumentPanel,
  toggleSecondDocumentPanel,
  toggleThrirdDocumentsPanel,
} = documentPreviewSlice.actions
export default documentPreviewSlice.reducer
