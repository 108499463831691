import { useLocation, useNavigate } from 'react-router-dom'

export const useDocumentsToggleFavoritesPage = ({
  isFavoriteRoute,
}: {
  isFavoriteRoute: boolean
}) => {
  const navigate = useNavigate()

  const handleBookmarkClick = () => {
    if (isFavoriteRoute) {
      navigate('/documents')
    } else {
      navigate('/documents/favorite')
    }
  }
  return {
    handleBookmarkClick,
    isFavoriteRoute,
  }
}
