import { useChatMessages } from '@/entities/chat/models/useChatMessages'
import { IUser } from '@/entities/user/models'
import IconLogo from '@/shared/assets/icons/icon_ai_filled_dark.svg?react'
import IconClose from '@/shared/assets/icons/icon_close.svg?react'
import { useAppDispatch, useTypedSelector } from '@/shared/store'
import {
  closeChatPanel,
  toggleFirstDocumentPanel,
} from '@/shared/store/slices/chat/documentPreviewSlice'
import { IconButton } from '@/shared/ui'
import { Typography } from '@/shared/ui/Typography'
import { cn } from '@/shared/utils/common'
import { useEffect, useState } from 'react'
import { useLoaderData, useParams } from 'react-router-dom'

import { ChatInput } from '../ChatInput'
import { ChatMessages } from '../ChatMessages'

interface ChatInputProps {
  isPanelInput?: boolean
  isVisibleSecondPanel?: boolean
}

export const ChatPanel = ({
  isPanelInput,

  isVisibleSecondPanel,
}: ChatInputProps) => {
  const dispatch = useAppDispatch()
  const { chat_id } = useParams<{ chat_id: string }>()
  const { user } = useLoaderData() as {
    user: IUser
  }
  const { data } = useChatMessages(chat_id!, Number(user.id))
  const [isVisibleFirst, setIsVisibleFirst] = useState(true)
  const { isChangedFirstPanel } = useTypedSelector(
    (state) => state.documentPreview
  )
  useEffect(() => {
    if (isChangedFirstPanel) {
      setIsVisibleFirst(false)
      setTimeout(() => {
        setIsVisibleFirst(true)
        dispatch(toggleFirstDocumentPanel(false))
      }, 1200)
    }
  }, [isChangedFirstPanel])

  return (
    <div
      className={cn(
        'relative flex shrink-0 flex-col items-center',
        'transition-[transform] duration-[600ms] ease-in-out',
        isVisibleFirst
          ? 'relative translate-x-0 bg-transparent'
          : 'fixed translate-x-full bg-inherit'
      )}
    >
      <div
        className="flex min-h-[60px] items-center justify-between self-stretch
          border-b-[1px] border-base-100 p-4"
      >
        <div className="flex cursor-pointer items-center justify-between gap-3">
          <IconLogo />
          <Typography className="font-[600]">AI-ассистент</Typography>
        </div>
        <IconButton
          onClick={() => dispatch(closeChatPanel())}
          className="cursor-pointer"
        >
          <IconClose width="24px" height="24px" />
        </IconButton>
      </div>
      <div
        className="menu_boundary relative flex h-[calc(100svh-232px)] grow flex-col
          items-center gap-4 self-stretch overflow-y-auto px-5 pb-6 pt-8"
      >
        <ChatMessages messages={data?.messages} />
      </div>
      <div className="w-full">
        <ChatInput isPanel={true} />
      </div>
    </div>
  )
}
