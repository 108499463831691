import {
  CourtBaseAssignedDocs,
  CourtBaseAssignedDocsMobile,
  CourtBaseByIdItem,
  CourtBaseByIdItemMobile,
} from '@/widgets/CourtBaseById'
import { useOutletContext } from 'react-router-dom'

import { courtBaseIdData } from './consts'

export const CourtBaseByIdPage = () => {
  const { isMobile } = useOutletContext<{
    isMobile: boolean
  }>()

  if (isMobile) {
    return (
      <div className="w-full px-2 pb-[118px]">
        <CourtBaseByIdItemMobile mainInfo={courtBaseIdData.data} />
        <CourtBaseAssignedDocsMobile
          assignedDocs={courtBaseIdData.assignedDocs}
        />
      </div>
    )
  }

  return (
    <div className="mx-auto max-w-[1010px] pb-[230px]">
      <CourtBaseByIdItem mainInfo={courtBaseIdData.data} />
      <CourtBaseAssignedDocs
        assignedDocs={courtBaseIdData.assignedDocs}
      />
    </div>
  )
}
