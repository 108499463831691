import { Table } from 'dexie'

export enum ECategoryType {
  DEVELOPING_ORGANIZATIONS = 'developing_organization',
  APPROVING_ORGANIZATIONS = 'approving_organization',
  GROUPS = 'groups',
  TYPES = 'types',
}

export interface Filter {
  id: number
  name: string
  parentId: number
  document_amount: number
  children_count: number
  tokens: string[]
}

export type DbTable = Table<Filter, number>

export type CategoriesPage = 'document' | 'court-base'

type SearchParam =
  | 'developing_organization'
  | 'approving_organization'
  | 'group'
  | 'type'

export interface Category {
  categoryType: ECategoryType
  searchParam: SearchParam
  name: string
}

export type Subtypes = Omit<Category, 'name'>

interface CategoryConfigItem {
  table: DbTable
  file: string
  category: Category
}

export type CategoriesConfig = {
  [key in CategoriesPage]: Record<ECategoryType, CategoryConfigItem>
}
