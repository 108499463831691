import { useChatInput } from '@/entities/chat/models/useChatInput'
import IconArrowActionGray from '@/shared/assets/icons/icon_arrow_action_gray.svg?react'
import { IconButton } from '@/shared/ui/IconButton'
import { Input } from '@/shared/ui/Input'
import { cn } from '@/shared/utils/common'
import { Dispatch, SetStateAction } from 'react'
import { useParams } from 'react-router-dom'

export const MobileChatInput = () => {
  const { chat_id } = useParams()

  const {
    isSending,
    inputValue,
    selectRef,
    handleInputChange,
    isSubmitButtonDisabled,
    handleSubmit,
  } = useChatInput({
    chatId: chat_id,
  })

  return (
    <form
      onSubmit={handleSubmit}
      className={cn(
        'fixed flex w-full items-center justify-center',
        'bottom-[71px] self-center bg-[#FCFCFC] px-5 pb-3',
        'lg:max-w-[768px]'
        // isPanel ? 'px-4 py-0 pb-0 lg:bottom-0 lg:pb-4' : ''
      )}
    >
      <div className="relative flex w-full items-center">
        <Input
          ref={selectRef}
          value={inputValue}
          onChange={handleInputChange}
          canRemove={false}
          disabled={isSending}
          className="w-full rounded-full border-[1px] border-base-200 bg-base-20 pr-14"
          placeholder="Введите запрос"
        />
        <IconButton
          type="submit"
          disabled={isSending}
          className={cn(
            'absolute right-2 top-1/2 -translate-y-1/2 transform',
            isSubmitButtonDisabled ? '[&_path]:fill-base-300' : ''
          )}
        >
          <IconArrowActionGray width={44} height={44} />
        </IconButton>
      </div>
    </form>
  )
}
