import {
  CourtBaseFilterDrawer,
  SearchModeFilterDrawer,
  SortModeFilterDrawer,
} from '@/features/filters'
import IconBookmarkDark from '@/shared/assets/icons/icon_bookmark_dark.svg?react'
import { IconButton } from '@/shared/ui'
import { Typography } from '@/shared/ui/Typography'

type Props = {
  totalDocuments?: number
}

export const WidgetCourtBaseFiltersMobile = ({
  totalDocuments,
}: Props) => {
  return (
    <>
      <div className="flex items-center justify-between border-y py-3">
        <div className="flex flex-col items-stretch justify-start gap-1">
          <Typography variant={'label'} className="font-semibold">
            Все дела
          </Typography>
          <Typography variant={'subtitle'} className="text-[#67707E]">
            {totalDocuments
              ? `${totalDocuments} документов`
              : '850 000 документов'}
          </Typography>
        </div>
        <div className="flex gap-8">
          <IconButton
            className="flex h-10 w-10 items-center justify-center border
              border-stroke-light-gray-2"
          >
            <IconBookmarkDark />
          </IconButton>
          <SortModeFilterDrawer />
          <SearchModeFilterDrawer />
        </div>
      </div>

      {/* Filters */}
      <div className="-mx-5 self-stretch overflow-visible">
        <div
          className="flex flex-nowrap items-center justify-start gap-3 overflow-x-scroll
            py-3 pl-5 pr-3"
        >
          <CourtBaseFilterDrawer courtBaseFilterType="case" />
          <CourtBaseFilterDrawer courtBaseFilterType="region" />
        </div>
      </div>
    </>
  )
}
