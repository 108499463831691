import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import { AppRoutes } from './app/router'
import store, { persistor } from './shared/store'
import { Toaster } from './shared/ui/Toast/toaster'

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Toaster />
        <AppRoutes />
      </PersistGate>
    </Provider>
  )
}
export default App
