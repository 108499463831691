import IconClose from '@/shared/assets/icons/icon_close.svg?react'
import { Button } from '@/shared/ui/Button'
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from '@/shared/ui/Drawer'
import { Typography } from '@/shared/ui/Typography'
import { cn } from '@/shared/utils/common'
import { courtBaseData } from '@/widgets/CourtBase/consts'
import { useEffect, useState } from 'react'

import { useCourtBaseFilter } from '../../hooks/useCourtBaseFilter'
import { useCourtBaseFilterSearchInput } from '../../hooks/useCourtBaseFilterSearchInput'
import { CourtBaseFilterType } from '../../types'
import { CourtBaseFilterCustomButton } from '../CourtBaseFilterCustomButton'
import { CourtBaseFilterItems } from '../CourtBaseFilterItems'
import { CourtBaseFilterSearch } from '../CourtBaseFilterSearch'

interface CourtBaseFilterDrawerProps {
  courtBaseFilterType: CourtBaseFilterType
}

export const CourtBaseFilterDrawer = ({
  courtBaseFilterType,
}: CourtBaseFilterDrawerProps) => {
  const [isOpenDrawer, setIsOpenDrawer] = useState(false)
  const { title, handleCheckboxChange, selectedIndices } =
    useCourtBaseFilter(courtBaseFilterType)

  const { value, handleChange, handleKeyDown } =
    useCourtBaseFilterSearchInput()

  useEffect(() => {
    if (value.length > 0) {
      setIsOpenDrawer(true)
    }
  }, [value])

  return (
    <Drawer direction="bottom">
      <DrawerTrigger>
        <CourtBaseFilterCustomButton value={title} />
      </DrawerTrigger>
      <DrawerContent
        className={cn(
          'z-[70] h-[100%] overflow-y-hidden',
          isOpenDrawer ? 'h-[75svh]' : 'h-[50svh]'
        )}
        style={{ transition: 'height 0.3s ease-in-out' }}
      >
        <DrawerClose className="absolute right-3 top-3">
          <IconClose className="h-6 w-6" />
        </DrawerClose>
        <DrawerHeader className="flex flex-col items-stretch justify-start gap-8 p-0">
          <DrawerTitle>
            <Typography className="self-start" variant="heading4">
              Номер дела
            </Typography>
          </DrawerTitle>
        </DrawerHeader>
        <DrawerDescription
          className="-mx-6 mt-6 flex flex-col items-stretch justify-start gap-4
            overflow-y-scroll px-5 pt-2"
        >
          <CourtBaseFilterSearch
            value={value}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
          />
          {isOpenDrawer ? (
            <CourtBaseFilterItems
              items={courtBaseData}
              selectedIndices={selectedIndices}
              handleCheckboxChange={handleCheckboxChange}
            />
          ) : null}
        </DrawerDescription>
        <DrawerFooter className="flex flex-row flex-nowrap justify-between gap-5 p-0 pb-5 pt-8">
          <DrawerClose className="shrink-0 grow">
            <Button
              variant={'tonal'}
              className="w-full bg-additional-blue-2 text-white disabled:cursor-not-allowed
                disabled:bg-base-100 disabled:text-base-400"
              disabled={!selectedIndices.length}
            >
              Применить
            </Button>
          </DrawerClose>
          <DrawerClose className="shrink-0 grow">
            <Button
              variant={'outlined'}
              className="w-full text-additional-blue-4 disabled:cursor-not-allowed
                disabled:border-base-400 disabled:text-base-400"
              disabled={!selectedIndices.length}
            >
              Сбросить
            </Button>
          </DrawerClose>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
}
