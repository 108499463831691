import { useContext } from 'react'

import { DocumentContentsContext } from '../contexts/DocumentContentContext'

export const useDocumentContents = () => {
  const context = useContext(DocumentContentsContext)
  if (!context) {
    throw new Error(
      'useDocumentContents must be used within a DocumentContentsProvider'
    )
  }
  return context
}
