import { useEffect, useMemo, useRef, useState } from 'react'

import './styles.css'

export const RawHtmlRenderer = ({ rawHtml }: { rawHtml: string }) => {
  const rawRef = useRef<HTMLDivElement>(null)
  const [tableWidth, setTableWidth] = useState(0)
  const processedHtml = useMemo(() => {
    return rawHtml
      .replace(/<i\s*\/?>/gi, '')
      .replace(/Статья \d+/g, (match) => `<strong>${match}</strong>`)
      .replace(/Р\sА\sЗ\sД\sЕ\sЛ/g, 'РАЗДЕЛ')
      .replace(/Сноска\./g, '')
  }, [rawHtml])

  useEffect(() => {
    if (rawRef.current) {
      const isThereATable =
        rawRef.current.querySelectorAll('.table-wrapper')
      if (isThereATable.length) {
        const { width } = rawRef.current.getBoundingClientRect()
        setTableWidth(Number(width.toFixed(0)))
        isThereATable.forEach((element) => {
          if (element instanceof HTMLElement) {
            element.style.width = `${tableWidth - 30}px`
          }
        })
      }
    }
  }, [rawRef.current])

  return (
    <div
      ref={rawRef}
      dangerouslySetInnerHTML={{ __html: processedHtml }}
    />
  )
}
