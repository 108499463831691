import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { useTypedSelector } from '@/shared/store'
import { useParams } from 'react-router-dom'

import { IChatMessage } from '../../types'
import { ChatAiResponse } from '../ChatAiResponse'
import { ChatResponseLoader } from '../ChatResponseLoader'
import { ChatUserRequest } from '../ChatUserRequest'
import { MobileChatAiResponse } from '../MobileChatAiResponse'

interface ChatMessagesProps {
  messages?: IChatMessage[]
}

export const ChatMessages = ({ messages }: ChatMessagesProps) => {
  const isMobile = useMediaQuery('(max-width: 959px)')
  const { chat_id } = useParams<{ chat_id: string }>()

  const isAwaitingResponse = useTypedSelector(
    (state) =>
      state.chats.chatsData[chat_id!]?.isAwaitingResponse ?? false
  )

  if (!messages) return null

  return (
    <div className="flex min-h-full flex-col justify-end gap-8 pb-12">
      {messages.map((item, index) => {
        const isLastMessage = index === messages.length - 1
        const messageId = isLastMessage ? 'last-message' : undefined

        if (item.from === 'ASSISTANT') {
          const ResponseComponent = isMobile
            ? MobileChatAiResponse
            : ChatAiResponse
          return (
            <div key={item.id + index} id={messageId}>
              <ResponseComponent
                response={item}
                isLastResponse={isLastMessage}
              />
            </div>
          )
        } else if (item.from === 'USER') {
          return (
            <ChatUserRequest
              key={item.id + index}
              chatMessage={item}
              id={messageId}
            />
          )
        }

        return null
      })}
      {isAwaitingResponse ? <ChatResponseLoader /> : null}
    </div>
  )
}
