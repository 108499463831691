import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { CourtBaseFilterType } from '../types'

export const useCourtBaseFilter = (
  courtBaseFilterType: CourtBaseFilterType
) => {
  const [selectedIndices, setSelectedIndices] = useState<number[]>([])
  const navigate = useNavigate()
  const location = useLocation()

  let title: string
  switch (courtBaseFilterType) {
    case 'region':
      title = 'Номер дела'
      break
    case 'case':
      title = 'Регион'
      break
    case 'mode':
      title = 'Сортировка'
      break
    default:
      title = 'Номер дела'
      break
  }

  const handleCheckboxChange = (id: string) => {
    const numberID = Number(id.replace(/-/g, ''))
    setSelectedIndices((prev) =>
      prev.includes(numberID)
        ? prev.filter((index) => index !== numberID)
        : [...prev, numberID]
    )
  }

  return {
    selectedIndices,
    handleCheckboxChange,
    title,
  }
}
