import { AssignedDoc, AssignedDocItem } from '@/entities/courtBase'
import {
  DownloadAssignedDocs,
  PrintAssignedDocs,
  SearchAssignedDocsInput,
  ToggleAssignedDocs,
} from '@/features/courtBase'
import { Accordion, Typography } from '@/shared/ui'
import { useRef, useState } from 'react'

interface CourtBaseAssignedDocsProps {
  assignedDocs: AssignedDoc[]
}

export const CourtBaseAssignedDocs = ({
  assignedDocs,
}: CourtBaseAssignedDocsProps) => {
  const accordionRefs = useRef<HTMLDivElement[]>([])
  const [openItems, setOpenItems] = useState<string[]>([])

  const toggleAllAccordions = () => {
    if (openItems.length === assignedDocs.length) {
      setOpenItems([])
    } else if (openItems.length > 0) {
      setOpenItems([])
    } else {
      setOpenItems(assignedDocs.map((doc) => doc.id.toString()))
    }
  }

  return (
    <div
      className="relative mt-[32px] flex w-full flex-col items-start justify-start
        rounded-3xl border-base-100 p-4 shadow-shadow-modal-light"
    >
      <div className="flex w-full justify-between">
        <Typography variant={'body'} className="font-medium">
          Прикрепленные документы
        </Typography>
        <div className="flex justify-center gap-4">
          <PrintAssignedDocs />
          <DownloadAssignedDocs />
          <ToggleAssignedDocs
            toggleAllAccordions={toggleAllAccordions}
            isAnyOpen={Boolean(openItems.length)}
          />
        </div>
      </div>

      <div className="w-full pt-4">
        <SearchAssignedDocsInput />
      </div>

      <div className="pt-4">
        <Accordion
          type="multiple"
          className={'pt-4'}
          value={openItems}
          onValueChange={setOpenItems}
        >
          {assignedDocs.map((doc, index) => {
            return (
              <AssignedDocItem
                key={index}
                assignedDoc={doc}
                accordionRef={accordionRefs.current[index]}
              />
            )
          })}
        </Accordion>
      </div>
    </div>
  )
}
