export type ISearchMode = 'IN_TITLE' | 'CONTEXT' | 'CONTEXT_PLUS'

//src/entities/document/types/index.ts
export enum EDocumentStatus {
  ACTIVE_WITH_CHANGES = 'ACTIVE_WITH_CHANGES',
  ACTIVE_WITHOUT_CHANGES = 'ACTIVE_WITHOUT_CHANGES',
  PENDING = 'PENDING',
  SUSPENDED = 'SUSPENDED',
  EXPIRED = 'EXPIRED',
  CANCELED = 'CANCELED',
}

export const enum EDocumentParagraphType {
  HEADING = 'HEADING',
  COMMENT = 'COMMENT',
  TEXT = 'TEXT',
}

export interface IDocument {
  id: number
  name: string
  requisites: string
  status: {
    code: EDocumentStatus
    name: string
  }
  is_favorite: boolean
}

type Languages = 'RU' | 'KK'

export interface Locale {
  current: Languages
  available: Languages[]
}

export interface Paragraph {
  id: number
  type: 'HEADING' | 'COMMENT' | 'TEXT'
  content: string
}

export interface Version {
  id: number
  date: string
}

export interface DocumentFilter {
  id: number
  name: string
  document_amount: number
  children: DocumentFilter[] | []
}

export interface DocumentQueryParams {
  search_mode?: ISearchMode
  search_query?: string
  group?: string // Comma-separated values (e.g., '1,2,3')
  type?: string // Comma-separated values (e.g., '1,2,3')
  organization?: string // Comma-separated values (e.g., '1,2,3')
  status?: 'ACTIVE' | 'PENDING'
  created_at?: string // Date in 'YYYY-MM-DD' format (e.g., '2024-01-15')
  created_at_from?: string // Date in 'YYYY-MM-DD' format (e.g., '2024-01-01')
  created_at_until?: string // Date in 'YYYY-MM-DD' format (e.g., '2024-01-30')
  page?: number // Optional page number for pagination
}
