import { ICourtItem } from '@/entities/courtBase'
import { CourtBaseItemStatus } from '@/entities/courtBase/ui/CourtBaseItemStatus'
import { Typography } from '@/shared/ui'
import { cn } from '@/shared/utils/common'
import React from 'react'

interface CourtBaseByIdItemProps {
  mainInfo: ICourtItem
}

export const CourtBaseByIdItem = ({
  mainInfo,
}: CourtBaseByIdItemProps) => {
  const courtByIdData = [
    { name: 'Истцы', data: mainInfo.plaintiffs },
    { name: 'Ответчики', data: mainInfo.defendants },
    { name: 'Суд', data: [mainInfo.court] },
    { name: 'Судьи', data: mainInfo.judges },
    { name: 'Требования', data: [mainInfo.requirements] },
  ]

  return (
    <div
      className="relative flex w-full flex-col items-start justify-start rounded-3xl
        border border-base-100 py-6 pl-6 pr-16 shadow-shadow-modal-light"
    >
      <div className="relative flex w-full cursor-pointer items-stretch justify-between">
        <Typography variant={'body'} className="font-medium">
          Гражданское дело №{mainInfo.id}
        </Typography>

        <Typography variant={'body'} className="font-medium">
          От {mainInfo.date}
        </Typography>
      </div>
      {/* Body */}
      <div className="grid grid-cols-[1fr_auto] gap-x-2.5 gap-y-2 pt-4">
        {courtByIdData.map((court, index) => {
          const isLast = index + 1 === courtByIdData.length
          return (
            <React.Fragment key={court.name}>
              <Typography
                variant={'label'}
                className="align-center flex translate-y-0.5 font-medium text-base-900"
              >
                {court.name}:
              </Typography>
              <span
                className={cn(
                  // py-3 pl-4 pr-14
                  isLast ? '' : 'pb-2',
                  'border-b-[1px]'
                )}
              >
                {court.data.map((el, index) => {
                  const isFirst = index === 0

                  return (
                    <Typography
                      variant={'label'}
                      className="font-medium text-base-900"
                      key={index}
                    >
                      {isFirst ? null : '• '}
                      {el}
                    </Typography>
                  )
                })}
              </span>
            </React.Fragment>
          )
        })}
        <Typography
          variant={'label'}
          className="pt-2 font-medium text-base-900"
        >
          Решение:
        </Typography>
        <div className="flex items-center justify-start gap-2">
          {mainInfo.status.map((stat, index) => {
            const isLast = index + 1 === mainInfo.status.length

            return (
              <span key={index}>
                <CourtBaseItemStatus status={stat} />
                {!isLast && (
                  <Typography
                    variant={'label'}
                    className="pl-2 pt-px font-medium leading-[2px] text-base-300"
                  >
                    |
                  </Typography>
                )}
              </span>
            )
          })}
        </div>
      </div>
    </div>
  )
}
