// FiltersSearchInput Component
import IconSearch from '@/shared/assets/icons/icon_search_dark.svg?react'
import { IconButton } from '@/shared/ui'
import { cn } from '@/shared/utils/common'
import React, { ForwardedRef, InputHTMLAttributes } from 'react'

interface CourtBaseFilterSearchProps
  extends InputHTMLAttributes<HTMLInputElement> {
  inputType?: string
  onValueChange?: (value: string) => void
  innerRef?: ForwardedRef<HTMLInputElement>
}

export const CourtBaseFilterSearch: React.FC<
  CourtBaseFilterSearchProps
> = ({
  inputType = 'text',
  onValueChange,
  innerRef,
  className,
  value,
  onChange,
  onKeyDown,
  onSubmit,
  ...props
}) => {
  return (
    <div className="relative flex w-full items-start gap-2 rounded-t-3xl pb-[1px]">
      <input
        type={inputType}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        ref={innerRef}
        placeholder="Поиск по названию документа..."
        className={cn(
          `border-input placeholder:font-inter flex h-12 w-full rounded-[20px]
          border border-base-100 bg-[#F1F2F4] pl-4 pr-6 text-sm shadow-sm
          transition-colors file:border-0 file:bg-transparent file:text-sm
          file:font-medium placeholder:text-sm placeholder:font-medium
          placeholder:leading-5 placeholder:text-[#67707E]
          focus-visible:outline-none focus-visible:ring-1
          focus-visible:ring-base-100 disabled:cursor-not-allowed
          disabled:opacity-50`,

          className
        )}
        {...props}
      />
      <IconButton className="absolute right-2 h-10 w-10 translate-y-1 rounded-[16px]">
        <IconSearch className="h-5 w-5" />
      </IconButton>
    </div>
  )
}
