import IconSearch from '@/shared/assets/icons/icon_search_dark.svg?react'
import { Input } from '@/shared/ui'

const SearchAssignedDocsInput = () => {
  return (
    <Input
      className="min-h-[48px] rounded-[20px] bg-base-100 pl-4 text-[14px]"
      placeholder="Поиск внутри документов..."
      rightIcon={
        <div
          className="absolute -right-1.5 top-1/2 -translate-y-1/2 transform rounded-[16px]
            bg-white p-2.5"
        >
          <IconSearch />
        </div>
      }
    />
  )
}

export { SearchAssignedDocsInput }
