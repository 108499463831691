import { ButtonToggleFavoriteMessage } from '@/features/chat/ui/ButtonToggleFavoriteMessage'
import IconCodexGray from '@/shared/assets/icons/icon_codex_gray.svg?react'
import IconCopyGray from '@/shared/assets/icons/icon_copy_mobile.svg?react'
import { IconButton } from '@/shared/ui/IconButton'
import { Typography } from '@/shared/ui/Typography'
import { cn } from '@/shared/utils/common'
import { HTMLAttributes } from 'react'
import ReactMarkdown from 'react-markdown'
import { useParams } from 'react-router-dom'

import { IChatMessage } from '../../types'
import { MobileChatAiFile } from '../MobileChatAiFile'

interface MobileChatAiResponseProps
  extends HTMLAttributes<HTMLDivElement> {
  response: IChatMessage
  isLastResponse: boolean
}

export const MobileChatAiResponse = ({
  response,
  isLastResponse,
  ...props
}: MobileChatAiResponseProps) => {
  const { chat_id } = useParams()

  return (
    <div
      className="group flex flex-col items-stretch justify-start self-stretch
        rounded-lg"
      {...props}
    >
      {/* Ai message */}
      <div className="flex items-start justify-start gap-4">
        <IconButton
          className="flex items-center justify-center rounded-full border-[1px]
            border-base-200 bg-white p-[6px]"
        >
          <IconCodexGray />
        </IconButton>
        <Typography className="whitespace-normal break-words">
          <ReactMarkdown className={'markdown -mt-[18px]'}>
            {response.message ?? ''}
          </ReactMarkdown>{' '}
        </Typography>
      </div>
      {/* Ai files */}
      <div className="flex flex-col items-stretch justify-start gap-4">
        {response.documents && (
          <div className="flex flex-col items-stretch justify-start gap-4">
            {response.documents.length > 0 &&
              response.documents.map((file) => {
                const fileKey = `${file.id + response.id}`
                return <MobileChatAiFile file={file} key={fileKey} />
              })}
          </div>
        )}
        <div
          className={cn(
            'flex items-center justify-start gap-3 self-stretch'
          )}
        >
          <IconButton>
            <IconCopyGray
              height={24}
              width={24}
              className="[&>g>path]:stroke-[#67707E]"
            />
          </IconButton>
          <ButtonToggleFavoriteMessage
            chatId={chat_id ? chat_id : response.chat?.id}
            isFavorite={response.is_favorite}
            messageId={String(response.id)}
          />
        </div>
      </div>
    </div>
  )
}
