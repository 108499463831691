import IconChevronRight from '@/shared/assets/icons/icon_chevron_right.svg?react'
import IconMoreDotsDark from '@/shared/assets/icons/icon_more_dots_dark.svg?react'
import { Typography } from '@/shared/ui'
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/shared/ui/Accordion'
import { cn } from '@/shared/utils/common'

import { AssignedDoc } from '../../types'

interface AssignedDocItemProps {
  assignedDoc: AssignedDoc
  accordionRef: any
}

const AssignedDocItem = ({
  assignedDoc,
  accordionRef,
}: AssignedDocItemProps) => {
  return (
    <AccordionItem value={assignedDoc.id.toString()}>
      <Typography variant={'body'} className="pl-2">
        <span className="pr-1">{'• '}</span>
        {assignedDoc.date}
      </Typography>
      <div
        className={cn(
          'relative mt-2 flex w-full flex-col items-start justify-start',
          `rounded-2xl border-[1px] border-base-100 pb-0 pt-4
          shadow-shadow-modal-light`
        )}
      >
        <AccordionTrigger
          className="p-0 px-4 pb-4"
          iconSvg={
            <div className="flex h-full flex-col justify-between">
              <IconMoreDotsDark />
              <div className="self-end rounded-[8px] border-[1px] border-base-200 p-[1px]">
                <IconChevronRight className="min-h-5 min-w-5" />
              </div>
            </div>
          }
        >
          <span className="flex h-full flex-col justify-start gap-2 pr-[67px] text-start">
            <Typography
              variant={'body'}
              className="inline font-medium"
            >
              {assignedDoc.additional_info}{' '}
            </Typography>

            <Typography
              variant={'label'}
              className="inline-flex max-w-fit rounded-[4px] bg-base-100 px-2 text-sm
                font-semibold"
            >
              {assignedDoc.id}
            </Typography>
          </span>
        </AccordionTrigger>
        <AccordionContent
          className="flex w-full flex-col gap-1 border-t-[1px] border-base-100 px-4 pb-4
            pt-4"
        >
          <Typography variant={'body'} className="inline text-sm">
            {assignedDoc.assignedDocInfo}
          </Typography>
        </AccordionContent>
      </div>
    </AccordionItem>
  )
}

export { AssignedDocItem }
