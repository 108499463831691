import IconDownload from '@/shared/assets/icons/icon_download_dark.svg?react'
import { IconButton } from '@/shared/ui'

const DownloadAssignedDocsButton = () => {
  return (
    <IconButton className="rounded-[8px] border border-base-100 p-[5px]">
      <IconDownload />
    </IconButton>
  )
}

export { DownloadAssignedDocsButton }
