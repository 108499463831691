import { useAppDispatch, useTypedSelector } from '@/shared/store'
import {
  removeAllDocuments,
  toggleDocumentPanel,
  toggleSecondDocumentPanel,
  toggleThrirdDocumentsPanel,
  updateHash,
} from '@/shared/store/slices/chat/documentPreviewSlice'
import { useSidebar } from '@/widgets/Sidebar'
import { useEffect, useRef, useState } from 'react'
import { ImperativePanelGroupHandle } from 'react-resizable-panels'
import { useLocation } from 'react-router-dom'

export const useDocumentPrevew = () => {
  const dispatch = useAppDispatch()
  const { isSidebarOpen } = useSidebar()
  const location = useLocation()
  const [isVisibleSecondDocument, setIsVisibleSecondDocument] =
    useState(true)

  const [isVisibleSecondPanel, setIsVisibleSecondPanel] =
    useState(true)
  const [isVisibleFirst, setIsVisibleFirst] = useState(true)

  const [isVisibleThreePanel, setIsVisibleThreePanel] = useState(true)

  const panelRef = useRef<ImperativePanelGroupHandle | null>(null)

  const {
    documents,
    isChatOpen,
    isOpenDocumentsPanel,
    hash,
    isChangedFirstPanel,
    isChangedSecondPanel,
    isChangedThreePanels,
  } = useTypedSelector((state) => state.documentPreview)
  const [oldDocuments, setOldDocuments] = useState(documents)
  const [oldChatOpen, setOldChatOpen] = useState(isChatOpen)

  const isAnyPanelOpen =
    isChatOpen ||
    documents.length === 2 ||
    isChangedFirstPanel ||
    isChangedSecondPanel
  const isTwoPanels =
    isAnyPanelOpen || isChangedFirstPanel || isChangedSecondPanel

  useEffect(() => {
    if (isChangedFirstPanel) {
      setIsVisibleFirst(false)
      setTimeout(() => {
        setIsVisibleFirst(true)
      }, 300)
    }
  }, [isChangedFirstPanel])

  useEffect(() => {
    if (panelRef.current) {
      if (documents.length === 1) {
        return
      }
      if (isChangedSecondPanel) {
        panelRef.current.setLayout([50, 50])
      }
      if (
        JSON.stringify(documents[0]) !==
          JSON.stringify(oldDocuments[0]) ||
        JSON.stringify(documents[1]) !==
          JSON.stringify(oldDocuments[1])
      ) {
        panelRef.current.setLayout([50, 50])
        setOldDocuments(documents)
      }

      if (isChatOpen !== oldChatOpen && documents.length !== 2) {
        panelRef.current.setLayout([50, 50])
        setOldChatOpen(isChatOpen)
      }
    }
  }, [documents, isChatOpen])

  useEffect(() => {
    if (isChangedSecondPanel) {
      setIsVisibleSecondDocument(false)
      setIsVisibleSecondPanel(false)
      setTimeout(() => setIsVisibleSecondPanel(true), 100)
      setTimeout(() => {
        setIsVisibleSecondDocument(true)
        dispatch(toggleSecondDocumentPanel(false))
      }, 200)
    }
  }, [isChangedSecondPanel, dispatch])

  useEffect(() => {
    if (isChangedThreePanels) {
      setIsVisibleThreePanel(false)
      setTimeout(() => {
        setIsVisibleThreePanel(true)
        dispatch(toggleThrirdDocumentsPanel(false))
      }, 600)
    }
  }, [isChangedThreePanels, dispatch])

  useEffect(() => {
    if (documents.length > 0) {
      dispatch(toggleDocumentPanel(true))
    } else {
      dispatch(toggleDocumentPanel(false))
    }
  }, [documents, dispatch])

  useEffect(() => {
    const pathSegments = location.pathname.split('/')
    const hashId = pathSegments[pathSegments.length - 1]
    if (hashId !== hash) {
      dispatch(removeAllDocuments())
    }
    if (hashId) {
      dispatch(updateHash(hashId))
    }
  }, [location, dispatch, hash])

  return {
    documents,
    isChatOpen,
    isAnyPanelOpen,
    isSidebarOpen,
    isOpenDocumentsPanel,
    isVisibleSecondDocument,
    isVisibleSecondPanel,
    isVisibleThreePanel,
    panelRef,
    isChangedFirstPanel,
    isChangedSecondPanel,
    isTwoPanels,
    isVisibleFirst,
  }
}
