// useAiResponse.ts
import {
  chatApi,
  usePatchToggleFavoriteMessageMutation,
} from '@/shared/api/chat'
import { useCallback } from 'react'

export const useAiResponse = (chat_id?: string) => {
  const [patchToggleFavoriteMessage] =
    usePatchToggleFavoriteMessageMutation()

  const handleToggleFavorite = useCallback(
    async (message_id?: string) => {
      if (chat_id && message_id)
        try {
          await patchToggleFavoriteMessage({
            chat_id,
            id: message_id,
          })
          chatApi.util.invalidateTags([{ type: 'Chat', id: 'LIST' }])
        } catch (error) {
          console.error('Failed to toggle favorite:', error)
        }
    },
    [chat_id, patchToggleFavoriteMessage]
  )

  return { handleToggleFavorite }
}
