import { Button } from '@/shared/ui/Button'
import { CardContent, CardFooter } from '@/shared/ui/Card'
import { Input } from '@/shared/ui/Input'
import { InputCounter } from '@/shared/ui/InputCounter'
import { LabeledSelect } from '@/shared/ui/Select'
import { Skeleton } from '@/shared/ui/Skeleton'
import { Typography } from '@/shared/ui/Typography'
import { unformatPhone } from '@/shared/utils/unformatPhone'
import { Controller, FormProvider } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { Link } from 'react-router-dom'

import { useSignupJudicial } from '../../models/useSignupJudicial'

export const SignupJudicial = ({
  isTablet,
}: {
  isTablet: boolean
}) => {
  const {
    methods,
    onSubmitOTP,
    resetField,
    companyTypes,
    errors,
    isLoading,
    isCompanyTypesLoading,
  } = useSignupJudicial()

  const phone = methods.watch('phone') ?? ''

  const orgTypeOptions = [
    {
      label: 'ТОО',
      value: 'ТОО',
    },
    {
      label: 'АО',
      value: 'АО',
    },
  ]

  if (isCompanyTypesLoading) {
    // Skeleton layout based on the form structure
    return (
      <CardContent className="flex flex-col gap-4">
        {/* Skeleton for FIO */}
        <Skeleton className="h-12 w-full rounded-lg" />
        {/* Skeleton for Email */}
        <Skeleton className="h-12 w-full rounded-lg" />
        {/* Skeleton for Phone */}
        <Skeleton className="h-12 w-full rounded-lg" />
        {/* Skeleton for Company Name */}
        <Skeleton className="h-12 w-full rounded-lg" />
        {/* Skeleton for BIN */}
        <Skeleton className="h-12 w-full rounded-lg" />
        {/* Skeleton for Company Type and Licenses */}
        <div className="flex flex-row justify-between gap-4">
          <Skeleton className="h-12 w-[49%] rounded-lg" />
          <Skeleton className="h-12 w-[49%] rounded-lg" />
        </div>
        {/* Skeleton for Submit Button */}
        <Skeleton className="mt-8 h-12 w-full rounded-lg" />
        {/* Skeleton for the agreement text */}
        <Skeleton className="mt-4 h-4 w-[80%] self-center rounded-md" />
      </CardContent>
    )
  }
  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmitOTP}>
        {isTablet ? (
          <CardContent className="flex flex-col gap-4">
            <Controller
              name="name"
              control={methods.control}
              rules={{ required: 'Обязательное поле' }}
              render={({ field }) => (
                <Input
                  {...field}
                  label="ФИО"
                  resetField={resetField}
                  error={errors.name?.message}
                  placeholder="Иван Васильевич"
                />
              )}
            />
            <Controller
              name="email"
              control={methods.control}
              rules={{ required: 'Обязательное поле' }}
              render={({ field }) => (
                <Input
                  {...field}
                  label="Email"
                  resetField={resetField}
                  error={errors.email?.message}
                  placeholder="email@gmail.com"
                />
              )}
            />
            <Controller
              name="phone"
              control={methods.control}
              defaultValue=""
              rules={{ required: 'Обязательное поле' }}
              render={({ field }) => (
                <InputMask
                  {...field}
                  mask="(999) 999-99-99"
                  prefix="+7"
                  placeholder="(___) ___-__-__"
                >
                  <Input
                    type="tel"
                    label="Номер телефона"
                    resetField={resetField}
                    error={errors.phone?.message}
                  />
                </InputMask>
              )}
            />
            <Controller
              name="company_name"
              control={methods.control}
              rules={{ required: 'Обязательное поле' }}
              render={({ field }) => (
                <Input
                  {...field}
                  label="Наименование организации"
                  resetField={resetField}
                  error={errors.company_name?.message}
                  placeholder="ТОО “Codex”"
                />
              )}
            />
            <Controller
              name="company_bin"
              control={methods.control}
              rules={{ required: 'Обязательное поле' }}
              render={({ field }) => (
                <Input
                  {...field}
                  label="БИН"
                  type="number"
                  maxLength={12}
                  resetField={resetField}
                  error={errors.company_bin?.message}
                  placeholder="123456"
                />
              )}
            />
            <div
              className="flex shrink grow-0 basis-full flex-row items-center justify-between
                gap-4"
            >
              <Controller
                name="company_type"
                defaultValue={orgTypeOptions?.[0]?.value || ''}
                control={methods.control}
                rules={{ required: 'Обязательное поле' }}
                render={({ field }) => (
                  <LabeledSelect
                    {...field}
                    label="Форма организации"
                    options={orgTypeOptions ?? []}
                  />
                )}
              />
              <Controller
                name="licences_count"
                defaultValue={1}
                control={methods.control}
                rules={{
                  required: 'Обязательное поле',
                }}
                render={({ field }) => (
                  <InputCounter
                    {...field}
                    label="Количество лицензий"
                  />
                )}
              />
            </div>
            <CardFooter className="mt-8 flex flex-col items-center justify-start gap-5 lg:gap-8">
              <div className="flex flex-col items-stretch justify-center gap-4 self-stretch">
                <Button
                  variant={'primary'}
                  className="w-full"
                  type="submit"
                  disabled={
                    isLoading ||
                    phone.replace(/[\s()-/_]/g, '').length !== 10
                  }
                >
                  Отправить пароль на почту
                </Button>
                <Typography className="text-center text-xs text-gray-500">
                  Уже есть аккаунт?{' '}
                  <Link
                    to={'/signin'}
                    className="font-medium text-base-900 underline"
                  >
                    Войти
                  </Link>
                </Typography>
              </div>
              <Typography
                variant={'subtitle'}
                className="text-center font-normal text-[#9EA2AA]"
              >
                Используя CODEX, Вы соглашаетесь с{' '}
                <span className={'underline'}>
                  Политикой конфиденциальности
                </span>
              </Typography>
            </CardFooter>
          </CardContent>
        ) : (
          <>
            <CardContent className="flex flex-col gap-4">
              <div className="flex flex-row items-center justify-between gap-4">
                <Controller
                  name="name"
                  control={methods.control}
                  rules={{ required: 'Обязательное поле' }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      label="ФИО"
                      resetField={resetField}
                      error={errors.name?.message}
                      placeholder="Иван Васильевич"
                    />
                  )}
                />
                <Controller
                  name="phone"
                  control={methods.control}
                  defaultValue=""
                  rules={{ required: 'Обязательное поле' }}
                  render={({ field }) => (
                    <InputMask
                      {...field}
                      mask="(999) 999-99-99"
                      prefix="+7"
                      placeholder="(___) ___-__-__"
                    >
                      <Input
                        type="tel"
                        label="Номер телефона"
                        resetField={resetField}
                        error={errors.phone?.message}
                      />
                    </InputMask>
                  )}
                />
              </div>
              <div className="flex flex-row items-center justify-between gap-4">
                <Controller
                  name="email"
                  control={methods.control}
                  rules={{ required: 'Обязательное поле' }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      label="Email"
                      resetField={resetField}
                      error={errors.email?.message}
                      placeholder="email@gmail.com"
                    />
                  )}
                />
                <Controller
                  name="company_name"
                  control={methods.control}
                  rules={{ required: 'Обязательное поле' }}
                  render={({ field }) => (
                    <Input
                      {...field}
                      label="Наименование организации"
                      resetField={resetField}
                      error={errors.company_name?.message}
                      placeholder="ТОО “Codex”"
                    />
                  )}
                />
              </div>
              <div className="flex flex-row items-center justify-between gap-4">
                <div className="shrink-0 grow basis-[49%]">
                  <Controller
                    name="company_bin"
                    control={methods.control}
                    rules={{ required: 'Обязательное поле' }}
                    render={({ field }) => (
                      <Input
                        {...field}
                        label="БИН"
                        maxLength={12}
                        resetField={resetField}
                        error={errors.company_bin?.message}
                        placeholder="123456"
                      />
                    )}
                  />
                </div>
                <div
                  className="flex shrink grow-0 basis-[49%] flex-row justify-between gap-4
                    self-stretch"
                >
                  <Controller
                    name="company_type"
                    defaultValue={orgTypeOptions?.[0]?.value || ''}
                    control={methods.control}
                    rules={{ required: 'Обязательное поле' }}
                    render={({ field }) => (
                      <LabeledSelect
                        {...field}
                        label="Форма организации"
                        options={orgTypeOptions ?? []}
                      />
                    )}
                  />
                  <Controller
                    name="licences_count"
                    defaultValue={1}
                    control={methods.control}
                    rules={{
                      required: 'Обязательное поле',
                    }}
                    render={({ field }) => (
                      <InputCounter
                        {...field}
                        label="Количество лицензий"
                        className="xl:gap-4 xl:self-start"
                      />
                    )}
                  />
                </div>
              </div>
            </CardContent>
            <CardFooter className="mt-8 flex flex-col items-center justify-start gap-5 lg:gap-8">
              <div className="flex flex-col items-stretch justify-center gap-4 self-stretch">
                <Button
                  variant={'primary'}
                  className="w-full"
                  type="submit"
                  disabled={
                    isLoading ||
                    phone.replace(/[\s()-/_]/g, '').length !== 10
                  }
                >
                  Отправить пароль на почту
                </Button>
                <Typography className="text-center text-xs text-gray-500">
                  Уже есть аккаунт?{' '}
                  <Link
                    to={'/signin'}
                    className="font-medium text-base-900 underline"
                  >
                    Войти
                  </Link>
                </Typography>
              </div>
              <Typography
                variant={'subtitle'}
                className="text-center font-normal text-[#9EA2AA]"
              >
                Используя CODEX, Вы соглашаетесь с{' '}
                <span className={'underline'}>
                  Политикой конфиденциальности
                </span>
              </Typography>
            </CardFooter>
          </>
        )}
      </form>
    </FormProvider>
  )
}
