import db from '@/db'

import { CategoriesConfig, ECategoryType } from '../types'

export const categoriesConfig: CategoriesConfig = {
  document: {
    types: {
      table: db.types,
      file: '/typesData.json',
      category: {
        categoryType: ECategoryType.TYPES,
        searchParam: 'type',
        name: 'Тип документа',
      },
    },
    groups: {
      table: db.groups,
      file: '/groupsData.json',
      category: {
        categoryType: ECategoryType.GROUPS,
        searchParam: 'group',
        name: 'Отрасль права',
      },
    },
    developing_organization: {
      table: db.organizations,
      file: '/organizationsData.json',
      category: {
        categoryType: ECategoryType.DEVELOPING_ORGANIZATIONS,
        searchParam: 'developing_organization',
        name: 'Орган разработки',
      },
    },
    approving_organization: {
      table: db.organizations,
      file: '/organizationsData.json',
      category: {
        categoryType: ECategoryType.APPROVING_ORGANIZATIONS,
        searchParam: 'approving_organization',
        name: 'Орган принятия',
      },
    },
  },
  'court-base': {
    types: {
      table: db.types,
      file: '/typesData.json',
      category: {
        categoryType: ECategoryType.TYPES,
        searchParam: 'type',
        name: 'Номер дела',
      },
    },
    groups: {
      table: db.groups,
      file: '/groupsData.json',
      category: {
        categoryType: ECategoryType.GROUPS,
        searchParam: 'group',
        name: 'Регион',
      },
    },
    developing_organization: {
      table: db.organizations,
      file: '/organizationsData.json',
      category: {
        categoryType: ECategoryType.DEVELOPING_ORGANIZATIONS,
        searchParam: 'developing_organization',
        name: 'Орган разработки',
      },
    },
    approving_organization: {
      table: db.organizations,
      file: '/organizationsData.json',
      category: {
        categoryType: ECategoryType.APPROVING_ORGANIZATIONS,
        searchParam: 'approving_organization',
        name: 'Орган принятия',
      },
    },
  },
}
