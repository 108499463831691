import IconChevronLeft from '@/shared/assets/icons/icon_chevron_left_dark.svg?react'
import IconDots from '@/shared/assets/icons/icon_more_dots_dark.svg?react'
import { useNavigateBack } from '@/shared/hooks/useNavigateBack'
import { Typography } from '@/shared/ui'

interface CourtBaseHeaderMobileProps {
  id: string
}

export const CourtBaseHeaderMobile = ({
  id,
}: CourtBaseHeaderMobileProps) => {
  const handleGoBack = useNavigateBack('/court-base')

  return (
    <div className="flex w-full items-center justify-between px-2 py-4">
      <IconChevronLeft
        width={'24px'}
        height={'24px'}
        onClick={handleGoBack}
      />
      <Typography variant={'body'} className="font-semibold">
        №{id}
      </Typography>
      <IconDots />
    </div>
  )
}
