import IconBurger from '@/shared/assets/icons/icon_burger_gray.svg?react'
import IconNewChat from '@/shared/assets/icons/icon_new_chat.svg?react'
import { IconButton } from '@/shared/ui/IconButton'
import { useNavigate } from 'react-router-dom'

import { MobileSidebar } from '../../Sidebar'

export const MobilePlatformHeader = () => {
  const navigate = useNavigate()

  return (
    <div
      className="fixed top-0 z-30 inline-flex w-full items-start justify-between
        bg-[#FCFCFDD9] backdrop-blur-[8px]"
    >
      <MobileSidebar>
        <IconBurger className="h-6 w-6 [&_path]:stroke-base-950" />
      </MobileSidebar>
      <IconButton onClick={() => navigate('/')} className="px-5 py-5">
        <IconNewChat className="h-6 w-6" />
      </IconButton>
    </div>
  )
}
