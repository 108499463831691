import axios from 'axios'

interface IConfig {
  DOMAIN_BASE: string
  API_BASE: string
}

export interface IResponseError {
  success: boolean
  code: number
  message: string
  messages?: IFieldError
}

interface IFieldError {
  [key: string]: string[]
}

export interface Meta {
  total: number
  per_page: number
  current_page: number
  next_page: number | null
  last_page: number
}

const config_main: Record<'dev' | 'test' | 'prod', IConfig> = {
  dev: {
    DOMAIN_BASE: 'http://localhost:3000',
    API_BASE: 'https://api.e-codex.kz/v1',
  },
  test: {
    DOMAIN_BASE: 'https://e-codex.kz',
    API_BASE: 'https://api.e-codex.kz/v1',
  },
  prod: {
    DOMAIN_BASE: 'https://e-codex.kz',
    API_BASE: 'https://api.e-codex.kz/v1',
  },
}

export const deploy_env: 'dev' | 'test' | 'prod' =
  (import.meta.env.VITE_ENV as 'dev' | 'test' | 'prod') ?? 'dev'

export const isDevEnv = import.meta.env.MODE === 'development'

export const config: IConfig = isDevEnv
  ? config_main['dev']
  : config_main[deploy_env]

console.log(`Running version: %c${deploy_env}`, 'color: red')

const api = axios.create({
  baseURL: config.API_BASE,
})

export { api }
