import { useAppDispatch, useTypedSelector } from '@/shared/store'
import {
  closeChatPanel,
  openChatPanel,
  removeDocument,
  toggleDocumentPanel,
  toggleSecondDocumentPanel,
  toggleThrirdDocumentsPanel,
} from '@/shared/store/slices/chat/documentPreviewSlice'
import { useState } from 'react'

export const useDocumentPanel = () => {
  const dispatch = useAppDispatch()
  const { documents, isChatOpen } = useTypedSelector(
    (state) => state.documentPreview
  )

  const [isInfoDialogOpen, setIsInfoDialogOpen] =
    useState<boolean>(false)

  const handleInfoClick = () => {
    setIsInfoDialogOpen((prev) => !prev)
  }
  const handleInfoClose = () => {
    setIsInfoDialogOpen(false)
  }

  const handleRemoveDocument = (id: number, index: number) => {
    if (documents.length === 1) {
      dispatch(toggleDocumentPanel(false))
      setTimeout(() => dispatch(removeDocument(id)), 150)
    } else {
      if (index === 0) {
        dispatch(toggleThrirdDocumentsPanel(true))
      }
      dispatch(toggleSecondDocumentPanel(true))
      dispatch(closeChatPanel())
      setTimeout(() => {
        dispatch(removeDocument(id))
      }, 300)
    }
  }

  return {
    isInfoDialogOpen,
    handleRemoveDocument,
    handleInfoClose,
    handleInfoClick,
  }
}
