import { useChatMessages } from '@/entities/chat/models/useChatMessages'
import { ChatContainer } from '@/entities/chat/ui/ChatContainer'
import { ChatContainerSkeleton } from '@/entities/chat/ui/ChatContainerSkeleton'
import { ChatPanelButton } from '@/entities/chat/ui/ChatPanelButton'
import { MobileChatContainer } from '@/entities/chat/ui/MobileChatContainer'
import { IUser } from '@/entities/user/models'
import { DocumentPreviewContainer } from '@/features/document'
import useMediaQuery from '@/shared/hooks/useMediaQuery'
import { useTypedSelector } from '@/shared/store'
import { useSidebar } from '@/widgets/Sidebar'
import { useLoaderData, useParams } from 'react-router-dom'

export const ChatByIdPage = () => {
  const { chat_id } = useParams<{ chat_id: string }>()
  const { user } = useLoaderData() as {
    user: IUser
  }

  const isMobile = useMediaQuery('(max-width: 959px)')
  const { data, isFetching, isLoading } = useChatMessages(
    chat_id!,
    Number(user.id)
  )

  const { isSidebarOpen } = useSidebar()

  if (!data?.messages || !chat_id) {
    return <div>Chat not found</div>
  }

  if (isMobile && !isFetching && !isLoading)
    return (
      <div
        className={`relative mx-5 mt-16 flex flex-1 flex-col items-stretch justify-start
          overflow-y-auto`}
      >
        <MobileChatContainer messages={data.messages ?? []} />
      </div>
    )

  if (!isFetching && !isLoading) {
    return (
      <>
        <ChatContainer
          messages={data.messages ?? []}
          // No more isAwaitingResponse prop needed
        />
        <DocumentPreviewContainer />
        <ChatPanelButton />
      </>
    )
  }

  return (
    <ChatContainerSkeleton
      isSidebarOpen={isSidebarOpen}
      isMobile={isMobile}
    />
  )
}
