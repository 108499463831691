import { combineReducers, configureStore } from '@reduxjs/toolkit'
import {
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
} from 'react-redux'
import {
  PersistConfig,
  Persistor,
  persistReducer,
  persistStore,
} from 'redux-persist'
import storageSession from 'redux-persist/lib/storage/session'

import { broadcastAuthApi } from '../api/broadcast'
import { chatApi } from '../api/chat'
import { documentsApi } from '../api/documents'
import { signinIndividualApi } from '../api/signinIndividual'
import { signupIndividualApi } from '../api/signupIndividual'
import { signupJudicialApi } from '../api/signupJudicial'
import { userApi } from '../api/user'
import { rtkQueryErrorLogger } from './middleware'
import signinIndividualReducer from './slices/auth/signinIndividualSlice'
import signinJudicialReducer from './slices/auth/signinJudicialSlice'
import signupIndividualReducer from './slices/auth/signupIndividualSlice'
import signupJudicialReducer from './slices/auth/signupJudicialSlice'
import userReducer from './slices/auth/userSlice'
import chatReducer from './slices/chat/chatSlice'
import documentPreviewReducer from './slices/chat/documentPreviewSlice'
import DocumentMetaReducer from './slices/meta/documentMeta'

const documentsApiPersistConfig: PersistConfig<any> = {
  key: 'documentsApi',
  storage: storageSession,
  whitelist: ['queries', 'mutations'], // Persist queries and mutations
}

const persistedDocumentsApiReducer = persistReducer(
  documentsApiPersistConfig,
  documentsApi.reducer
)

const rootReducer = combineReducers({
  signupJudicial: signupJudicialReducer,
  signupIndividual: signupIndividualReducer,
  signinIndividual: signinIndividualReducer,
  signinJudicial: signinJudicialReducer,
  chats: chatReducer,
  user: userReducer,
  documentPreview: documentPreviewReducer,
  documentMeta: DocumentMetaReducer,
  [signinIndividualApi.reducerPath]: signinIndividualApi.reducer,
  [signupIndividualApi.reducerPath]: signupIndividualApi.reducer,
  [signupJudicialApi.reducerPath]: signupJudicialApi.reducer,
  [broadcastAuthApi.reducerPath]: broadcastAuthApi.reducer,
  [chatApi.reducerPath]: chatApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  // Use the persisted reducer for documentsApi
  [documentsApi.reducerPath]: persistedDocumentsApiReducer,
})

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // Needed for redux-persist to work with RTK Query
      serializableCheck: {
        ignoredActions: [
          'persist/PERSIST',
          'persist/REHYDRATE',
          // Add any other actions that cause serialization errors
        ],
      },
    }).concat(
      documentsApi.middleware,
      signinIndividualApi.middleware,
      signupIndividualApi.middleware,
      signupJudicialApi.middleware,
      broadcastAuthApi.middleware,
      chatApi.middleware,
      userApi.middleware,
      rtkQueryErrorLogger
    ),
})

export const persistor: Persistor = persistStore(store)
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useTypedSelector: TypedUseSelectorHook<RootState> =
  useSelector

export default store
