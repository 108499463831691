import IconCancelGray from '@/shared/assets/icons/icon_cancel_gray.svg?react'
import IconEye from '@/shared/assets/icons/icon_eye_dark.svg?react'
import { cn } from '@/shared/utils/common'
import React, { forwardRef } from 'react'
import { UseFormResetField } from 'react-hook-form'
import TextareaAutosize from 'react-textarea-autosize'

import { IconButton } from '../IconButton'

export interface InputProps
  extends React.InputHTMLAttributes<
    HTMLTextAreaElement | HTMLInputElement
  > {
  placeholder?: string
  label?: string
  canRemove?: boolean
  error?: string
  prefix?: string
  resetField?: UseFormResetField<any>
  leftIcon?: React.ReactNode
  rightIcon?: React.ReactNode
  leftChildren?: React.ReactNode
  textarea?: boolean
  isPanel?: boolean
}

export const Input = forwardRef<
  HTMLTextAreaElement | HTMLInputElement,
  InputProps
>(
  (
    {
      className,
      type,
      prefix,
      label,
      canRemove = true,
      error,
      resetField,
      leftChildren,
      leftIcon,
      rightIcon,
      textarea,
      isPanel,
      ...props
    },
    ref
  ) => {
    const [isPasswordVisible, setIsPasswordVisible] =
      React.useState(false)

    const inputType =
      type === 'password' && isPasswordVisible ? 'text' : type

    const togglePasswordVisibility = React.useCallback(
      (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        setIsPasswordVisible((prev) => !prev)
      },
      []
    )
    const { style, ...restProps } = props
    const handleRemoveText = React.useCallback(() => {
      if (resetField && props.name) {
        resetField(props.name, { defaultValue: '' })
        if (props.onChange)
          props.onChange({
            target: { value: '' },
          } as React.ChangeEvent<HTMLInputElement>)
      } else {
        if (props.onChange)
          props.onChange({
            target: { value: '' },
          } as React.ChangeEvent<HTMLInputElement>)
      }
    }, [props, resetField])

    const [textareaHeight, setTextareaHeight] =
      React.useState<number>(52)

    const computeBorderRadius = React.useCallback(
      (height: number): number => {
        const minRadius = 10
        const maxRadius = 100
        const minHeight = 52
        const maxHeight = 260

        if (height <= minHeight) return maxRadius
        if (height >= maxHeight) return minRadius

        const ratio = (height - minHeight) / (maxHeight - minHeight)
        const radius = maxRadius - ratio * (maxRadius - minRadius) * 2

        return Math.max(radius, minRadius)
      },
      []
    )

    const borderRadiusClass = isPanel
      ? '100px'
      : `${String(Number(computeBorderRadius(textareaHeight)).toFixed(0))}px`

    return (
      <div className="relative flex w-full flex-col items-start justify-center gap-2">
        {label && (
          <label className="whitespace-nowrap text-base-400">
            {label}
          </label>
        )}
        <div className="relative w-full">
          {prefix && (
            <span className="absolute left-3 pt-3 text-base-950">
              {prefix}
            </span>
          )}
          {textarea ? (
            <TextareaAutosize
              ref={ref as React.Ref<HTMLTextAreaElement>}
              onHeightChange={(height) => setTextareaHeight(height)}
              className={cn(
                `max-h-[260px] min-h-[52px] w-full resize-none bg-transparent pb-2 pl-6
                  pr-12 pt-3 placeholder:text-base-400 focus:outline-none
                  disabled:cursor-not-allowed disabled:opacity-50`,

                prefix ? 'pl-[36px]' : '',
                className
              )}
              onKeyDown={restProps.onKeyDown}
              style={{ borderRadius: borderRadiusClass }}
              minRows={1}
              maxRows={10}
              {...restProps}
            />
          ) : (
            <input
              type={inputType}
              ref={ref as React.Ref<HTMLInputElement>}
              className={cn(
                `min-h-[52px] w-full rounded-lg border border-[#E3E5E8] bg-transparent
                  pl-6 pr-12 placeholder:text-base-400 focus:outline-none
                  disabled:cursor-not-allowed disabled:opacity-50`,
                className
              )}
              style={{
                paddingLeft: prefix ? '36px' : undefined,
              }}
              {...props}
            />
          )}
          {type === 'password' && props.value ? (
            <IconButton
              className="absolute right-2 top-1/2 -translate-y-1/2 transform lg:right-4"
              onClick={togglePasswordVisibility}
              aria-label={
                isPasswordVisible ? 'Hide password' : 'Show password'
              }
            >
              <IconEye />
            </IconButton>
          ) : type !== 'password' && canRemove && props.value ? (
            <IconButton
              type="button"
              className="absolute right-2 top-1/2 -translate-y-1/2 lg:right-4"
              onClick={handleRemoveText}
              aria-label="Clear input"
            >
              <IconCancelGray />
            </IconButton>
          ) : null}
          {rightIcon && (
            <div className="absolute right-3 top-1/2 -translate-y-1/2 transform">
              {rightIcon}
            </div>
          )}
        </div>
        {error && (
          <p className="mb-1 max-w-[90%] break-all text-red-500">
            {error}
          </p>
        )}
      </div>
    )
  }
)

Input.displayName = 'Input'
