import { CourtBaseItemMobile } from '@/entities/courtBase'
import IconNotFound from '@/shared/assets/icons/logo_not_found.svg?react'
import { useTypedSelector } from '@/shared/store'
import { Typography } from '@/shared/ui'
import { useRef } from 'react'

import { courtBaseData } from '../consts'

export const CourtBaseBodyContainerMobile = () => {
  const containerRef = useRef<HTMLDivElement>(null)

  return (
    <div
      ref={containerRef}
      className="flex flex-col items-stretch justify-start gap-4 rounded-b-3xl
        border-b-[1px] border-t-0 bg-transparent"
      style={{
        overflow: 'visible',
      }}
    >
      {courtBaseData && courtBaseData.length > 0 ? (
        <>
          {courtBaseData.map((court, index) => {
            const isLastItem = index === courtBaseData.length - 1
            return (
              <CourtBaseItemMobile
                key={court.id}
                index={index}
                courtBaseItem={court}
                isLastItem={isLastItem}
              />
            )
          })}
        </>
      ) : (
        <div
          className="flex shrink-0 grow flex-col items-center justify-center self-stretch
            py-20"
        >
          <div className="flex flex-col items-stretch justify-center gap-8">
            <IconNotFound className="self-center drop-shadow-lg" />
            <div className="flex flex-col items-center justify-center">
              <Typography>
                По запросу не найдено ни одного документа.
              </Typography>
              <Typography>
                Попробуйте написать запрос иначе и повторить поиск.
              </Typography>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
