import IconSortDark from '@/shared/assets/icons/icon_sort_dark.svg?react'
import { Typography } from '@/shared/ui/Typography'
import { forwardRef } from 'react'

interface CustomInputProps {
  value?: string
  onClick?: () => void
  className?: string
}

export const SortModeFilterCustomInput = forwardRef<
  HTMLButtonElement,
  CustomInputProps
>(({ value, onClick, className, ...props }, ref) => {
  return (
    <div className="flex shrink grow-0 basis-[150px] flex-col items-start gap-2">
      <button
        ref={ref}
        onClick={onClick}
        className={`flex h-8 items-center justify-between gap-2 self-stretch rounded-lg
          border border-[#E3E5E8] bg-white px-4 py-[6px] ${className}`}
        {...props}
      >
        <Typography variant={'label'} className="font-medium">
          {value ?? 'Сортировать'}
        </Typography>
        <IconSortDark className="h-4 w-4" />
      </button>
    </div>
  )
})
