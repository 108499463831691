import IconArrowRightWhite from '@/shared/assets/icons/icon_arrow_right_white.svg?react'
import { Button } from '@/shared/ui/Button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/shared/ui/DropdownMenu'
import { Typography } from '@/shared/ui/Typography'

import { mockDocumentStatusData } from '../../consts'
import { useDocumentStatusFilter } from '../../hooks/useDocumentStatusFilter'
import { DocumentStatusFilterCustomInput } from '../DocumentStatusFilterCustomInput'
import { DocumentStatusFilterItemsDesktop } from '../DocumentStatusFilterItemsDesktop'

type DocumentStatusFilterMenuDesktopProps = {
  totalDocuments?: string
}

export const DocumentStatusFilterMenuDesktop = ({
  totalDocuments,
}: DocumentStatusFilterMenuDesktopProps) => {
  const {
    selectedIndices,
    handleCheckboxChange,
    handleApply,
    handleReset,
  } = useDocumentStatusFilter()
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <DocumentStatusFilterCustomInput />
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="center"
        className="relative flex w-[465px] flex-col gap-2 overflow-hidden rounded-2xl
          bg-white p-2 pb-14"
      >
        <div className="flex flex-nowrap justify-start gap-2 px-2">
          <Typography variant={'subtitle'}>
            Выбрано фильтров: 1
          </Typography>
          <button
            className="rounded-full px-3 py-0 text-black"
            onClick={handleReset}
          >
            <Typography
              variant={'subtitle'}
              className="text-black underline"
            >
              Очистить
            </Typography>
          </button>
        </div>
        <div className="flex flex-col gap-2">
          <DocumentStatusFilterItemsDesktop
            items={mockDocumentStatusData}
            selectedIndices={selectedIndices}
            handleCheckboxChange={handleCheckboxChange}
          />
        </div>
        <div
          className="absolute bottom-0 -ml-2 flex w-full items-center justify-between gap-8
            rounded-b-2xl border-t border-t-[#F0F0F0] bg-white px-5 py-2"
        >
          <Typography variant={'label'} className="font-semibold">
            {totalDocuments &&
              `Найдено документов: ${totalDocuments}`}
          </Typography>
          <DropdownMenuItem className="p-0">
            <Button
              onClick={handleApply}
              variant={'secondary'}
              leftIcon={<IconArrowRightWhite />}
              className="grow py-1"
            >
              Показать
            </Button>
          </DropdownMenuItem>
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
