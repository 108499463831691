import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface DocumentMetaState {
  yOffset: number
}

const initialState: DocumentMetaState = {
  yOffset: 0,
}

const documentMetaSlice = createSlice({
  name: 'documentMeta',
  initialState,
  reducers: {
    setYOffset(state, action: PayloadAction<number>) {
      state.yOffset = action.payload
    },
    resetDocumentMeta(state) {
      state.yOffset = 0
    },
  },
})

export const { setYOffset, resetDocumentMeta } =
  documentMetaSlice.actions

export default documentMetaSlice.reducer
