import { DocumentItemStatus } from '@/entities/document/ui/DocumentItemStatus'
import IconBookmarkBlack from '@/shared/assets/icons/icon_bookmark_dark.svg?react'
import IconExport from '@/shared/assets/icons/icon_export.svg?react'
import IconFolderPlus from '@/shared/assets/icons/icon_folder_plus.svg?react'
import IconFullscreen from '@/shared/assets/icons/icon_fullscreen.svg?react'
import IconDots from '@/shared/assets/icons/icon_more_dots_dark.svg?react'
import { useAppDispatch, useTypedSelector } from '@/shared/store'
import {
  addDocument,
  closeChatPanel,
  toggleFirstDocumentPanel,
  toggleThrirdDocumentsPanel,
} from '@/shared/store/slices/chat/documentPreviewSlice'
import { IconButton } from '@/shared/ui'
import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarTrigger,
} from '@/shared/ui/Menubar'
import { Typography } from '@/shared/ui/Typography'
import { useBoundary } from '@/shared/utils/useBoundary'
import { useSidebar } from '@/widgets/Sidebar'
import { Arrow } from '@radix-ui/react-menubar'

import { IChatAiFile } from '../../types'

type ChatAiFileProps = {
  file: IChatAiFile
}

export const ChatAiFile = ({ file }: ChatAiFileProps) => {
  const dispatch = useAppDispatch()
  const { documents, isChatOpen } = useTypedSelector(
    (state) => state.documentPreview
  )

  const { isSidebarOpen, closeSidebar, toggleSidebar } = useSidebar()
  const boundaryElements = useBoundary('menu_boundary')
  const handleOpenDocument = () => {
    if (isSidebarOpen) {
      closeSidebar()
    }
    if (documents.length === 1) {
      dispatch(toggleFirstDocumentPanel(true))
      setTimeout(() => {
        dispatch(
          addDocument({
            id: file.id,
            name: file.name,
            chunks: file.chunks,
            status: file.status,
            requisites: file.requisites,
          })
        )
      }, 300)
    } else if (documents.length === 2) {
      dispatch(toggleThrirdDocumentsPanel(true))
      dispatch(
        addDocument({
          id: file.id,
          name: file.name,
          chunks: file.chunks,
          status: file.status,
          requisites: file.requisites,
        })
      )
    } else {
      dispatch(
        addDocument({
          id: file.id,
          name: file.name,
          chunks: file.chunks,
          status: file.status,
          requisites: file.requisites,
        })
      )
    }

    dispatch(closeChatPanel())
  }

  return (
    <Menubar
      key={file.id}
      className="flex items-center justify-stretch self-stretch"
    >
      <MenubarMenu>
        <div
          className="flex w-full flex-col items-stretch justify-start gap-2 rounded-[16px]
            border border-stroke-light-gray-2 bg-white p-4"
          style={{
            boxShadow:
              '0px 6px 2px 0px rgba(0, 0, 0, 0.00), 0px 4px 1px 0px rgba(0, 0, 0, 0.00), 0px 2px 1px 0px rgba(0, 0, 0, 0.02), 0px 1px 1px 0px rgba(0, 0, 0, 0.03)',
          }}
        >
          <div className="flex items-center justify-between gap-2">
            <DocumentItemStatus
              status={file.status.code}
              label={file.status.name}
            />
            <MenubarTrigger asChild>
              <IconButton
                className="h-6 w-6 -translate-y-[8px] translate-x-[8px] cursor-pointer rounded-lg
                  hover:scale-100 hover:bg-base-200"
              >
                <IconDots />
              </IconButton>
            </MenubarTrigger>
          </div>
          <div className="flex items-start justify-between gap-2">
            <Typography
              variant={'body'}
              className="break-words text-start font-medium leading-[24px]"
            >
              {file.name}
            </Typography>
          </div>

          {file.requisites ? (
            <ul
              className="list-disc flex-col items-stretch justify-start gap-1 pl-4
                marker:text-base-500"
            >
              <li>
                <div className="inline-flex w-full items-start justify-start gap-2">
                  <Typography
                    variant={'label'}
                    className="text-start"
                  >
                    {file.requisites}
                  </Typography>
                </div>
              </li>
            </ul>
          ) : null}
        </div>
        <MenubarContent
          collisionPadding={{ right: -20, top: 10, bottom: 15 }}
          className="pointer-events-auto visible z-50 flex w-[174px] flex-col items-stretch
            gap-3 rounded-[20px] bg-white p-2 shadow-shadow2"
          align="end"
          alignOffset={-16}
          sideOffset={-3}
        >
          <Arrow fill="#fff" className="h-2 w-4" />

          <MenubarItem
            className="flex items-center justify-start gap-3 rounded-lg p-2
              hover:cursor-pointer hover:bg-slate-100"
            onClick={handleOpenDocument}
            inset={true}
          >
            <IconFullscreen className="h-6 w-6" />
            <Typography className="whitespace-nowrap font-normal">
              Открыть здесь
            </Typography>
          </MenubarItem>

          <MenubarItem
            asChild
            onClick={() => {
              const newTab = window.open('', '_blank')
              if (newTab) {
                newTab.document.write(`
            <!DOCTYPE html>
            <html lang="en">
              <head>
                <meta charset="UTF-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                <title>${file.name}</title>
                <style>
                  body {
                    font-family: 'Inter', sans-serif;
                    margin: 0;
                    padding: 20px;
                    color: #000;
                  }
                  h1 {
                    text-align: center;
                    font-size: 2em;
                    font-weight: bold;
                    margin-bottom: 20px;
                  }
                  p {
                    font-size: 1em;
                    line-height: 1.5;
                  }
                </style>
              </head>
              <body>
                <h1>${file.name}</h1>
              </body>
            </html>
          `)
                newTab.document.close()
              }
            }}
            className="gap-3 rounded-lg p-2 hover:cursor-pointer hover:bg-slate-100"
          >
            <div
              className="flex items-center justify-start gap-3 rounded-lg p-2
                hover:cursor-pointer hover:bg-slate-100"
            >
              <IconExport className="h-6 w-6" />

              <Typography className="font-normal">
                В новом окне
              </Typography>
            </div>
          </MenubarItem>
          <MenubarItem
            disabled
            className="flex items-center justify-start gap-3 rounded-lg p-2
              hover:cursor-pointer hover:bg-additional-light-blue"
            onClick={handleOpenDocument}
            inset={true}
          >
            <IconBookmarkBlack className="h-6 w-6" />
            <Typography className="whitespace-nowrap font-normal">
              В избранное
            </Typography>
          </MenubarItem>
          <MenubarItem
            disabled
            className="bprder-stroke-light-gray-1 flex items-center justify-start gap-3
              rounded-b-lg border-t-[1px] p-2 hover:cursor-pointer
              hover:bg-slate-100"
            onClick={handleOpenDocument}
            inset={true}
          >
            <IconFolderPlus className="h-6 w-6" />
            <Typography className="whitespace-nowrap font-normal">
              В папку
            </Typography>
          </MenubarItem>
          {/* {chats.documents && chats.documents.length >= 1 ? (
            <MenubarItem
              className="flex items-center justify-start gap-3 rounded-lg p-2
                hover:cursor-pointer hover:bg-slate-100"
              onClick={handleOpenDocument}
              inset={true}
            >
              <IconRowVertical className="h-6 w-6" />
              <Typography
                
                className="whitespace-nowrap font-normal"
              >
                Сравнить
              </Typography>
            </MenubarItem>
          ) : null} */}
        </MenubarContent>
      </MenubarMenu>
    </Menubar>
  )
}
