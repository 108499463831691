import { ECourtBaseStatus, ICourtItem } from '@/entities/courtBase'

export const courtBaseData: ICourtItem[] = [
  {
    id: '7599-24-70-2',
    date: '17.09.2024',
    plaintiffs: [
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
    ],
    defendants: ['Перцев Д.Г.', 'Перцев Д.Г.'],
    judges: ['Баратбеков Т.К.'],
    court: 'Алматинский городской суд (Апелляционная инстанция)',
    status: [ECourtBaseStatus.EXPIRED, ECourtBaseStatus.ACTIVE],
    additional_info: ['новый суд'],
    is_favorite: true,
    number: 1,
    requirements:
      'Признании недействительным пункта договора об установлении комиссии за обслуживание займа, признании частично недействительным условия договора банковского займа и о взыскании удержанной суммы комиссии за обслуживание займа',
  },

  {
    id: '7599-24-00-2',
    date: '17.09.2024',
    requirements:
      'Признании недействительным пункта договора об установлении комиссии за обслуживание займа, признании частично недействительным условия договора банковского займа и о взыскании удержанной суммы комиссии за обслуживание займа',

    plaintiffs: ['Перцев Д.Г.', 'Перцев Д.Г.'],
    is_favorite: false,
    defendants: ['Перцев Д.Г.', 'Перцев Д.Г.'],
    judges: ['Баратбеков Т.К.'],
    court: 'Алматинский городской суд (Апелляционная инстанция)',
    status: [ECourtBaseStatus.EXPIRED, ECourtBaseStatus.PENDING],
    additional_info: ['новый суд'],
    number: 2,
  },
  {
    id: '7599-24-60-2',
    requirements:
      'Признании недействительным пункта договора об установлении комиссии за обслуживание займа, признании частично недействительным условия договора банковского займа и о взыскании удержанной суммы комиссии за обслуживание займа',
    date: '17.09.2024',
    plaintiffs: ['Перцев Д.Г.'],
    defendants: ['Перцев Д.Г.'],
    is_favorite: false,
    judges: ['Баратбеков Т.К.'],
    court: 'Алматинский городской суд (Апелляционная инстанция)',
    status: [ECourtBaseStatus.EXPIRED],
    number: 3,
    additional_info: ['новый суд'],
  },
  {
    requirements:
      'Признании недействительным пункта договора об установлении комиссии за обслуживание займа, признании частично недействительным условия договора банковского займа и о взыскании удержанной суммы комиссии за обслуживание займа',
    id: '7599-24-10-2',
    is_favorite: true,
    date: '17.09.2024',
    plaintiffs: ['Перцев Д.Г.', 'Перцев Д.Г.'],
    defendants: ['Перцев Д.Г.', 'Перцев Д.Г.'],
    judges: ['Баратбеков Т.К.'],
    court: 'Алматинский городской суд (Апелляционная инстанция)',
    status: [ECourtBaseStatus.ACTIVE],
    number: 4,
    additional_info: ['новый суд'],
  },
  {
    id: '7596-24-00-2',
    requirements:
      'Признании недействительным пункта договора об установлении комиссии за обслуживание займа, признании частично недействительным условия договора банковского займа и о взыскании удержанной суммы комиссии за обслуживание займа',
    is_favorite: true,
    date: '17.09.2024',
    plaintiffs: ['Перцев Д.Г.', 'Перцев Д.Г.'],
    defendants: ['Перцев Д.Г.', 'Перцев Д.Г.'],
    judges: ['Баратбеков Т.К.'],
    court: 'Алматинский городской суд (Апелляционная инстанция)',
    status: [ECourtBaseStatus.ARTICLE],
    number: 5,
    additional_info: ['новый суд'],
  },
  {
    id: '7529-24-00-2',
    requirements:
      'Признании недействительным пункта договора об установлении комиссии за обслуживание займа, признании частично недействительным условия договора банковского займа и о взыскании удержанной суммы комиссии за обслуживание займа',
    date: '17.09.2024',
    plaintiffs: ['Перцев Д.Г.', 'Перцев Д.Г.'],
    defendants: ['Перцев Д.Г.', 'Перцев Д.Г.'],
    is_favorite: true,
    judges: ['Баратбеков Т.К.'],
    number: 6,
    court: 'Алматинский городской суд (Апелляционная инстанция)',
    status: [ECourtBaseStatus.ACTIVE],
    additional_info: ['новый суд'],
  },
  {
    id: '7899-24-00-2',
    date: '17.09.2024',
    plaintiffs: ['Перцев Д.Г.', 'Перцев Д.Г.'],
    is_favorite: true,
    defendants: ['Перцев Д.Г.', 'Перцев Д.Г.'],
    requirements:
      'Признании недействительным пункта договора об установлении комиссии за обслуживание займа, признании частично недействительным условия договора банковского займа и о взыскании удержанной суммы комиссии за обслуживание займа',
    judges: ['Баратбеков Т.К.'],
    court: 'Алматинский городской суд (Апелляционная инстанция)',
    status: [ECourtBaseStatus.ACTIVE],
    additional_info: ['новый суд'],
    number: 7,
  },
  {
    id: '7599-24-00-1',
    is_favorite: true,
    requirements:
      'Признании недействительным пункта договора об установлении комиссии за обслуживание займа, признании частично недействительным условия договора банковского займа и о взыскании удержанной суммы комиссии за обслуживание займа',
    date: '17.09.2024',
    plaintiffs: ['Перцев Д.Г.', 'Перцев Д.Г.'],
    defendants: ['Перцев Д.Г.', 'Перцев Д.Г.'],
    judges: ['Баратбеков Т.К.'],
    number: 8,
    court: 'Алматинский городской суд (Апелляционная инстанция)',
    status: [ECourtBaseStatus.ACTIVE],
    additional_info: ['новый суд'],
  },
  {
    id: '7599-24-70-22',
    date: '17.09.2024',
    plaintiffs: [
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
    ],
    defendants: ['Перцев Д.Г.', 'Перцев Д.Г.'],
    judges: ['Баратбеков Т.К.'],
    court: 'Алматинский городской суд (Апелляционная инстанция)',
    status: [ECourtBaseStatus.EXPIRED, ECourtBaseStatus.ACTIVE],
    additional_info: ['новый суд'],
    is_favorite: true,
    number: 1,
    requirements:
      'Признании недействительным пункта договора об установлении комиссии за обслуживание займа, признании частично недействительным условия договора банковского займа и о взыскании удержанной суммы комиссии за обслуживание займа',
  },

  {
    id: '7599-21-70-2',
    date: '17.09.2024',
    plaintiffs: [
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
    ],
    defendants: ['Перцев Д.Г.', 'Перцев Д.Г.'],
    judges: ['Баратбеков Т.К.'],
    court: 'Алматинский городской суд (Апелляционная инстанция)',
    status: [ECourtBaseStatus.EXPIRED, ECourtBaseStatus.ACTIVE],
    additional_info: ['новый суд'],
    is_favorite: true,
    number: 1,
    requirements:
      'Признании недействительным пункта договора об установлении комиссии за обслуживание займа, признании частично недействительным условия договора банковского займа и о взыскании удержанной суммы комиссии за обслуживание займа',
  },

  {
    id: '7199-24-70-2',
    date: '17.09.2024',
    plaintiffs: [
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
    ],
    defendants: ['Перцев Д.Г.', 'Перцев Д.Г.'],
    judges: ['Баратбеков Т.К.'],
    court: 'Алматинский городской суд (Апелляционная инстанция)',
    status: [ECourtBaseStatus.EXPIRED, ECourtBaseStatus.ACTIVE],
    additional_info: ['новый суд'],
    is_favorite: true,
    number: 1,
    requirements:
      'Признании недействительным пункта договора об установлении комиссии за обслуживание займа, признании частично недействительным условия договора банковского займа и о взыскании удержанной суммы комиссии за обслуживание займа',
  },

  {
    id: '7599-34-70-2',
    date: '17.09.2024',
    plaintiffs: [
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
    ],
    defendants: ['Перцев Д.Г.', 'Перцев Д.Г.'],
    judges: ['Баратбеков Т.К.'],
    court: 'Алматинский городской суд (Апелляционная инстанция)',
    status: [ECourtBaseStatus.EXPIRED, ECourtBaseStatus.ACTIVE],
    additional_info: ['новый суд'],
    is_favorite: true,
    number: 1,
    requirements:
      'Признании недействительным пункта договора об установлении комиссии за обслуживание займа, признании частично недействительным условия договора банковского займа и о взыскании удержанной суммы комиссии за обслуживание займа',
  },

  {
    id: '7599-24-70-28',
    date: '17.09.2024',
    plaintiffs: [
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
    ],
    defendants: ['Перцев Д.Г.', 'Перцев Д.Г.'],
    judges: ['Баратбеков Т.К.'],
    court: 'Алматинский городской суд (Апелляционная инстанция)',
    status: [ECourtBaseStatus.EXPIRED, ECourtBaseStatus.ACTIVE],
    additional_info: ['новый суд'],
    is_favorite: true,
    number: 1,
    requirements:
      'Признании недействительным пункта договора об установлении комиссии за обслуживание займа, признании частично недействительным условия договора банковского займа и о взыскании удержанной суммы комиссии за обслуживание займа',
  },

  {
    id: '7599-24-70-211',
    date: '17.09.2024',
    plaintiffs: [
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
      'Перцев Д.Г.',
    ],
    defendants: ['Перцев Д.Г.', 'Перцев Д.Г.'],
    judges: ['Баратбеков Т.К.'],
    court: 'Алматинский городской суд (Апелляционная инстанция)',
    status: [ECourtBaseStatus.EXPIRED, ECourtBaseStatus.ACTIVE],
    additional_info: ['новый суд'],
    is_favorite: true,
    number: 1,
    requirements:
      'Признании недействительным пункта договора об установлении комиссии за обслуживание займа, признании частично недействительным условия договора банковского займа и о взыскании удержанной суммы комиссии за обслуживание займа',
  },
]
