import React, { useEffect, useRef, useState } from 'react'

import { IChatMessage } from '../types'

type UseChatMobileProps = {
  messages: IChatMessage[]
}

export const useChatMobile = ({ messages }: UseChatMobileProps) => {
  const bottomRef = useRef<HTMLDivElement | null>(null)
  const [isBottomVisible, setIsBottomVisible] = useState(true)

  // Scroll to the bottom whenever messages change
  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [messages])

  // Set up Intersection Observer to detect if bottomRef is in view
  useEffect(() => {
    const observerCallback = (
      entries: IntersectionObserverEntry[]
    ) => {
      const entry = entries[0]
      setIsBottomVisible(entry.isIntersecting)
    }

    const observerOptions = {
      root: null, // Use the viewport as the container
      threshold: 0.1, // Trigger when at least 10% of the target is visible
    }

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    )

    if (bottomRef.current) {
      observer.observe(bottomRef.current)
    }

    // Clean up the observer on component unmount
    return () => {
      if (bottomRef.current) {
        observer.unobserve(bottomRef.current)
      }
      observer.disconnect()
    }
  }, [])

  return { bottomRef, isBottomVisible }
}
