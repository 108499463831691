import { usePostToggleFavoriteDocumentMutation } from '@/shared/api/documents'

export const useToogleFavouriteDocument = (documentId: number) => {
  const [toggleFavorite, { isLoading, isSuccess, isError }] =
    usePostToggleFavoriteDocumentMutation()

  const handleToggleFavorite = async () => {
    try {
      await toggleFavorite({ document_id: documentId }).unwrap()
    } catch (error) {
      console.error('Failed to toggle favorite status:', error)
    }
  }

  return {
    handleToggleFavorite,
    isLoading,
    isSuccess,
    isError,
  }
}
